import { title, grayColor } from "assets/jss/material-kit-pro-react.js";

const plataformaStyle = {
  section: {
    // overflow: "hidden",
    backgroundColor: "rgb(0 0 0 / 11%)",
    paddingBlock: "3.5rem",
    paddingInline: "1.2rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center", // Ajustar items na horizontal
    justifyContent: "center", // Ajustar items na vertical
    textAlign: "left",
  },

  title: {
    ...title,
    "@media (max-width: 480px)": {
      fontSize: "7.5vw",
    },
    marginTop: 0,
    paddingTop: 0,
    textAlign: "left",
    fontWeight: "700",
    marginBottom: "4rem",
    // fontStyle: "normal",
    fontFamily: "'Exo 2'",
    fontSize: "3.125rem",
    // display: "inline-block",
    // position: "relative",
    // marginTop: "30px",
    // minHeight: "57px",
    // marginLeft: "2rem",
    color: "#454545",
    // whiteSpace: "nowrap",
  },

  subTitle: {
    ...title,
    // "@media (max-width: 480px)": {
    //   fontSize: "6vw",
    // },
    // marginBottom: "0px",
    marginTop: 0,
    marginBottom: 0,
    minHeight: "unset",
    fontStyle: "normal",
    fontFamily: "'Exo 2'",
    fontSize: "30px",
    // lineHeight: "0.1",
    // display: "inline-block",
    color: "#454545",
    "@media (max-width: 480px)": {
      display: "none",
    },
  },

  textsvg: {
    position: "absolute",
    width: "253px",
    height: "80px",
    fontFamily: "'Exo 2'",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "30px",
    lineHeight: "40px",
    color: "#454545",
    marginTop: "260px",
  },
  fluxoImage: {
    position: "initial",
    width: "800px",
    marginLeft: "210px",
    height: "initial",
    mixBlendMode: "darken",
    marginBottom: "60px",
  },

  description: {
    "@media (max-width: 480px)": {
      width: "103%",
    },
    // display: "inline-block",
    fontFamily: "'Exo 2'",
    fontSize: "20px",
    // width: "36em",
    lineHeight: "2",
    color: grayColor[1],
    // marginLeft: "2rem",
    minHeight: "57px",
  },

  line: {
    backgroundColor: "rgba(196, 196, 196, 1)",
    width: "100%",
    border: "1px solid rgb(97 97 97)",
    marginBlock: "3rem",
  },

  eyeflowImage: {
    "@media (max-width: 480px)": {
      marginLeft: "0em",
      marginBottom: "0.5em",
    },
    width: "193.97px",
    // marginLeft: "760px",
    height: "60px",
    // marginBottom: "-70px",
  },
  edgeImage: {
    // "@media (max-width: 480px)": {
    //   marginLeft: "0em",
    //   marginBottom: "0.5em",
    // },
    // "@media (max-width: 340px)": {
    //   marginLeft: "0.5vw",
    // },
    width: "187.81px",
    // marginLeft: "763px",
  },
  sectionFlow: {
    padding: "20px 0",
    textAlign: "left",
    width: "max",
  },
  flowHeader: {
    fontFamily: "'Exo 2', sansSerif",
    fontSize: "20px",
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    marginLeft: "2rem",
    color: grayColor[1],
    textDecoration: "none",
  },
  cardTitle: {
    fontFamily: "'Exo 2', sansSerif",
    fontSize: "16px",
    display: "inline-block",
    position: "relative",
    fontWeight: "500",
    // marginTop: "30px",
    // minHeight: "32px",
    // marginLeft: "2rem",
    textDecoration: "none",
    color: grayColor[1],
  },
  cardDescription: {
    fontFamily: "'Exo 2', sansSerif",
    fontSize: "16px",
    display: "inline-block",
    position: "relative",
    // marginTop: "30px",
    // minHeight: "32px",
    // marginLeft: "2rem",
    textDecoration: "none",
    color: grayColor[1],
  },
  imgIcon: {
    height: "60px",
  },
};

export default plataformaStyle;
