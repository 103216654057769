import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import apisauce from "apisauce";

// nodejs library that concatenates classes
import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import PinDrop from "@material-ui/icons/PinDrop";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import { Grid } from '@material-ui/core';

// core components
import Header from "components/Header/Header.js";
import SignUpPopup from "components/SignUpPopup/SignUpPopup";
import HeaderLinks from "components/Header/PageHeaderLinks.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Form from "components/Form/FormFields.js";
import PageFooter from "components/Footer/PageFooter.js";
import contactUsStyle from "assets/jss/material-kit-pro-react/Pages/contactUsStyle.js";

import fluid_yellow_png from "assets/img/fluid-yellow.png";
import fluid_blue_png from "assets/img/fluid-blue.png";
import eyeflowImg from "assets/img/logos/logoEyeFlowNameless32.png";

const useStyles = makeStyles(contactUsStyle);

const ContactUsPage = ({ intl, ...rest }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const [sentEmail, setSentEmail] = React.useState(false);
  const [dados, setDados] = React.useState({
    nome: "",
    email: "",
    celular: "",
    assunto: "",
  });

  const api = apisauce.create({
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const sendEmail = (email) =>
    api.post(`${window.WS_URL}/send-email`, { ...email });
  function handleFormSubmit(e) {
    e.preventDefault();
    // setDados(dados);
    sendEmail({
      receivers: dados.email,
      subject: intl.formatMessage({
        id: "email.contact.subject",
        defaultMessage: "Parceiro EyeFlow",
      }),
      html: `<html><body><table style="background-color:#f0fdfc;border-collapse:collapse;border-color:#add8e6;height:200px;border-style:inset" border="1" width="700" cellspacing="0" cellpadding="0" align="center"><tbody><tr style="border-bottom:0;border-top:0"><td style="padding:40px 0 30px 0;border-bottom:0;border-top:0" align="center"><a href="https://eyeflow.ai/" target="_blank" rel="noreferrer noopener nofollow"><img class="CToWUd" style="display: block;" src="https://eyeflow.ai/static/media/logoEyeFlowNameless4.51908118.png" alt="EyeFlow" width="200&quot;" height="70"></a></td></tr><tr><td style="padding-top:30px;padding-left:80px;padding-right:90px;margin:0;font-size:15px;line-height:38px;font-weight:200;color:grey;border-bottom:0;border-top:0" align="center">
      ${intl.formatMessage({
        id: "email.greeting",
        defaultMessage: "Parceiro EyeFlow",
      })}&nbsp;<b>${dados.nome
        }</b></td></tr><tr><td style="padding-top:20px;padding-left:80px;padding-right:90px;margin:0;font-size:15px;line-height:38px;font-weight:200;color:grey;border-bottom:0;border-top:0" align="center">
      ${intl.formatMessage({
          id: "email.contact.paragraph1",
          defaultMessage:
            "Que bom saber que você tem interesse em ser parceiro do EyeFlow!",
        })}
      <br>
      ${intl.formatMessage({
          id: "email.contact.paragraph2",
          defaultMessage: "Parceiro EyeFlow",
        })}
      <br></td></tr><tr><td style="padding-top:10px;padding-left:90px;padding-right:90px;margin:0;font-size:15px;line-height:38px;font-weight:200;color:grey;border-bottom:0;border-top:0" align="center">
      ${intl.formatMessage({
          id: "email.team",
          defaultMessage: "Parceiro EyeFlow",
        })}
      </td></tr><tr><td style="border-bottom:0;border-top:0;padding:40px 0 30px 0" align="center"><a href="https://siliconlife.ai/"><img style="display: block;" src="https://eyeflow.ai/images/logo-siliconlife.png" alt="SiliconLife" width="190&quot;" height="80"></a></td></tr></tbody></table></body></html>`,
    }).then((result) => {
      if (result.ok) {
        setSentEmail(true);
      }
    });

    sendEmail({
      receivers: "contact@eyeflow.ai",
      subject: "Contato EyeFlow",
      text: `
      Nome: ${dados.nome}\n
      E-mail: ${dados.email}\n
      Celular: ${dados.celular}\n
      Assunto: ${dados.assunto}\n
      Idioma: ${(navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        window.navigator.userLanguage
        }
      `,
    }).then((result) => {
      if (result.ok) {
        setSentEmail(true);
      }
    });
    setTimeout(() => {
      setSentEmail(false);
    }, 10000);
  }

  function handlePhoneChange(value, country, e, formattedValue) {
    dados["celular"] = formattedValue;
  }

  function handleInputChange(event) {
    dados[event.target.name] = event.target.value;
    setDados(dados);
  }

  const emailResponse = (
    <div className={classes.thanksMessage}>
      <img src={eyeflowImg} alt="" />
      <br />
      <br />
      <FormattedMessage
        id="partners.notification1"
        defaultMessage="Obrigado pelo contato!"
      />
    </div>
  );

  const fields = [
    {
      label: <FormattedMessage id="contact.field1" defaultMessage="Nome" />,
      isFull: true,
    },
    {
      label: <FormattedMessage id="contact.field2" defaultMessage="Email" />,
      isFull: true,
    },
    {
      label: <FormattedMessage id="contact.field3" defaultMessage="Celular" />,
      isFull: true,
    },
    {
      label: (
        <FormattedMessage
          id="contact.field4"
          defaultMessage="O que gostaria de falar conosco?"
        />
      ),
      multipleLines: [true, 4, 1000],
      isFull: true,
    },
  ];

  return (
    <div>
      <Header brand="EyeFlow.AI" links={<HeaderLinks />} fixed color="white" />
      <SignUpPopup />
      <div className={classes.sectionImageBlue}>
        <img src={fluid_blue_png} alt="" className={classes.imageFluidBlue} />
      </div>
      <div className={classes.sectionImageYellow}>
        <img
          src={fluid_yellow_png}
          alt=""
          className={classes.imageFluidYellow}
        />
      </div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <Grid
          style={{
            paddingBlock: '2rem',
          }}
          container
          spacing={1}
          direction="row"
          justify='center'
          alignItems="center"
        >
          <Grid item md={5} sm={5}>
            <h2
              style={{ display: sentEmail ? "none" : "" }}
              className={classes.title}
            >
              <FormattedMessage
                id="contact.title1"
                defaultMessage="Nos mande uma mensagem!"
              />
            </h2>
            {sentEmail ? (
              emailResponse
            ) : (
              <Form
                fields={fields}
                isFullWidth={true}
                names={["nome", "email", "celular", "assunto"]}
                handleInputChange={handleInputChange}
                handleFormSubmit={handleFormSubmit}
                handlePhoneChange={handlePhoneChange}
                emailResponse={emailResponse}
                sentEmail={sentEmail}
                formId="contactForm"
              />
            )}
          </Grid>
          <Grid item md={5} sm={5}>
            <InfoArea
              className={classes.info}
              style={{ fontFamily: "'Exo 2'" }}
              title={intl.formatMessage({
                id: "contact.title1Info",
                defaultMessage: "Procure a gente no escritório",
              })}
              description={
                <p
                  style={{ fontFamily: "'Exo 2'", fontWeight: "bolder" }}
                >
                  Av. Paulista, n. 2202 - 145
                  <br />
                  Bela Vista, São Paulo
                  <br />
                  SP, 01310-300{" "}
                </p>
              }
              icon={PinDrop}
              iconColor="blue"
            />
            <InfoArea
              className={classes.info}
              title={intl.formatMessage({
                id: "contact.title2Info",
                defaultMessage: "Contatos Comerciais",
              })}
              description={
                <div
                  style={{
                    fontFamily: "'Exo 2'",
                    fontWeight: "bolder",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gridTemplateRows: "1fr 1fr",
                  }}
                >
                  <div style={{ fontSize: "14px" }}>
                    <strong>Paulo Melo</strong>
                    <br />
                    Cel: +55 (11) 96460 - 0409
                    <br />
                    Email:{" "}
                    <a href="mailto:pcmelo@eyeflow.ai">
                      pcmelo@eyeflow.ai
                    </a>
                  </div>
                  <div style={{ fontSize: "14px" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <strong>Juliana Pessoa</strong>
                      <strong>Head de Marketing e Vendas</strong>
                    </div>
                    Cel: +55 (11) 91677-0603
                    <br />
                    Email:{" "}
                    <a href="mailto:juliana.pessoa@eyeflow.ai">
                      juliana.pessoa@eyeflow.ai
                    </a>
                  </div>
                </div>
              }
              icon={PhoneIphoneIcon}
              iconColor="blue"
            />
          </Grid>
        </Grid>
      </div>
      <PageFooter style={{ marginBottom: "-5em" }} />
      <div className={classes.sectionImageYellowBottom}>
        <img
          src={fluid_blue_png}
          alt=""
          className={classes.imageFluidBlueBottom}
        />
      </div>
      <div className={classes.sectionImageBlueBottom}>
        <img
          src={fluid_yellow_png}
          alt=""
          className={classes.imageFluidYellowBottom}
        />
      </div>
    </div>
  );
};
export default injectIntl(ContactUsPage);
