const newFooterStyle = {
  footer: {
    fontFamily: "'Poppins', sans-serif !important",
  },

  navItem: {
    fontFamily: "'Poppins', sans-serif !important",
    textDecoration: "none !important",
    color: "rgb(255, 255, 255)",
    fontSize: "14px",
  },

  navLink: {
    fontFamily: "'Poppins', sans-serif !important",
    color: "#192245 !important",
    fontSize: "20px !important",
    "&:hover": {
      color: "#909090 !important",
      fontSize: "210px",
    },
    "&:focus": {
      color: "#909090 !important",
      fontSize: "210px",
    },
  },

  footerTopP: {
    fontSize: "14px",
    color: "#5a7196",
    fontFamily: "'Poppins', sans-serif !important",
  },

  "@media (max-width: 576px)": {
    largeHeader: {
      maxHeight: "300px",
    },
  },

  "@media (max-width: 768px)": {
    largeHeader: {
      maxHeight: "400px",
    },
  },

  "@media (max-width: 992px)": {
    largeHeader: {
      maxHeight: "500px",
    },
  },
};

export default newFooterStyle;
