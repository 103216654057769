import React from "react";
import { FormattedMessage } from "react-intl";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

import teamStyle from "assets/jss/material-kit-pro-react/Pages/aboutUsSections/teamStyle.js";
// Profiles
// import Pic1 from "assets/img/faces/renata.jpg";
import Pic2 from "assets/img/faces/felipe.JPG";
import Pic3 from "assets/img/faces/adriane.jpg";
import Pic4 from "assets/img/faces/gabriel.jpg";
import Pic5 from "assets/img/faces/claudio.jpeg";
import Pic6 from "assets/img/faces/sergio.jpg";
import Pic7 from "assets/img/faces/lucas.jpg";
import Pic8 from "assets/img/faces/jones.jpg";
import Pic9 from "assets/img/faces/alex.png";
import Pic10 from "assets/img/faces/denison.PNG";
import Pic11 from "assets/img/faces/juliana.jpg";
import Pic12 from "assets/img/faces/fernando.png";
import Pic13 from "assets/img/faces/paulinho.jpeg";

const useStyles = makeStyles(teamStyle);

export default function SectionTeam() {
  const classes = useStyles();

  const people = [
    {
      role: <FormattedMessage id="about.team.role1" defaultMessage="CEO" />,
      description: (
        <FormattedMessage
          id="about.team.description1"
          defaultMessage={`Founder com +30 anos experiência em criação de plataformas de software e soluções tecnológicas para grandes corporações.
      +20 anos de pesquisas em Inteligência Artificial.
      Nerd assumido, workaholic, filósofo.
      Missão: Construir uma máquina mais inteligente do que eu.`}
        />
      ),
      name: "Alex Sobral",
      pic: (
        <img
          src={Pic9}
          width="50%"
          alt="profile-pic"
          className={classes.picProfile}
        />
      ),
    },
    {
      role: "COO",
      description: (
        <FormattedMessage
          id="about.team.description2"
          defaultMessage="Durante os últimos 30 anos na área de tecnologia, atuou em empresas de grande porte como Bancos e Telecom, tendo sido consultor estratégico durante 7 anos pela K2 Achievements e CIO da MRM Brazil, do grupo McCann, por 4 anos. Foi sócio da SmartClick, uma agência digital em 2002 e é COO/Sócio da SiliconLIFE desde 2018. MBAs em Gestão Estratégica de TI e Gestão econômica e financeira de Empresas, ambos pela FGV."
        />
      ),
      name: "Roberto Jones",
      pic: (
        <img
          src={Pic8}
          width="50%"
          alt="profile-pic"
          className={classes.picProfile}
        />
      ),
    },
    {
      role: (
        <FormattedMessage
          id="about.team.role13"
          defaultMessage="Diretor Comercial"
        />
      ),
      description: (
        <FormattedMessage
          id="about.team.description13"
          defaultMessage="Responsável pela área comercial e com relacionamento com cliente"
        />
      ),
      name: "Paulo Melo",
      pic: (
        <img
          src={Pic13}
          width="50%"
          alt="profile-pic"
          style={{ marginTop: "-1em" }}
          className={classes.picProfile}
        />
      ),
    },
    {
      role: (
        <FormattedMessage
          id="about.team.role3"
          defaultMessage="Full-Stack Developer"
        />
      ),
      description: (
        <FormattedMessage
          id="about.team.description3"
          defaultMessage="O faz tudo da empresa, onde tiver código ele estará lá. Baiano, geek, apreciador de música e violonista nas horas vagas."
        />
      ),
      name: "Gabriel Melo",
      pic: (
        <img
          src={Pic4}
          width="50%"
          alt="profile-pic"
          className={classes.picProfile}
        />
      ),
    },
    {
      role: (
        <FormattedMessage
          id="about.team.role4"
          defaultMessage="Full-Stack Developer"
        />
      ),
      description: (
        <FormattedMessage
          id="about.team.description4"
          defaultMessage="Só não sou violonista..."
        />
      ),
      name: "Felipe Alves",
      pic: (
        <img
          src={Pic2}
          width="50%"
          alt="profile-pic"
          className={classes.picProfile}
        />
      ),
    },
    // {
    //   role: <FormattedMessage id="about.team.role5" defaultMessage="Quality Assurance Tester" />,
    //   description: <FormattedMessage id="about.team.description5" defaultMessage={`Atua no controle de qualidade da plataforma EyeFlow e é responsável pela implementação da automação de testes de software.
    //   Gaúcha, gosta de manter um lifestyle saudável e aproveita as horas vagas para praticar crossfit e corrida ao ar livre.`}/>,
    //   name: "Renata Dalbem",
    //   pic: <img src={Pic1} width="50%" alt="profile-pic"  className={classes.picProfile}/>,
    // },
    {
      role: (
        <FormattedMessage
          id="about.team.role6"
          defaultMessage="Front-End Developer"
        />
      ),
      description: (
        <FormattedMessage
          id="about.team.description6"
          defaultMessage={`Atua no desenvolvimento de Front-End e é responsável pelo gerenciamento do site.
      Paulista, iniciante na área de tecnologia, adora ler, desenhar e ouvir (bastante) podcasts em seu tempo livre.`}
        />
      ),
      name: "Adriane Santos",
      pic: (
        <img
          src={Pic3}
          width="50%"
          alt="profile-pic"
          className={classes.picProfile}
        />
      ),
    },
    {
      role: (
        <FormattedMessage
          id="about.team.role7"
          defaultMessage="Senior data engineer"
        />
      ),
      description: (
        <FormattedMessage
          id="about.team.description7"
          defaultMessage="Dados por entusiasmo, caratê por estilo de vida, games por fascinação, pizzaiolo por diversão, amigos por socialização, família por alicerces, pai por propósito e esposo por paixão"
        />
      ),
      name: "Cláudio Sobral",
      pic: (
        <img
          src={Pic5}
          width="50%"
          alt="profile-pic"
          className={classes.picProfile}
        />
      ),
    },
    {
      role: (
        <FormattedMessage
          id="about.team.role8"
          defaultMessage="Head of DevOps"
        />
      ),
      description: (
        <FormattedMessage
          id="about.team.description8"
          defaultMessage="Trabalha na área de Tecnologia da Informação e Engenharia de Desenvolvimento há mais de 35 anos, atua em projetos de big data, inteligência artificial, containers e instrumentação de aplicativos. Possui vivência multicultural por ter participado em projetos em países da América do Norte e América Latina, interagindo com interlocutores de diversas nacionalidades e posições."
        />
      ),
      name: "Sérgio Siqueira",
      pic: (
        <img
          src={Pic6}
          width="50%"
          alt="profile-pic"
          style={{ marginTop: "-1em" }}
          className={classes.picProfile}
        />
      ),
    },
    {
      role: (
        <FormattedMessage
          id="about.team.role9"
          defaultMessage="Engenheiro de IA"
        />
      ),
      description: (
        <FormattedMessage
          id="about.team.description9"
          defaultMessage="Analista de IA, engenheiro de Visão Computacional e engenheiro mecatrônico, ele implementa algorítmos de deep learning e cria soluções de Visão Computacional. Obcecado com Elon desde a infância, Lucas acredita que trabalho duro e engenharia são as melhores ferramentas se resolver os problemas da humanidade. Entusiasta de Sci-Fi, robotica e viagem espacial."
        />
      ),
      name: "Lucas Sabbatini",
      pic: (
        <img
          src={Pic7}
          width="50%"
          alt="profile-pic"
          className={classes.picProfile}
        />
      ),
    },
    {
      role: (
        <FormattedMessage
          id="about.team.role10"
          defaultMessage="Analista de Suporte Senior"
        />
      ),
      description: (
        <FormattedMessage
          id="about.team.description10"
          defaultMessage="Baiano de nascimento, paulistano por escolha. E que escolha! Atua há quase 20 anos na área de suporte em soluções de TI. Adora Heavy Metal, bacon, IPA, Trippel e afins."
        />
      ),
      name: "Denison Soares",
      pic: (
        <img
          src={Pic10}
          width="50%"
          alt="profile-pic"
          className={classes.picProfile}
        />
      ),
    },
    {
      role: (
        <FormattedMessage
          id="about.team.role11"
          defaultMessage="Head of Marketing"
        />
      ),
      description: (
        <FormattedMessage
          id="about.team.description11"
          defaultMessage="Formada em Comunicação Social com habilitação em Relações Públicas. MBA em Marketing pela Fundação Getúlio Vargas e MBA Disruptivo de Marketing Digital com foco em Gestão de Negócios e Empreendedorismo pelo Instituto de Ensino Superior Brasileiro. Apaixonada por Marketing e Inovação, com 20 anos de experiência, é entusiasta da tecnologia e acredita que Inteligência Artificial é a palavra de ordem."
        />
      ),
      name: "Juliana Pessoa",
      pic: (
        <img
          src={Pic11}
          width="50%"
          alt="profile-pic"
          className={classes.picProfile}
        />
      ),
    },
    {
      role: (
        <FormattedMessage
          id="about.team.role12"
          defaultMessage="Head of Marketing"
        />
      ),
      description: (
        <FormattedMessage
          id="about.team.description12"
          defaultMessage="Formada em Comunicação Social com habilitação em Relações Públicas. MBA em Marketing pela Fundação Getúlio Vargas e MBA Disruptivo de Marketing Digital com foco em Gestão de Negócios e Empreendedorismo pelo Instituto de Ensino Superior Brasileiro. Apaixonada por Marketing e Inovação, com 20 anos de experiência, é entusiasta da tecnologia e acredita que Inteligência Artificial é a palavra de ordem."
        />
      ),
      name: "Fernando Pessoa",
      pic: (
        <img
          src={Pic12}
          width="50%"
          alt="profile-pic"
          className={classes.picProfile}
        />
      ),
    },
  ];

  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      // color: "cornflowerblue",
    },
    tooltip: {
      fontFamily: "'Exo 2'",
      fontSize: "12px",
      textAlign: "center",
      // backgroundColor: "cornflowerblue",
    },
  }));

  function TeamTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
  }

  const renderTeam = () => {
    return (
      <GridContainer>
        {people.map((el, index) => {
          return (
            <GridItem key={index} md={3} sm={3}>
              <Card profile plain>
                <CardAvatar
                  profile
                  plain
                  style={{ height: "15em", width: "15em" }}
                >
                  <TeamTooltip
                    arrow
                    title={el.description}
                    aria-label={el.description}
                    placement="bottom"
                  >
                    <div style={{ height: "12.5em" }}>{el.pic}</div>
                  </TeamTooltip>
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.cardTitle}>{el.name}</h4>
                  <h6
                    style={{ fontFamily: "'Exo 2'" }}
                    className={classes.textMuted}
                  >
                    {el.role}
                  </h6>
                  {/* <p className={classes.cardDescription}>{el.description}</p> */}
                </CardBody>
                {/* <CardFooter className={classes.justifyContent}>
                  <h6 className={classes.textMuted}>{el.description}</h6>
                  </CardFooter> */}
              </Card>
            </GridItem>
          );
        })}
      </GridContainer>
    );
  };

  return (
    <div className={classes.team}>
      {/* <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>We are nerd rockstars</h2>
          <h5 className={classes.description}>
            This is the paragraph where you can write more details about your
            team. Keep you user engaged by providing meaningful information.
          </h5>
        </GridItem>
      </GridContainer> */}
      <GridContainer>{renderTeam(classes)}</GridContainer>
    </div>
  );
}
