import React from "react";
import { FormattedMessage } from "react-intl";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import contactUsStyle from "assets/jss/material-kit-pro-react/Pages/partnersSections/hpeStyle.js";
import logoHPE from "assets/img/partners/partner2.png"

const useStyles = makeStyles(contactUsStyle);

export default function ContactUsPage() {
  const classes = useStyles();
  return (
    <div style={{ pointerEvents:"none", marginBottom:"3em", marginTop:"4em" }}>
      <div style={{ backgroundColor: "#e1ffe1" }} className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            {/* <h2 style={{ textAlign:"center", fontFamily: "'Exo 2'" }} className={classes.title}>Parceria com HPE</h2> */}
            <GridContainer justify="center" style={{ padding: "0px 60px" }}>
              <GridItem md={6} sm={6}>
                <a
                    href="https://www.hpe.com" 
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                  >
                  <img src={logoHPE} className={classes.imgStyle} alt="HPE" width={300} />
                </a>
              </GridItem>
              <GridItem md={6} sm={6} className={classes.mlAuto} style={{ fontFamily: "'Exo 2'", fontWeight: 400, textAlign: "-webkit-center", alignSelf: "center", fontSize: "1.14em" }}>
                <FormattedMessage id="partners.hpe.title" defaultMessage="A HPE é parceira do EyeFlow. Nós contamos com seus servidores em nossa infraestrutura!"/> 
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
