import React from "react";
import { FormattedMessage } from "react-intl";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import fluid_turquoise from "assets/img/fluid-turquoise.png"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import targetsStyle from "assets/jss/material-kit-pro-react/Pages/partnersSections/targetsStyle.js";

const useStyles = makeStyles(targetsStyle);

export default function SectionTargets() {
  const classes = useStyles();
  return (
    <div className={classes.targets}>
      <GridContainer>
        <GridItem
          data-aos="fade-right"
          md={5}
          sm={5}
          padding={10}
          style={{ position: "relative", textAlign: "center", color: "white" }}
          className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.textCenter
          )}
        >
          <div style={{ position: "relative", textAlign: "center", color: "white" }}>
            <img src={fluid_turquoise} alt="Snow" style={{ width:"100%" }}></img>
            <div style={{ position: "absolute", top: "60%", left: "50%", transform: "translate(-50%, -50%)", width: "20em"  }}>
              <h4 style={{ fontFamily: "'Exo 2'", fontWeight: 600 }}>
                <FormattedMessage
                  id="partners.targets.cardTitle1"
                  defaultMessage="Especialistas em Sistemas de Visão"
                />
              </h4>
              <h4 style={{ fontFamily: "'Exo 2'", fontWeight: 400 }}>
                <FormattedMessage
                  id="partners.targets.subtitle1"
                  defaultMessage="A solução EyeFlow pode incrementar suas soluções ou resolver aquele desafio mais complexo que as soluções tradicionais de Machine Vision não atendem."
                />
              </h4>
            </div>
          </div>
        </GridItem>
        <GridItem data-aos="fade-left"
          md={5}
          sm={5}
          padding={10}
          className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.textCenter
          )}>
          <div style={{ position: "relative", textAlign: "center", color: "white" }}>
          <img src={fluid_turquoise} alt="Snow" style={{ width:"100%", WebkitTtransform: "scaleX(-1)", transform: "scaleX(-1)" }}></img>
          <div style={{ position: "absolute", top: "60%", left: "50%", transform: "translate(-50%, -50%)", width: "20em" }}>
          <h4 style={{ fontFamily: "'Exo 2'", fontWeight: 600 }}>
          <FormattedMessage
            id="partners.targets.cardTitle2"
            defaultMessage="Integradores"
          />
          </h4>
          <h4 style={{ fontFamily: "'Exo 2'", fontWeight: 400 }}>
          <FormattedMessage
            id="partners.targets.subtitle2"
            defaultMessage="Incorpore EyeFlow aos seus projetos de Video Analytics sem ter que se preocupar com as complexidades de IA."
          />
          </h4>
          </div>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
