import {
  container,
  title,
  main,
  whiteColor,
  mainRaised,
  blackColor,
  hexToRgb,
} from "assets/jss/material-kit-pro-react.js";

const landingPageStyle = {
  container: {
    background: whiteColor,
    ...container,
    height: "100vmin",
    flexWrap: "unset",
  },
  videoText: {
    height: "100vh",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    justifyItems: "center",
    alignItems: "center",
    margin: "1rem",
    alignContent: "center",
    gap: "3rem",
    maxWidth: "1150px",
    alignSelf: "center",
    position: "relative",
    "@media(max-width: 1030px)": {
      gridTemplateColumns: "1fr",
      alignContent: "center",
      textAlign: "center",
    },
  },

  logo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    ...title,
    fontFamily: "'Exo 2'",
    fontDisplay: "swap",
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "black",
    textDecoration: "none",
    fontSize: "3.2rem",
  },
  videoHome: {
    "& video": {
      borderRadius: "25px",
    },
  },
  parallaxHome: {
    backgroundColor: "white",
    marginBottom: "-10vh ",
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0",
  },
  gifEyeflow: {
    boxShadow:
      "0 16px 38px -12px rgba(" +
      hexToRgb(blackColor) +
      ", 0.56), 0 4px 25px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2)",
  },
  main: {
    ...main,
  },
  mainRaised: {
    ...mainRaised,
  },
  initHeader: {
    color: "success",
  },
  socialButtons: {
    marginLeft: "-6.5em",
    "@media (max-width: 480px)": {
      marginLeft: "0",
    },
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  left: {
    float: "left!important",
    padding: "15px 0",
  },
  right: {
    padding: "15px 0",
    float: "right!important",
  },
  center: {
    color: "inherit",
    fontFamily: "'Exo 2'",
    fontSize: "0.9em",
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative",
  },
  navLink: {
    position: "relative",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "0.9rem",
    fontFamily: "'Exo 2'",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    letterSpacing: "10%",
    display: "inline-flex",
  },
};

export default landingPageStyle;
