import { grayColor } from "assets/jss/material-kit-pro-react.js";

const formStyle = theme =>( {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    // margin: theme.spacing(1),
    position: 'relative',
  },
  fabProgress: {
    position: 'absolute',
    top: -5,
    left: -4,
    zIndex: 1,
    color: "#212121"
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  inputAdornment: {
    marginRight: "18px",
    position: "relative",
  },
  inputAdornmentIcon: {
    color: grayColor[13],
  },
  form: {
    margin: "0",
    width: '-webkit-fill-available'
  },
  textStyle:{
    padding: "10px 0px",
    // width: "130%",
  },
  textCenter: {
    textAlign: "center!important",
    fontFamily: "'Exo 2'"
  },
  buttonStyle:{
    textAlign: "center",
    backgroundColor: "#3f51b5",
    color: "white",
    padding: "12px 20px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    float: "right",
  },
});

export default formStyle;
