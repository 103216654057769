import {
  container,
  coloredShadow,
  title,
  mlAuto,
  grayColor,
  whiteColor,
  hexToRgb,
} from "assets/jss/material-kit-pro-react.js";

import { palleteColor2 } from "assets/jss/land_cores.js";

import expandingCards from "assets/jss/material-kit-pro-react/components/expandingCardsStyle.js";

const solutionStyle = {
  container,
  coloredShadow,
  mlAuto,
  ...expandingCards,
  cards: {
    justifyContent: "center",
    "@media (min-width: 695px)": {
      gridTemplateColumns: "repeat(2, 1fr)",
      justifyContent: "center",
    },
    "@media (min-width: 1060px)": {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    "@media (min-width: 1410px)": { gridTemplateColumns: "repeat(4, 1fr)" },
  },
  parallax: {
    "& $react-parallax-background-children": {
      left: 0,
    },
  },
  appButton: {
    borderRadius: "50px",
    fontFamily: "'Exo 2'",
    width: "38rem",
    border: "3px solid rgb(246 169 53)",
    fontWeight: "600",
    paddingBlock: "1rem",
    backgroundColor: "white",
    zIndex: 5,
    fontSize: "1.1rem",
    "@media (max-width:607px)": {
      width: "20rem",
    },
    "&:hover": {
      transform: "scale(1.05)",
      transition: "80ms",
    },
  },
  card: {
    perspective: "1000rem",
    perspectiveOrigin: "center",
    width: "340px",
    height: "377px",
    backgroundColor: "transparent",
    padding: "10px",
    position: "relative",
    transition: ".5s",
    "&:hover,&:focus": {
      transform: "scale(1.05)",
    },
  },
  cardStyle: {
    position: "relative",
    width: "100%",
    height: "100%",
    textAlign: "center",
    transition: "transform 0.6s",
    transformStyle: "preserve-3d",
    boxShadow: "rgb(133 77 0) 0px 1px 5px 0px",
    // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    "&:hover": {
      transform: "rotateY(180deg)",
    },
    borderRadius: "6px",
  },
  cardFront: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(255, 247, 236)",
    "-webkit-backface-visibility": "hidden",
    backfaceVisibility: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "6px",
  },
  cardBack: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
    transform: "rotateY(180deg)",
    borderRadius: "6px",
    "-webkit-backface-visibility": "hidden",
    backfaceVisibility: "hidden",
  },
  cardVideo: {
    width: "100%",
    position: "absolute",
    right: 0,
    bottom: 0,
    minWidth: "100%",
    minHeight: "100%",
    height: "auto",
    // zIndex: "-100",
    backgroundSize: "cover",
    overflow: "hidden",
  },
  section: {
    // "@media (min-width: 1500px)": {
    //   paddingInline: "15rem",
    // },
    // "@media (max-width: 376px)": {
    //   marginLeft: "-16.4em",
    // },
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "left",
    width: "max",
    marginTop: "2m",
    // marginLeft: "-8.5em",
  },
  sectionText: {
    // width: "90%",
  },
  wrapperBackground: {
    backgroundPosition: "50%",
    backgroundSize: "cover",
    textAlign: "center",
    "&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''",
      backgroundColor: "rgba(" + hexToRgb(whiteColor) + ", 0)",
      borderRadius: "6px",
    },
  },
  title: {
    ...title,
    "@media (max-width: 480px)": {
      fontSize: "9vw",
      width: "90%",
    },
    width: "50%",
    fontFamily: "'Exo 2'",
    fontSize: "60px",
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: palleteColor2,
    textDecoration: "none",
  },

  turnIcon: {
    "@media (min-width:1060px)": {
      display: "none"
    },
  },

  sectionImage: {
    height: "1100px",
    width: "300px",
    marginTop: "300px",
  },
  imageFluid: {
    "@media (max-width: 480px)": {
      display: "none",
    },
    height: "1100px",
    margin: "110px 0px 0px -850px",
  },
  sectionCards: {
    // padding: "0px 0",
    // textAlign: "left",
    marginTop: "-15px",
    // width: "90%",
  },
  cardTitle: {
    fontFamily: "'Exo 2'",
    fontSize: "20px",
    display: "inline-block",
    position: "relative",
    fontWeight: "700",
    textDecoration: "none",
    color: grayColor[1],
  },
  cardDescription: {
    fontFamily: "'Exo 2'",
    fontSize: "20px",
    fontWeight: "400",
    display: "inline-block",
    position: "relative",
    textDecoration: "none",
    color: grayColor[1],
    paddingInline: "1rem",
  },
};

export default solutionStyle;
