import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";

import logo_eyeflow_mask from "assets/img/logos/logoEyeFlowNameless4.png";
import background from "assets/img/fluid-blue.png";
import styles from "assets/jss/material-kit-pro-react/components/signUpPopUp.js";

const useStyles = makeStyles(styles);

export default function SignUpPopup(props) {
  const classes = useStyles();
  const intl = useIntl();
  const [open, setOpen] = React.useState(false);
  const [dados, setDados] = React.useState({
    email: "",
    nome: "",
    sobrenome: "",
  });

  React.useEffect(() => {
    let closePopUp = localStorage.getItem("closePopUp");
    setTimeout(() => {
      if (!closePopUp) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }, 1000);
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    window.location.href = `/signup?popUpUser=${JSON.stringify(dados)}`;
    localStorage.setItem("closePopUp", true);
  };

  const handleChange = (e) => {
    dados[e.target.name] = e.target.value;
    setDados(dados);
  };

  const handleClose = () => {
    localStorage.setItem("closePopUp", true);
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{
            alignSelf: "center",
            textAlign: "center",
            marginBottom: "-0.5em",
          }}
        >
          <img
            src={logo_eyeflow_mask}
            alt="eyeflow"
            className={classes.eyeflowImage}
          />
          <br />
          <div
            style={{
              fontFamily: "'Exo 2'",
              color: "#2a5cc6",
              fontVariant: "all-petite-caps",
              fontSize: "1.3em",
            }}
          >
            <FormattedMessage
              id="signup.popup_signup"
              defaultMessage="Cadastre-se"
            />
          </div>
        </DialogTitle>
        <DialogContent style={{ backgroundImage: "url(" + background + ")" }}>
          <DialogContentText
            style={{ alignSelf: "center", textAlign: "center" }}
          >
            <div style={{ fontFamily: "'Exo 2'" }}>
              <FormattedMessage
                id="signup.popup_description"
                defaultMessage="Preencha os dados abaixo para se cadastrar em nossa plataforma!"
              />
            </div>
          </DialogContentText>
          <form id="popup_signup" onSubmit={handleSubmit}>
            <TextField
              margin="dense"
              required
              id="email"
              name="email"
              label={
                <span style={{ fontFamily: "'Exo 2'" }}>
                  <FormattedMessage id="signup.field3" defaultMessage="Email" />
                </span>
              }
              fullWidth
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              required
              id="nome"
              name="nome"
              label={
                <span style={{ fontFamily: "'Exo 2'" }}>
                  <FormattedMessage id="signup.field1" defaultMessage="Nome" />
                </span>
              }
              fullWidth
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              required
              id="sobrenome"
              name="sobrenome"
              style={{ paddingBottom: "0.3em" }}
              label={
                <span style={{ fontFamily: "'Exo 2'" }}>
                  <FormattedMessage
                    id="signup.field2"
                    defaultMessage="Sobrenome"
                  />
                </span>
              }
              fullWidth
              onChange={handleChange}
            />
            <div>
              <input
                className={classes.buttonStyle}
                type="submit"
                id="submitButton"
                value={intl.formatMessage({
                  id: "signup.field7",
                  defaultMessage: "Olá, ",
                })}
              />
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
