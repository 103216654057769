import {
  mrAuto,
  mlAuto,
  title,
  description,
  grayColor
} from "assets/jss/material-kit-pro-react.js";

const teamStyle = {
  mrAuto,
  mlAuto,
  title,
  description: {
    ...description,
    marginBottom: "80px"
  },
  cardTitle:{
    color: grayColor[0],
    fontFamily: "'Exo 2'",
    fontWeight: 400
  },
  cardDescription: {
    color: grayColor[0],
    fontWeight: 400,
    fontFamily: "'Exo 2'"
  },
  team: {
    padding: "80px 0px"
  },
  textCenter: {
    fontFamily: "'Exo 2'",
    textAlign: "center!important",
  },
  picProfile:{
    height: "100%"
  },
  img: {
    width: "100%",
    height: "auto"
  },
  textMuted: {
    color: grayColor[7] + " !important"
  },
  justifyContent: {
    justifyContent: "center!important"
  }
};

export default teamStyle;
