import React from "react";
import { FormattedMessage } from "react-intl";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import SignUpPopup from "components/SignUpPopup/SignUpPopup";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import PageFooter from "components/Footer/PageFooter.js";

import HeaderLinks from "components/Header/PageHeaderLinks.js";

// sections for this page
import SectionTeam from "Pages/AboutUsPage/Sections/SectionTeam.js";

import aboutUsStyle from "assets/jss/material-kit-pro-react/Pages/aboutUsStyle.js";
const useStyles = makeStyles(aboutUsStyle);

export default function AboutUsPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();
  return (
    <div>
      {/* <FloatingButton/> */}
      <Header
        brand="EyeFlow.AI"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 300,
          color: "white",
        }}
      />
      <SignUpPopup />
      <Parallax
        image={require("assets/img/backgroundAboutUs.jpg")}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter,
              )}
            >
              <h1 style={{ fontFamily: "'Exo 2'" }} className={classes.title}>
                <FormattedMessage
                  id="about.title1"
                  defaultMessage="Sobre Nós"
                />
              </h1>
              <h4 style={{ fontFamily: "'Exo 2'", fontWeight: 400 }}>
                <FormattedMessage
                  id="about.title2"
                  defaultMessage="Conheça o time por trás desse projeto "
                />
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <SectionTeam />
        </div>
      </div>
      <PageFooter />
    </div>
  );
}
