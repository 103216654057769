import {
  grayColor,
  // whiteColor,
  mlAuto,
  // hexToRgb
} from "assets/jss/material-kit-pro-react.js";

import tooltip from "assets/jss/material-kit-pro-react/tooltipsStyle.js";

const headerLinksStyle = (theme) => ({
  list: {
    [theme.breakpoints.up("md")]: {
      WebkitBoxAlign: "center",
      MsFlexAlign: "center",
      alignItems: "center",
      WebkitBoxOrient: "horizontal",
      WebkitBoxDirection: "normal",
      MsFlexDirection: "row",
      flexDirection: "row",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    marginTop: "0px",
    display: "flex",
    paddingLeft: "0",
    marginBottom: "0",
    listStyle: "none",
    padding: "0",
  },

  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    "&:hover": {
      color: "inherit",
      "&:after": {
        content: '""',
        width: "100%",
        height: ".14rem",
        background: "rgb(44, 54, 151)",
        display: "flex",
        transition: "all 0.5s ease",
        borderRadius: "25px",
      },
    },
    "&:after": {
      content: '""',
      width: 0,
      height: ".14rem",
      background: "rgb(44, 54, 151)",
      borderRadius: "25px",
      display: "flex",
      transition: "all 0.5s ease",
    },
    "&, &:svg": {
      backgroundColor: "white",
    },
    [theme.breakpoints.down("sm")]: {
      "& ul": {
        maxHeight: "400px",
        overflow: "scroll",
      },
      width: "100%",
      "&:not(:last-child)": {
        "&:after": {
          width: "calc(100% - 30px)",
          content: '""',
          display: "block",
          height: "1px",
          marginLeft: "15px",
          backgroundColor: grayColor[14],
        },
      },
    },
  },

  listItemLanguage: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    "&, &:svg": {
      backgroundColor: "white",
    },
    [theme.breakpoints.down("sm")]: {
      "& ul": {
        maxHeight: "400px",
        overflow: "scroll",
      },
      width: "100%",
      "&:not(:last-child)": {
        "&:after": {
          width: "calc(100% - 30px)",
          content: '""',
          display: "block",
          height: "1px",
          marginLeft: "15px",
          backgroundColor: grayColor[14],
        },
      },
    },
  },

  listItemSignUp: {
    float: "left",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    color: "white",
    "&, &:svg": {
      backgroundColor: "white",
    },
    [theme.breakpoints.down("sm")]: {
      "& ul": {
        maxHeight: "400px",
        overflow: "scroll",
      },
      width: "100%",
      "&:not(:last-child)": {
        "&:after": {
          width: "calc(100% - 30px)",
          content: '""',
          display: "block",
          height: "1px",
          marginLeft: "15px",
          backgroundColor: grayColor[14],
        },
      },
    },
  },
  listItemText: {
    padding: "0 !important",
  },
  navLinkDropDown: {
    color: "inherit",
    position: "relative",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "0.9rem",
    textTransform: "capitalize",
    fontFamily: "'Exo 2'",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    letterSpacing: "10%",
    display: "inline-flex",
    "&:hover,&:focus": {
      color: "inherit",
    },
    "& .fab,& .far,& .fal,& .fas,& .material-icons": {
      position: "relative",
      top: "2px",
      marginTop: "-4px",
      marginRight: "4px",
      marginBottom: "0px",
      fontSize: "1.25rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "8px",
      marginTop: "8px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start",
      },
    },
    "& svg": {
      marginRight: "3px",
      width: "20px",
      height: "20px",
    },
  },

  eyeflowImage: {
    "@media (max-width: 600px)": {
      maxWidth: "147px",
    },
    maxWidth: "190px",
    width: "auto",
  },

  navLink: {
    color: "inherit",
    position: "relative",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "0.9rem",
    // textTransform: "capitalize",
    fontFamily: "'Exo 2'",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    letterSpacing: "10%",
    display: "inline-flex",
    "&:hover,&:focus": {
      color: "inherit",
    },
    "& .fab,& .far,& .fal,& .fas,& .material-icons": {
      position: "relative",
      top: "2px",
      marginTop: "-4px",
      marginRight: "4px",
      marginBottom: "0px",
      fontSize: "1.25rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "8px",
      marginTop: "8px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start",
      },
    },
    "& svg": {
      marginRight: "3px",
      width: "20px",
      height: "20px",
    },
  },

  navLinkLanguage: {
    color: "inherit",
    position: "relative",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "0.9rem",
    // textTransform: "capitalize",
    fontFamily: "'Exo 2'",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    letterSpacing: "10%",
    display: "inline-flex",
    "&:hover,&:focus": {
      color: "inherit",
      "& span": {
        transform: "scale(1.1, 1.1)",
        transition: "50ms",
      },
    },
    "& .fab,& .far,& .fal,& .fas,& .material-icons": {
      position: "relative",
      top: "2px",
      marginTop: "-4px",
      marginRight: "4px",
      marginBottom: "0px",
      fontSize: "1.25rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "8px",
      marginTop: "8px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start",
      },
    },
    "& svg": {
      marginRight: "3px",
      width: "20px",
      height: "20px",
    },
  },

  navLinkSignUp: {
    borderRadius: "25px",
    textAlign: "center",
    position: "relative",
    // height: "3.4em",
    padding: "0.34rem 1.5rem",
    fontWeight: "400",
    fontSize: "0.9rem",
    // textTransform: "capitalize",
    fontFamily: "'Exo 2'",
    lineHeight: "16px",
    textDecoration: "none",
    margin: "0px",
    letterSpacing: "10%",
    display: "inline-flex",
    // background:`linear-gradient(317deg, #4e67f8, rgba(255,0,0,0) 70.71%),
    // linear-gradient(104deg, #ff3737, rgba(0,255,0,0) 70.71%),
    // linear-gradient(145deg, #e2e6fd, rgb(238 154 76 / 88%) 70.71%)`,
    "&:hover,&:focus": {
      // background:`linear-gradient(140deg, #4e67f8, rgba(255,0,0,0) 70.71%),
      // linear-gradient(291deg, #ff3737, rgba(0,255,0,0) 70.71%),
      // linear-gradient(316deg, #e2e6fd, rgb(238 154 76 / 88%) 70.71%)`,
      transform: "scale(1.05)",
      // fontWeight: "900",
      color: "#7CA2C8",
      border: ".18rem solid #7CA2C8",
      transition: "80ms",
    },
    // backgroundColor: "#7CA2C8",
    color: "#7CA2C8",
    border: ".15rem solid #7CA2C8",
    "& .fab,& .far,& .fal,& .fas,& .material-icons": {
      position: "relative",
      top: "2px",
      marginTop: "-4px",
      marginRight: "4px",
      marginBottom: "0px",
      fontSize: "1.25rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "8px",
      marginTop: "8px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start",
      },
    },
    "& svg": {
      marginRight: "3px",
      width: "20px",
      height: "20px",
    },
  },
  navLinkJustIcon: {
    "& .fab,& .far,& .fal,& .fas,& .material-icons": {
      marginRight: "0px",
    },
    "& svg": {
      marginRight: "0px",
    },
  },
  navButton: {
    position: "relative",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "5px",
      marginTop: "5px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start",
      },
    },
    "& $icons": {
      marginRight: "3px",
    },
  },
  notificationNavLink: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
  },
  registerNavLink: {
    position: "relative",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
  },
  // navLinkActive: {
  //   "&, &:active ": {
  //     "&:after": {
  //       content: "''",
  //       display: "block",
  //     },
  //   },
  //   "&:hover, &:focus": {
  //     borderBottom: "none",
  //   },
  // },
  icons: {
    width: "20px",
    height: "20px",
    marginRight: "14px",
  },
  dropdownIcons: {
    width: "24px",
    height: "24px",
    marginRight: "14px",
    opacity: "0.5",
    marginTop: "-4px",
    top: "1px",
    verticalAlign: "middle",
    fontSize: "24px",
    position: "relative",
  },
  socialIcons: {
    position: "relative",
    fontSize: "1.25rem",
    maxWidth: "24px",
  },
  dropdownLink: {
    "&,&:hover,&:focus": {
      fontFamily: "'Exo 2'",
      color: "inherit",
      textDecoration: "none",
      display: "flex",
      padding: "0.75rem 1.25rem 0.75rem 0.75rem",
    },
  },
  ...tooltip,
  marginRight5: {
    marginRight: "5px",
  },
  collapse: {
    [theme.breakpoints.up("md")]: {
      display: "flex !important",
      MsFlexPreferredSize: "auto",
      whiteSpace: "nowrap",
      flexBasis: "auto",
    },
    WebkitBoxFlex: "1",
    MsFlexPositive: "1",
    flexGrow: "1",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center",
  },
  mlAuto,
});

export default headerLinksStyle;
