import {
  grayColor,
  container,
  cardTitle,
  whiteColor,
  blackColor,
  hexToRgb,
} from "assets/jss/material-kit-pro-react.js";

import customCheckboxRadioSwitchStyle from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js";

const signupPageStyle = {
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    paddingTop: "20vh",
    color: whiteColor,
  },
  googleButton: {
    backgroundColor: "rgb(255, 255, 255)",
    display: "inline-flex",
    alignItems: "center",
    color: "rgba(0, 0, 0, 0.54)",
    boxShadow: "rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px",
    padding: "0px",
    borderRadius: "2px",
    border: "1px solid transparent",
    fontWeight: "500",
    fontFamily: "Roboto, sans-serif",
    marginBottom: "1em",
    cursor: "pointer"
  },
  imageFluidYellow: {
    height: "1100px",
    margin: "-1300px 0px 0px -800px",
    WebkitTransform:"rotate(-90deg)",
    MozTransform: "rotate(-90deg)",
    MsTransform: "rotate(-90deg)",
    OTransform: "rotate(-90deg)",
    transform: "rotate(-90deg)"
  },
  imageFluidBlue: {
    height: "1100px",
    margin: "-550px 0px 0px 1310px",
  },
  sectionImageYellowBottom: {
    "@media (max-width: 480px)":{
      display: "none"
    },
  },
  imageFluidYellowBottom: {
    height: "1100px",
    margin: "-550px 0px -600px 1310px",
    WebkitTransform:"rotate(-90deg)",
    MozTransform: "rotate(-90deg)",
    MsTransform: "rotate(-90deg)",
    OTransform: "rotate(-90deg)",
    transform: "rotate(-90deg)"
  },
  imageFluidBlueBottom: {
    margin: "-700px 0px -1100px -850px",
    height: "1100px",
  },
  pageHeader: {
    minHeight: "10vh",
    maxHeight: "200vh",
    height: "auto",
    display: "inherit",
    position: "relative",
    margin: "0",
    padding: "0",
    border: "0",
    alignItems: "center",
    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""',
    }
  },
  cardSignup: {
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 6px 30px 5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2);",
    padding: "40px 0px",
  },
  cardTitle: {
    ...cardTitle,
    textDecoration: "none",
    textAlign: "center !important",
    marginBottom: "0.75rem",
  },
  ...customCheckboxRadioSwitchStyle,
  socials: {
    marginTop: "0",
    position: "absolute",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
  },
  textCenter: {
    textAlign: "center",
    backgroundColor: "#3f51b5",
    color: "white",
    padding: "12px 20px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    float: "right",
  },
  thanksMessage: {
    backgroundColor: "lightblue",
    width: "-webkit-fill-available",
    placeSelf: "center",
    padding: "6em 1em",
    borderRadius: "25px",
    fontFamily: "'Exo 2'",
    fontWeight: "500",
    fontSize: "2em",
    lineHeight: "1em",
    "& img": {
      width: "5em"
    }
  },
  inputAdornment: {
    marginRight: "18px",
    position: "relative",
  },
  inputAdornmentIcon: {
    color: grayColor[13],
  },
  labelCheckBox: {
    "@media (max-width: 480px)": {
      whiteSpace:'normal'
    },
    whiteSpace:'nowrap'
  },
  form: {
    margin: "0",
    width: '-webkit-fill-available'
  },
  nameField: {
    "@media (max-width: 1226px)":{
      marginRight: "0em",
      width: "-webkit-fill-available"
    },
    marginRight: "0.8em",
    padding: "10px 0px"
  },
  lastNameField: {
    "@media (max-width: 1226px)":{
      width: "-webkit-fill-available"
    },
    padding: "10px 0px"
  },
  infoArea: {
    padding: "0px 0px 20px !important",
    fontFamily: "'Exo 2'",
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  left: {
    float: "left!important",
    padding: "15px 0",
    "&,& *,& *:hover,& *:focus": {
      color: grayColor + "  !important"
    }
  },
  right: {
    padding: "15px 0",
    float: "right!important",
    "&,& *,& *:hover,& *:focus": {
      color: grayColor + "  !important"
    }
  },
  socialButtons: {
    marginTop: "-3vh",
    marginLeft: "-10vh",
    "& li": {
      display: "inline-block",
    },
  },
  logo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  center: {
    color: "inherit",
    fontFamily: "'Exo 2'",
    fontSize: "0.9em",
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative",
  },
};

export default signupPageStyle;
