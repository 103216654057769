import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import SignUpPopup from "components/SignUpPopup/SignUpPopup";
import HeaderLinks from "components/Header/PageHeaderLinks.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import PageFooter from "components/Footer/PageFooter.js";

// sections for this page
import SectionPills from "./Sections/SectionPills.js";

import blogPostsPageStyle from "assets/jss/material-kit-pro-react/Pages/blogPostsPageStyle.js";

const useStyles = makeStyles(blogPostsPageStyle);

const BlogPostsPage = ({ intl, ...rest }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      {/* <FloatingButton /> */}
      <Header
        absolute
        brand="EyeFlow.AI"
        color="white"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        {...rest}
      />
      <SignUpPopup />
      <Parallax
        image={require("assets/img/backgroundAboutUs.jpg")}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8} className={classes.textCenter}>
              <h2 className={classes.title}>
                <FormattedMessage
                  id="blog.title"
                  defaultMessage="Descubra mais sobre a plataforma"
                />
              </h2>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.main}>
        <div className={classes.container}>
          <SectionPills />
        </div>
      </div>

      <PageFooter />
    </div>
  );
};

export default injectIntl(BlogPostsPage);
