import {
  grayColor,
  container,
  title
} from "assets/jss/material-kit-pro-react.js";

import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";

const sectionTextStyle = {
  container,
  title,
  section: {
    paddingBottom: "0",
    backgroundPosition: "50%",
    backgroundSize: "cover",
    padding: "70px 0",
    "& p": {
      fontSize: "1.188rem",
      lineHeight: "1.5em",
      color: grayColor[15],
      marginBottom: "30px"
    }
  },
  quoteText: {
    fontSize: "1.5rem !important"
  },
  authorStyle:{
    "@media (max-width: 480px)": {
      display: "contents",
      textAlign: "center"
    },
    fontFamily:"'Exo 2'",
    fontWeight:"bolder", 
    display: "-webkit-box",
    width: "30em",
    textAlign: "left",
    height: "11em"
  },
  authorDescription:{
    "@media (max-width: 480px)": {
      width: "17em",
    },
    fontFamily:"'Exo 2'",
    fontWeight:"bolder",
    fontSize: "15px",
    width: "34em",
    marginBottom: "0.5em"
  },
  ...imagesStyles
};

export default sectionTextStyle;
