import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import apisauce from "apisauce";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import WorkIcon from "@material-ui/icons/Work";
import WebIcon from "@material-ui/icons/Web";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Form from "components/Form/FormFields";
import contactStyle from "assets/jss/material-kit-pro-react/Pages/partnersSections/contactStyle.js";
import EyeFlowImg from "assets/img/logos/logoEyeFlowNameless32.png";
const useStyles = makeStyles(contactStyle);

const SectionContact = ({ intl }) => {
  const classes = useStyles();
  const [sentEmail, setSentEmail] = React.useState(false);
  // const [formCondtion, setFormCondtion] = React.useState(false)

  const [dados, setDados] = React.useState({
    name: "",
    empresa: "",
    celular: "",
    email: "",
    site_da_empresa: "",
  });

  const api = apisauce.create({
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const sendEmail = (email) =>
    api.post(`${window.WS_URL}/send-email`, { ...email });

  function handleFormSubmit(e) {
    e.preventDefault();
    sendEmail({
      receivers: dados.email,
      subject: "EyeFlow Partner",
      html: `<html><body><table style="background-color:#f0fdfc;border-collapse:collapse;border-color:#add8e6;height:200px;border-style:inset" border="1" width="700" cellspacing="0" cellpadding="0" align="center"><tbody><tr style="border-bottom:0;border-top:0"><td style="padding:40px 0 30px 0;border-bottom:0;border-top:0" align="center"><a href="https://eyeflow.ai/" target="_blank" rel="noreferrer noopener nofollow"><img class="CToWUd" style="display: block;" src="https://eyeflow.ai/static/media/logoEyeFlowNameless4.51908118.png" alt="EyeFlow" width="200&quot;" height="70"></a></td></tr><tr><td style="padding-top:30px;padding-left:80px;padding-right:90px;margin:0;font-size:15px;line-height:38px;font-weight:200;color:grey;border-bottom:0;border-top:0" align="center">
    Hello, ${dados.name}</td></tr><tr><td style="padding-top:20px;padding-left:80px;padding-right:90px;margin:0;font-size:15px;line-height:38px;font-weight:200;color:grey;border-bottom:0;border-top:0" align="center">
      Good to know that you are interested in partnering with EyeFlow!
    <br>
    We will contact you but do not hesitate to contact us at any time via sales@eyeflow.ai.
    <br></td></tr><tr><td style="padding-top:10px;padding-left:90px;padding-right:90px;margin:0;font-size:15px;line-height:38px;font-weight:200;color:grey;border-bottom:0;border-top:0" align="center">
    Thank you!
    <br>
    Team EyeFlow.AI
    </td></tr><tr><td style="border-bottom:0;border-top:0;padding:40px 0 30px 0" align="center"><a href="https://siliconlife.ai/"><img style="display: block;" src="https://eyeflow.ai/images/logo-siliconlife.png" alt="SiliconLife" width="190&quot;" height="80"></a></td></tr></tbody></table></body></html>`,
    }).then((result) => {
      if (result.ok) {
        setSentEmail(true);
      }
    });

    // sendEmail({
    //   receivers: "contato@siliconlife.ai",
    //   subject: "Parceiro EyeFlow",
    //   text: `
    // Nome: ${dados.nome}\n
    // Empresa: ${dados.empresa}\n
    // E-mail: ${dados.email}\n
    // Celular: ${dados.celular}\n
    // Site da empresa: ${dados.site_da_empresa}\n
    // Idioma: ${
    //   (navigator.languages && navigator.languages[0]) ||
    //   navigator.language ||
    //   window.navigator.userLanguage
    // }
    // `,
    // }).then((result) => {
    //   if (result.ok) {
    //     setSentEmail(true);
    //   }
    // });

    setTimeout(() => {
      setSentEmail(false);
    }, 10000);
  }

  function handleInputChange(e) {
    dados[e.target.name] = e.target.value;
    setDados(dados);
  }

  function handlePhoneChange(value, country, e, formattedValue) {
    dados["celular"] = formattedValue;
    setDados(dados);
  }

  const fields = [
    {
      label: <FormattedMessage id="Temp1" defaultMessage="Name" />,
      // <FormattedMessage id="partners.contact.field1" defaultMessage="Nome" />,
      required: true,
      isFull: true,
    },
    {
      label: <FormattedMessage id="Temp2" defaultMessage="Company" />,
      // <FormattedMessage id="partners.contact.field2" defaultMessage="Empresa" />
      required: true,
      isFull: true,
    },
    {
      label: <FormattedMessage id="Temp3" defaultMessage="Email" />,
      // <FormattedMessage id="partners.contact.field3" defaultMessage="Email" />,
      required: true,
      isFull: true,
    },
    {
      label: <FormattedMessage id="Temp4" defaultMessage="Cell phone" />,
      // <FormattedMessage
      //   id="partners.contact.field4"
      //   defaultMessage="Celular"
      // />,
      required: true,
      isFull: true,
    },
    {
      label: <FormattedMessage id="Temp5" defaultMessage="Company Site" />,
      // <FormattedMessage
      //   id="partners.contact.field5"
      //   defaultMessage="Site da empresa"
      // />
      required: false,
      isFull: true,
    },
  ];

  const emailResponse = (
    <div className={classes.thanksMessage}>
      <img src={EyeFlowImg} alt="" />
      <br />
      <br />
      <FormattedMessage
        id="Temp5"
        defaultMessage="Thank you for contacting us!"
      />
    </div>
  );

  return (
    <div className={classes.section}>
      <GridContainer>
        <GridItem
          data-aos="fade-up"
          xs={10}
          sm={9}
          md={8}
          style={{ display: sentEmail ? "none" : "" }}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h2 className={classNames(classes.title, classes.textCenter)}>
            {/* <FormattedMessage
              id="partners.contact.title"
              defaultMessage="Entre em contato conosco e vamos conversar!"
            /> */}
            <FormattedMessage
              id="Temp7"
              defaultMessage="Contact us and let's chat!"
            />
          </h2>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={10} sm={9} md={8}>
          {sentEmail ? (
            emailResponse
          ) : (
            <Form
              disabledButton={false}
              isFullWidth={true}
              // names={['field-one', 'field-two', 'field-three']}
              fields={fields}
              icons={[<Face />, <WorkIcon />, <Email />, <WebIcon />]}
              handleInputChange={handleInputChange}
              handleFormSubmit={handleFormSubmit}
              handlePhoneChange={handlePhoneChange}
              emailResponse={emailResponse}
              sentEmail={sentEmail}
              formId="contactPartners"
              buttonText="Submit"
            />
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
};
export default injectIntl(SectionContact);
