import {
  container,
  mrAuto,
  mlAuto,
  description
} from "assets/jss/material-kit-pro-react.js";

const logosStyle = {
  container,
  textCenter: {
    textAlign: "center"
  },
  aboutDescription: {
    padding: "70px 0 0 0"
  },
  mrAuto,
  mlAuto,
  description,
  imgEdge:{
    width: 150,
    margin:"0px 50px",
    "@media (max-width: 783px)":{
      margin:"20px 50px"
    },
  },
  imgEyeflow:{
    width: 200,
    margin:"0px 50px",
    "@media (max-width: 783px)":{
      margin:"20px 50px"
    },
  },
};

export default logosStyle;
