import {
  title,
  description,
  mrAuto,
  mlAuto,
  grayColor
} from "assets/jss/material-kit-pro-react.js";

import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";

const imgRaised = imagesStyles.imgRaised;
const rounded = imagesStyles.imgRounded;
const imgFluid = imagesStyles.imgFluid;

const vantagensStyle = {
  title:{
    ...title,
    fontFamily: "'Exo 2'"
  },
  description,
  mrAuto,
  mlAuto,
  textCenter: {
    textAlign: "center!important",
    fontFamily: "'Exo 2'"
  },
  vantagens: {
    marginTop:"-2.5em",
    marginLeft:"-1.05em",
    // marginBottom:"-40em",
    "& img": {
      margin: "0px -340px",
      padding: "15px 0px",
      width: "1680px"
    },
    "@media (max-width: 480px)":{
      display: "none"
    },
  },
  vantagensMobile: {
    display: "none",
    marginTop:"-2.5em",
    marginLeft:"-1.05em",
    // marginBottom:"-40em",
    "& img": {
      margin: "0px -340px",
      padding: "15px 0px",
      width: "1680px"
    },
    "@media (max-width: 480px)":{
      display: "block"
    },
  },
  cardDescription: {
    fontWeight: "300",
    fontFamily: "'Exo 2', sansSerif",
    fontSize: "20px",
    display: "inline-block",
    position: "relative",
    // marginTop: "30px",
    // minHeight: "32px",
    // marginLeft: "2rem",
    textDecoration: "none",
    color: grayColor[1]
  },
  cardBody:{
    // padding: "0.1em 1.9rem",
    padding: "0em 0rem"
  },
  titleCard:{
    fontFamily:"'Exo 2'",
    fontWeight: 800,
    fontSize: "30px"
  },
  descriptionCard:{
    fontFamily: "'Exo 2'",
    fontWeight: 400,
    fontSize: "16px"
  },
  cardsLine:{
    "@media (max-width: 480px)":{
      display:"none"
    },
    width:"135px",
    height: "0.9879px",
    borderWidth: "0",
    color: "#5BC4F1",
    backgroundColor: "#5BC4F1",
    alignSelf:"center",
    margin:"0.35em -0.8em 0em -0.8em",
    padding:"0em"
  },
  imgRaised,
  rounded,
  imgFluid
};

export default vantagensStyle;
