import { title, grayColor } from "assets/jss/material-kit-pro-react.js";

import { palleteColor1 } from "assets/jss/land_cores.js";

const solutionStyle = {
  section: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "max",
    // paddingBottom:"6vh",
    overflow: "visible",
  },
  solutionButton: {
    borderRadius: "50px",
    fontFamily: "'Exo 2'",
    width: "9rem",
    border: "3px rgb(124, 162, 200) solid",
    fontWeight: "600",
    paddingBlock: "0.1rem",
    backgroundColor: "white",
    zIndex: 5,
    "&:hover": {
      transform: "scale(1.05)",
      transition: "80ms",
    },
  },
  sectionText: {
    "@media (max-width: 600px)": {
      marginLeft: "0",
      marginRight: "1em",
      paddingLeft: "1em",
    },
    marginLeft: "30em",
    // width: "80%",
    padding: "70px 0",
  },
  title: {
    ...title,
    "@media (max-width: 600px)": {
      fontSize: "9.5vw",
    },
    marginLeft: "-0.075em",
    fontFamily: "'Exo 2'",
    fontSize: "60px",
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    // marginLeft: "2rem",
    color: palleteColor1,
    textDecoration: "none",
  },
  description: {
    "@media (max-width: 600px)": {
      width: "100%",
      fontSize: "18.5px",
    },
    fontFamily: "'Exo 2'",
    fontSize: "20px",
    // marginLeft: "2rem",
    width: "30em",
    lineHeight: "1.8",
    color: grayColor[1],
    fontWeight: 400,
  },
  sectionSvg: {
    width: "1500px",
    marginLeft: "35px",
  },
  sectionImage: {
    "@media (max-width: 600px)": {
      display: "none",
    },
    height: "600px",
    width: "450px",
    marginBottom: "-600px",
    overflow: "hidden",
  },
  imageFluid: {
    "@media (max-width: 600px)": {
      display: "none",
    },
    height: "1100px",
    margin: "-419px 0px 0px 824px",
  },
  sectionFlow: {
    "@media (max-width: 600px)": {
      width: "100%",
    },
    padding: "20px 0",
    textAlign: "left",
    width: "max",
  },
  flowHeader: {
    fontFamily: "'Exo 2', sansSerif",
    fontSize: "20px",
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    // marginLeft: "2rem",
    color: grayColor[1],
    textDecoration: "none",
  },
  sectionCards: {
    padding: "0px 0",
    textAlign: "left",
    width: "max",
    marginLeft: "-350px",
    marginTop: "-30px",
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
  cardContainer: {
    padding: "0px 10px",
  },
  sectionCardsMobile: {
    display: "none",
    "@media (max-width: 600px)": {
      display: "block",
      textAlign: "left",
      width: "max",
      marginTop: "-10px",
    },
  },
  cardTitle: {
    width: "236px",
    height: "23px",
    left: "calc(50% - 236px/2 - 175px)",
    top: "2382px",
    fontFamily: "Exo 2",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "30px",
    /* or 187% */
    color: "#FFFFFF",
  },
  cardDescription: {
    fontWeight: "300",
    fontFamily: "'Exo 2', sansSerif",
    fontSize: "20px",
    display: "inline-block",
    position: "relative",
    // marginTop: "30px",
    // minHeight: "32px",
    // marginLeft: "2rem",
    textDecoration: "none",
    color: grayColor[1],
  },
  imgVector5: {
    marginLeft: "-20px",
    marginBottom: "-70px",
    overflow: "hidden",
  },
  imgVector4: {
    marginTop: "60px",
    marginLeft: "-225px",
    marginBottom: "-320px",
    overflow: "hidden",
  },
};

export default solutionStyle;
