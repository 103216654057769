import React from "react";

import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/PageHeaderLinks.js";
import PageFooter from "components/Footer/PageFooter.js";
import SignUpPopup from 'components/SignUpPopup/SignUpPopup'

export default function TermsandConditions() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  // const classes = useStyles();
  return (
    <div>
      {/* <FloatingButton /> */}
      <Header
        brand="EyeFlow.AI"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
      />
      <SignUpPopup />
      <div
        style={{
          marginTop: "80px",
          marginRight: "50px",
          marginLeft: "50px",
          fontFamily: "'Exo 2'",
          fontWeight: 500,
        }}
      >
        <div>
          <div>
            <div style={{ marginTop: "20px" }}>
              <div>
                <div style={{ textAlign: "center" }}>
                  <p style={{ textAlign: "center", lineHeight: "normal" }}>
                    <strong>
                      <span
                        style={{
                          textAlign: "center",
                          fontSize: "13px",
                          color: "#415071",
                        }}
                      >
                        POL&Iacute;TICA DE PRIVACIDADE
                      </span>
                    </strong>
                  </p>
                  <p style={{ textAlign: "justify", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13px", color: "#415071" }}>
                      O EyeFlow ou (&ldquo;n&oacute;s&rdquo; ou
                      &ldquo;nosso&rdquo;) respeita a privacidade de nossos
                      usu&aacute;rios (&ldquo;usu&aacute;rio&rdquo; ou
                      &ldquo;voc&ecirc;&rdquo;). Esta Pol&iacute;tica de
                      Privacidade explica como coletamos, utilizamos, divulgamos
                      e armazenamos suas informa&ccedil;&otilde;es quando
                      voc&ecirc; visita nosso site https://eyelow.ai, incluindo
                      qualquer outra forma de m&iacute;dia, canal de
                      m&iacute;dia, site m&oacute;vel ou aplicativo m&oacute;vel
                      relacionado ou conectado a ele (coletivamente, o
                      &ldquo;site&quot;). Por favor, leia esta pol&iacute;tica
                      de privacidade com cuidado. SE VOC&Ecirc; N&Atilde;O
                      CONCORDA COM OS TERMOS DESTA POL&Iacute;TICA DE
                      PRIVACIDADE, N&Atilde;O ACESSE O SITE.
                    </span>
                  </p>
                  <p style={{ textAlign: "justify", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13px", color: "#415071" }}>
                      N&oacute;s nos reservamos o direito de fazer quaisquer
                      altera&ccedil;&otilde;es nesta Pol&iacute;tica de
                      Privacidade a qualquer momento e por qualquer motivo.
                      Iremos alert&aacute;-lo sobre quaisquer
                      altera&ccedil;&otilde;es, atualizando a data
                      &ldquo;Revisada&rdquo; desta Pol&iacute;tica de
                      Privacidade. Quaisquer altera&ccedil;&otilde;es ou
                      modifica&ccedil;&otilde;es entrar&atilde;o em vigor
                      imediatamente ap&oacute;s a publica&ccedil;&atilde;o da
                      Pol&iacute;tica de Privacidade atualizada no Site, e
                      voc&ecirc; renuncia ao direito de receber um aviso
                      espec&iacute;fico de cada altera&ccedil;&atilde;o ou
                      modifica&ccedil;&atilde;o. Recomendamos que voc&ecirc;
                      reveja periodicamente esta Pol&iacute;tica de Privacidade
                      para se manter informado sobre as
                      atualiza&ccedil;&otilde;es. Ser&aacute; considerado que
                      voc&ecirc; foi informado, estar&aacute; sujeito e aceitou
                      as mudan&ccedil;as em qualquer Pol&iacute;tica de
                      Privacidade revisada por seu uso continuado do Site
                      ap&oacute;s a data de publica&ccedil;&atilde;o da
                      Pol&iacute;tica de Privacidade revisada.
                    </span>
                  </p>
                  <ol style={{ listStyleType: "none", textAlign: "left" }}>
                    <li>
                      <strong>
                        <span>1. Coleta de suas informações</span>
                      </strong>
                    </li>
                  </ol>
                  <p style={{ textAlign: "justify", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13px", color: "#415071" }}>
                      Podemos coletar informações sobre você de várias maneiras.
                      As informações que podemos coletar no Site incluem:
                    </span>
                  </p>

                  <ol style={{ listStyleType: "inherit", textAlign: "left" }}>
                    <li>
                      <strong>
                        <span>Dados Pessoais</span>
                      </strong>
                    </li>
                  </ol>
                  <p style={{ textAlign: "justify", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13px", color: "#415071" }}>
                      Informações de identificação pessoal, como seu nome,
                      endereço de email, número de telefone celular, empresa a
                      qual pertence e/ou representa e respectivo segmento de
                      negócio, informações essas voluntariamente fornecidas por
                      você ao se registrar no Site ou quando você optar por
                      participar de diversas atividades relacionadas ao como
                      chat, FAQ e fóruns de discussão. Ao adquirir um dos nossos
                      planos pagos, são coletadas informações relativas a dados
                      financeiros para viabilizar a cobrança e contrato. Você
                      não tem nenhuma obrigação de nos fornecer informações
                      pessoais de qualquer tipo; no entanto, sua recusa em
                      fazê-lo pode impedi-lo de usar certos recursos do Site.
                    </span>
                  </p>
                  <ol style={{ listStyleType: "inherit", textAlign: "left" }}>
                    <li>
                      <strong>
                        <span>Dados Derivados</span>
                      </strong>
                    </li>
                  </ol>
                  <p style={{ textAlign: "justify", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13px", color: "#415071" }}>
                      Informações que nossos servidores coletam automaticamente
                      quando você acessa o Site, como seu endereço IP, tipo de
                      navegador, sistema operacional, horários de acesso e as
                      páginas que você visualizou diretamente antes e depois de
                      acessar o Site (Cookies).
                    </span>
                  </p>
                  <ol style={{ listStyleType: "none", textAlign: "left" }}>
                    <li>
                      <strong>
                        <span>
                          2. Finalidade de coleta, armazenamento e uso de suas
                          informações
                        </span>
                      </strong>
                    </li>
                  </ol>
                  <p style={{ textAlign: "justify", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13px", color: "#415071" }}>
                      Ter informações precisas sobre você nos permite fornecer
                      uma experiência eficiente e personalizada.
                      Especificamente, podemos usar as informações coletadas
                      sobre você por meio do Site para:
                    </span>
                  </p>
                  <ol
                    style={{
                      listStyleType: "inherit",
                      textAlign: "left",
                      fontSize: "13px",
                    }}
                  >
                    <li>
                      <span>Enviar um boletim informativo;</span>
                    </li>
                    <li>
                      <span>
                        Compilar dados estatísticos anônimos e análises para uso
                        interno ou com terceiros;
                      </span>
                    </li>
                    <li>
                      <span>Criar e gerenciar sua conta;</span>
                    </li>
                    <li>
                      <span>
                        Fornecer publicidade direcionada, cupons, boletins
                        informativos e promoções e outras informações
                        relacionadas ao nosso site e aplicativo móvel para você;
                      </span>
                    </li>
                    <li>
                      <span>
                        Enviar um email a você sobre sua conta ou pedido;
                      </span>
                    </li>
                    <li>
                      <span>
                        Gerir um perfil pessoal sobre você para tornar as
                        futuras visitas ao Site mais personalizadas;
                      </span>
                    </li>
                    <li>
                      <span>Aumentar a eficiência e operação do Site;</span>
                    </li>
                    <li>
                      <span>Notificá-lo sobre atualizações do site;</span>
                    </li>
                    <li>
                      <span>
                        Oferecer novos produtos, serviços e/ou recomendações
                        para você;
                      </span>
                    </li>
                    <li>
                      <span>
                        Executar outras atividades de negócios conforme
                        necessário;
                      </span>
                    </li>
                    <li>
                      <span>
                        Solicitar feedback e entrar em contato com você sobre o
                        uso do Site;
                      </span>
                    </li>
                    <li>
                      <span>
                        Prover segurança e garantir conformidade e prevenção a
                        fraudes; e
                      </span>
                    </li>
                    <li>
                      <span>Cumprir quaisquer leis e pedidos judiciais</span>
                    </li>
                  </ol>
                  <ol style={{ listStyleType: "none", textAlign: "left" }}>
                    <li>
                      <strong>
                        <span>3. Divulgação de suas informações</span>
                      </strong>
                    </li>
                  </ol>
                  <p style={{ textAlign: "justify", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13px", color: "#415071" }}>
                      Podemos compartilhar informações que coletamos sobre você
                      em determinadas situações. Suas informações podem ser
                      divulgadas da seguinte forma:
                    </span>
                  </p>

                  <ol
                    style={{
                      listStyleType: "inherit",
                      textAlign: "left",
                      fontSize: "13px",
                    }}
                  >
                    <li>
                      <strong>
                        <span>Por lei ou para proteger direitos</span>
                      </strong>
                    </li>
                    <p>
                      <span>
                        Se acreditarmos que a divulgação de informações sobre
                        você é necessária para responder a um processo legal,
                        para investigar ou remediar possíveis violações de
                        nossas políticas ou para proteger os direitos,
                        propriedade e segurança de terceiros, podemos
                        compartilhar suas informações conforme permitido ou
                        exigido por qualquer lei, regra ou regulamento
                        aplicável. Isso inclui a troca de informações com outras
                        entidades para proteção contra fraude e redução do risco
                        de crédito.
                      </span>
                    </p>
                    <li>
                      <strong>
                        <span>Provedores de serviços terceirizados</span>
                      </strong>
                    </li>
                    <p>
                      <span>
                        Podemos compartilhar suas informações com terceiros que
                        prestam serviços para nós ou em nosso nome, incluindo
                        processamento de pagamentos, análise de dados, entrega
                        de e-mail, serviços de hospedagem, atendimento ao
                        cliente e assistência de marketing.
                      </span>
                    </p>
                    <li>
                      <strong>
                        <span>Comunicações de marketing</span>
                      </strong>
                    </li>
                    <p>
                      <span>
                        Com o seu consentimento, ou com a oportunidade de
                        retirar o consentimento, podemos compartilhar suas
                        informações com terceiros para fins de marketing,
                        conforme permitido por lei.
                      </span>
                    </p>
                    <li>
                      <strong>
                        <span>Postagens Online</span>
                      </strong>
                    </li>
                    <p>
                      <span>
                        Quando você posta comentários, contribuições ou outro
                        conteúdo no Site, suas postagens podem ser vistas por
                        todos os usuários e podem ser distribuídas publicamente
                        fora do Site para sempre.
                      </span>
                    </p>
                    <li>
                      <strong>
                        <span>Outros Terceiros</span>
                      </strong>
                    </li>
                    <p>
                      <span>
                        Podemos compartilhar suas informações com anunciantes e
                        investidores com o objetivo de conduzir análises gerais
                        de negócios. Também podemos compartilhar suas
                        informações com terceiros para fins de marketing,
                        conforme permitido por lei.
                      </span>
                    </p>
                    <li>
                      <strong>
                        <span>Venda ou falência</span>
                      </strong>
                    </li>
                    <p>
                      <span>
                        Se reorganizarmos ou vendermos todos ou parte de nossos
                        ativos, passarmos por uma fusão ou forem adquiridos por
                        outra entidade, podemos transferir suas informações para
                        a entidade sucessora. Se fecharmos as portas ou
                        entrarmos em falência, suas informações serão um ativo
                        transferido ou adquirido por terceiros. Você reconhece
                        que tais transferências podem ocorrer e que o
                        cessionário pode recusar honrar os compromissos que
                        assumimos nesta Política de Privacidade.
                      </span>
                    </p>
                  </ol>
                  <p style={{ textAlign: "justify", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13px", color: "#415071" }}>
                      Não somos responsáveis pelas ações de terceiros com os
                      quais você compartilha dados pessoais ou confidenciais, e
                      não temos autoridade para gerenciar ou controlar
                      solicitações de terceiros. Se você não deseja mais receber
                      correspondência, e-mails ou outras comunicações de
                      terceiros, você é responsável por contatar o terceiro
                      diretamente.
                    </span>
                  </p>
                  <ol style={{ listStyleType: "none", textAlign: "left" }}>
                    <li>
                      <strong>
                        <span>4. Tecnologias de rastreamento</span>
                      </strong>
                    </li>
                  </ol>
                  <ol
                    style={{
                      listStyleType: "inherit",
                      textAlign: "left",
                      fontSize: "13px",
                    }}
                  >
                    <li>
                      <strong>
                        <span>Cookies e Web Beacons</span>
                      </strong>
                    </li>
                    <p>
                      <span>
                        Podermos usar cookies, web beacons, pixels de
                        rastreamento e outras tecnologias de rastreamento no
                        Site para ajudar a personalizar o Site e melhorar sua
                        experiência. Quando você acessa o Site, suas informações
                        pessoais não são coletadas por meio do uso de tecnologia
                        de rastreamento. A maioria dos navegadores está
                        configurada para aceitar cookies por padrão. Você pode
                        remover ou rejeitar cookies, mas esteja ciente de que
                        tal ação pode afetar a disponibilidade e funcionalidade
                        do Site. Você não pode recusar web beacons. No entanto,
                        eles podem se tornar ineficazes recusando todos os
                        cookies ou modificando as configurações do seu navegador
                        da web para notificá-lo cada vez que um cookie é
                        oferecido, permitindo que você aceite ou recuse cookies
                        individualmente.
                      </span>
                    </p>
                    <li>
                      <strong>
                        <span>Análise do site</span>
                      </strong>
                    </li>
                    <p>
                      <span>
                        Também podemos fazer parceria com fornecedores
                        terceirizados selecionados, como o Google Analytics,
                        para permitir tecnologias de rastreamento e serviços de
                        remarketing no Site por meio do uso de cookies primários
                        e cookies de terceiros, para, entre outras coisas,
                        analisar e rastrear usuários, uso do Site, determinar a
                        popularidade de determinado conteúdo e compreender
                        melhor a atividade online. Ao acessar o Site, você
                        concorda com a coleta e uso de suas informações por
                        esses fornecedores terceirizados. Recomendamos que você
                        analise sua política de privacidade e entre em contato
                        diretamente com eles para obter respostas às suas
                        perguntas. Não transferimos informações pessoais para
                        esses fornecedores terceirizados. No entanto, se você
                        não quiser que nenhuma informação seja coletada e usada
                        por tecnologias de rastreamento, pode visitar o
                        fornecedor terceirizado.
                      </span>
                    </p>
                    <p>
                      <span>
                        Você deve estar ciente de que obter um novo computador,
                        instalar um novo navegador, atualizar um navegador
                        existente ou apagar ou alterar os arquivos de cookies do
                        seu navegador também pode limpar certos cookies de
                        cancelamento, plug-ins ou configurações.
                      </span>
                    </p>
                  </ol>
                  <ol style={{ listStyleType: "none", textAlign: "left" }}>
                    <li>
                      <strong>
                        <span>5. Sites de terceiros</span>
                      </strong>
                    </li>
                  </ol>
                  <p style={{ textAlign: "justify", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13px", color: "#415071" }}>
                      O Site pode conter links para sites de terceiros e
                      aplicativos de interesse, incluindo anúncios e serviços
                      externos, que não são afiliados a nós. Depois de usar
                      esses links para sair do Site, qualquer informação que
                      você fornecer a esses terceiros não será coberta por esta
                      Política de Privacidade e não podemos garantir a segurança
                      e privacidade de suas informações. Antes de visitar e
                      fornecer qualquer informação a quaisquer sites de
                      terceiros, você deve se informar sobre as políticas e
                      práticas de privacidade (se houver) do terceiro
                      responsável por esse site e deve tomar as medidas
                      necessárias para, a seu critério, proteger a privacidade
                      de suas informações. Não somos responsáveis pelo conteúdo
                      ou privacidade e práticas e políticas de segurança de
                      terceiros, incluindo outros sites, serviços ou aplicativos
                      que podem estar vinculados ao Site ou a partir dele.
                    </span>
                  </p>
                  <ol style={{ listStyleType: "none", textAlign: "left" }}>
                    <li>
                      <strong>
                        <span>6. Segurança de suas informações</span>
                      </strong>
                    </li>
                  </ol>
                  <p style={{ textAlign: "justify", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13px", color: "#415071" }}>
                      Usamos medidas de segurança administrativa, técnica e
                      física para ajudar a proteger suas informações pessoais. O
                      acesso aos dados se dá por profissionais autorizados,
                      respeitando os pilares de confidencialidade e privacidade
                      que regem esta política.
                    </span>
                  </p>
                  <p style={{ textAlign: "justify", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13px", color: "#415071" }}>
                      Embora tenhamos tomado medidas razoáveis para proteger as
                      informações pessoais que você nos fornece, esteja ciente
                      de que, apesar de nossos esforços, nenhuma medida de
                      segurança é perfeita ou impenetrável e nenhum método de
                      transmissão de dados pode ser garantido contra qualquer
                      interceptação ou outro tipo de uso indevido. Qualquer
                      informação divulgada online é vulnerável à interceptação e
                      uso indevido por terceiros não autorizados. Portanto, não
                      podemos garantir segurança total se você fornecer
                      informações pessoais.
                    </span>
                  </p>
                  <ol style={{ listStyleType: "none", textAlign: "left" }}>
                    <li>
                      <strong>
                        <span>7. Política para menores de idade</span>
                      </strong>
                    </li>
                  </ol>
                  <p style={{ textAlign: "justify", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13px", color: "#415071" }}>
                      Não solicitamos informações ou comercializamos
                      intencionalmente para pessoas menores de 18 anos. Se você
                      souber de quaisquer dados que coletamos de pessoas menores
                      de 18 anos, entre em contato conosco usando as informações
                      de contato fornecidas abaixo.
                    </span>
                  </p>
                  <ol style={{ listStyleType: "none", textAlign: "left" }}>
                    <li>
                      <strong>
                        <span>8. Controles para recursos do-not-track</span>
                      </strong>
                    </li>
                  </ol>
                  <p style={{ textAlign: "justify", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13px", color: "#415071" }}>
                      A maioria dos navegadores da web e alguns sistemas
                      operacionais móveis incluem um recurso Do-Not-Track
                      (“DNT”) ou configuração que você pode ativar para
                      sinalizar sua preferência de privacidade de não ter dados
                      sobre suas atividades de navegação online monitorados e
                      coletados. Nenhum padrão de tecnologia uniforme para
                      reconhecimento e implementação de sinais DNT foi
                      finalizado. Como tal, atualmente não respondemos aos
                      sinais do navegador DNT ou qualquer outro mecanismo que
                      comunique automaticamente sua escolha de não ser rastreado
                      online. Se for adotado um padrão para rastreamento online
                      que devemos seguir no futuro, iremos informá-lo sobre essa
                      prática em uma versão revisada desta Política de
                      Privacidade.
                    </span>
                  </p>
                  <ol style={{ listStyleType: "none", textAlign: "left" }}>
                    <li>
                      <strong>
                        <span>9. Opções relativas às suas informações</span>
                      </strong>
                    </li>
                  </ol>
                  <p style={{ textAlign: "left", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13px", color: "#415071" }}>
                      Você pode, a qualquer momento, revisar ou alterar as
                      informações em sua conta ou encerrar sua conta usando as
                      informações de contato fornecidas.
                    </span>
                  </p>
                  <p style={{ textAlign: "left", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13px", color: "#415071" }}>
                      Havendo pedido para encerrar sua conta, desativaremos ou
                      excluiremos sua conta e informações de nossos bancos de
                      dados ativos. No entanto, algumas informações podem ser
                      retidas em nossos arquivos para evitar fraudes, solucionar
                      problemas, auxiliar em quaisquer investigações, fazer
                      cumprir nossos Termos de Uso e/ou cumprir requisitos
                      legais.
                    </span>
                  </p>
                  <ol style={{ listStyleType: "none", textAlign: "left" }}>
                    <li>
                      <strong>
                        <span>10. Emails e comunicações</span>
                      </strong>
                    </li>
                  </ol>
                  <p style={{ textAlign: "justify", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13px", color: "#415071" }}>
                      Se você não deseja mais receber correspondência, e-mails
                      ou outras comunicações nossas, você pode cancelar o
                      recebimento entrando em contato conosco usando as
                      informações de contato fornecidas.
                    </span>
                  </p>
                  <p style={{ textAlign: "justify", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13px", color: "#415071" }}>
                      Se você não deseja mais receber correspondência, e-mails
                      ou outras comunicações de terceiros, é responsável por
                      contatá-los diretamente.
                    </span>
                  </p>
                  <ol style={{ listStyleType: "none", textAlign: "left" }}>
                    <li>
                      <strong>
                        <span>11. Controle dos dados</span>
                      </strong>
                    </li>
                  </ol>
                  <p style={{ textAlign: "justify", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13px", color: "#415071" }}>
                      Segundo a Lei Geral de Proteção de Dados (Lei nº
                      13.709/18), “controlador” é aquele a quem compete as
                      decisões sobre o tratamento de dados pessoais – nesse
                      caso, a EyeFlow. Abaixo estão os nossos dados:
                    </span>
                  </p>
                  <p style={{ textAlign: "justify", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13px", color: "#415071" }}>
                      SILICON LIFE PARTICIPACOES LTDA.
                    </span>
                  </p>
                  <p style={{ textAlign: "justify", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13px", color: "#415071" }}>
                      Avenida Paulista, nº 2202, Conj. 145, São Paulo/SP, CEP:
                      01310-932, Brasil.
                    </span>
                  </p>
                  <p style={{ textAlign: "justify", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13px", color: "#415071" }}>
                      CNPJ: 36.418.023/0001-68
                    </span>
                  </p>
                  <p style={{ textAlign: "justify", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13px", color: "#415071" }}>
                      Abaixo constam os dados do encarregado pelos dados:
                    </span>
                  </p>
                  <p style={{ textAlign: "justify", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13px", color: "#415071" }}>
                      Nome: Roberto Jones Neto
                    </span>
                  </p>
                  <p style={{ textAlign: "justify", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13px", color: "#415071" }}>
                      E-mail: roberto.jones@eyeflow.ai
                    </span>
                  </p>
                  <ol style={{ listStyleType: "none", textAlign: "left" }}>
                    <li>
                      <strong>
                        <span>
                          12. Direitos do usuário sobre os dados fornecidos
                        </span>
                      </strong>
                    </li>
                  </ol>
                  <p style={{ textAlign: "justify", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13px", color: "#415071" }}>
                      Os direitos dos usuários acerca dos dados disponibilizados
                      se encontram no art. 18 da Lei Geral de Proteção de Dados
                      Pessoais (Lei nº 13.709/18):
                    </span>
                  </p>
                  <ol
                    style={{
                      listStyleType: "upper-roman",
                      textAlign: "left",
                      fontSize: "13px",
                    }}
                  >
                    <li style={{ listStyleType: "none" }}>
                      <span>
                        Art. 18. O titular dos dados pessoais tem direito a
                        obter do controlador, em relação aos dados do titular
                        por ele tratados, a qualquer momento e mediante
                        requisição:
                      </span>
                    </li>
                    <li>
                      <span>Confirmação da existência de tratamento;</span>
                    </li>
                    <li>
                      <span>Acesso aos dados;</span>
                    </li>
                    <li>
                      <span>
                        Correção de dados incompletos, inexatos ou
                        desatualizados;
                      </span>
                    </li>
                    <li>
                      <span>
                        Anonimização, bloqueio ou eliminação de dados
                        desnecessários, excessivos ou tratados em
                        desconformidade com o disposto nesta Lei;{" "}
                      </span>
                    </li>
                    <li>
                      <span>
                        Portabilidade dos dados a outro fornecedor de serviço ou
                        produto, mediante requisição expressa e observados os
                        segredos comercial e industrial, de acordo com a
                        regulamentação do órgão controlador;
                      </span>
                    </li>
                    <li>
                      <span>
                        Eliminação dos dados pessoais tratados com o
                        consentimento do titular, exceto nas hipóteses previstas
                        no art. 16 desta Lei;
                      </span>
                    </li>
                    <li>
                      <span>
                        Informação das entidades públicas e privadas com as
                        quais o controlador realizou uso compartilhado de dados;
                      </span>
                    </li>
                    <li>
                      <span>
                        Informação sobre a possibilidade de não fornecer
                        consentimento e sobre as consequências da negativa;
                      </span>
                    </li>
                    <li>
                      <span>
                        Revogação do consentimento, nos termos do § 5º do art.
                        8º desta Lei.
                      </span>
                    </li>
                  </ol>
                  <ol
                    style={{
                      listStyleType: "none",
                      textAlign: "left",
                      fontSize: "13px",
                    }}
                  >
                    <li>
                      <span>
                        § 1º O titular dos dados pessoais tem o direito de
                        peticionar em relação aos seus dados contra o
                        controlador perante a autoridade nacional.
                      </span>
                    </li>
                    <li>
                      <span>
                        § 2º O titular pode opor-se a tratamento realizado com
                        fundamento em uma das hipóteses de dispensa de
                        consentimento, em caso de descumprimento ao disposto
                        nesta Lei.
                      </span>
                    </li>
                    <li>
                      <span>
                        § 3º Os direitos previstos neste artigo serão exercidos
                        mediante requerimento expresso do titular ou de
                        representante legalmente constituído, a agente de
                        tratamento.
                      </span>
                    </li>
                    <li>
                      <span>
                        § 4º Em caso de impossibilidade de adoção imediata da
                        providência de que trata o § 3º deste artigo, o
                        controlador enviará ao titular resposta em que poderá:{" "}
                      </span>
                    </li>
                  </ol>
                  <ol
                    style={{
                      listStyleType: "upper-roman",
                      textAlign: "left",
                      fontSize: "13px",
                    }}
                  >
                    <li>
                      <span>
                        Comunicar que não é agente de tratamento dos dados e
                        indicar, sempre que possível, o agente; ou
                      </span>
                    </li>
                    <li>
                      <span>
                        Indicar as razões de fato ou de direito que impedem a
                        adoção imediata da providência.
                      </span>
                    </li>
                  </ol>
                  <ol
                    style={{
                      listStyleType: "none",
                      textAlign: "left",
                      fontSize: "13px",
                    }}
                  >
                    <li>
                      <span>
                        5º O requerimento referido no § 3º deste artigo será
                        atendido sem custos para o titular, nos prazos e nos
                        termos previstos em regulamento.
                      </span>
                    </li>
                    <li>
                      <span>
                        § 6º O responsável deverá informar, de maneira imediata,
                        aos agentes de tratamento com os quais tenha realizado
                        uso compartilhado de dados a correção, a eliminação, a
                        anonimização ou o bloqueio dos dados, para que repitam
                        idêntico procedimento, exceto nos casos em que esta
                        comunicação seja comprovadamente impossível ou implique
                        esforço desproporcional.{" "}
                      </span>
                    </li>
                    <li>
                      <span>
                        § 7º A portabilidade dos dados pessoais a que se refere
                        o inciso V do caput deste artigo não inclui dados que já
                        tenham sido anonimizados pelo controlador.
                      </span>
                    </li>
                    <li>
                      <span>
                        § 8º O direito a que se refere o § 1º deste artigo
                        também poderá ser exercido perante os organismos de
                        defesa do consumidor.
                      </span>
                    </li>
                  </ol>
                  <ol
                    style={{
                      listStyleType: "none",
                      textAlign: "left",
                      fontSize: "13px",
                    }}
                  >
                    <li>
                      <strong>
                        <span>13. Legislação e Foro &nbsp;</span>
                      </strong>
                    </li>
                  </ol>
                  <p style={{ textAlign: "justify", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13px", color: "#415071" }}>
                      Esta Política de Privacidade e todas as decorrências que
                      dela possam advir, bem como todas as eventuais disputas a
                      elas relacionadas, serão reguladas exclusivamente pela
                      legislação brasileira.
                    </span>
                  </p>
                  <p style={{ textAlign: "justify", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13px", color: "#415071" }}>
                      Fica eleito o Foro Central da Comarca de São Paulo/SP,
                      para resolver qualquer questão relacionada a essas
                      Políticas, renunciando as partes a qualquer outro, por
                      mais privilegiado que seja ou venha a ser.
                    </span>
                  </p>
                  <p style={{ textAlign: "justify", lineHeight: "normal" }}>
                    <strong>
                      <span style={{ fontSize: "13px", color: "#415071" }}>
                        CONTATE-NOS
                      </span>
                    </strong>
                  </p>
                  <p style={{ textAlign: "justify", lineHeight: "normal" }}>
                    <span style={{ fontSize: "13px", color: "#415071" }}>
                      Se você tiver perguntas ou comentários sobre esta Política
                      de Privacidade, entre em contato conosco em:
                    </span>
                  </p>
                  <p style={{ textAlign: "justify" }}>
                    <span
                      style={{
                        fontSize: "13px",
                        lineHeight: "107%",
                        color: "#415071",
                      }}
                    >
                      <a href="mailto:contact@eyeflow.ai">
                        <span style={{ fontSize: "13px" }}>
                          contact@eyeflow.ai
                        </span>
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PageFooter />
    </div>
  );
}
