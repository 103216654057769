import React from "react";
// import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

// nodejs library that concatenates classes
// import classNames from "classnames";

// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import PhoneInput from "react-phone-input-2";
import "assets/css/signUpPhoneInput.css";
import { IntlContext } from "../../intlProviderWrapper";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PasswordValidator from "password-validator";
import CircularProgress from "@material-ui/core/CircularProgress";
// core components
import formStyles from "assets/jss/material-kit-pro-react/components/formStyles.js";

// import logo_eyeflow_mask from "assets/img/logos/logoEyeFlowNameless4.png";

const useStyles = makeStyles(formStyles);

export default function FormFields(props, ...rest) {
  const classes = useStyles();
  const intl = useIntl();
  const {
    disabledButton,
    fields,
    names,
    handleInputChange,
    handleFormSubmit,
    handlePhoneChange,
    termsCheckbox,
    handleCheck,
    sentEmail,
    emailResponse,
    formId,
    checked,
    buttonText,
  } = props;
  const [fieldsInfo, setFieldsInfo] = React.useState([]);
  const [celOption, setCelOption] = React.useState("celular");
  // eslint-disable-next-line
  const [showPassword, setShowPassword] = React.useState(false);

  const { locale } = React.useContext(IntlContext);
  function getFieldsInfo(e) {
    // setNumberOfFields(numberOfFields.slice(numberOfFields))
    setFieldsInfo(
      fields.map((x, i) => [x, names === undefined ? "" : names[i]]),
    );
    // eslint-disable-next-line
    fields.map((value) => {
      let textValue = value.label.props
        ? value.label.props.defaultMessage
        : value.label;
      if (textValue.toLowerCase() === "phone") {
        setCelOption(textValue);
      } else if (textValue.toLowerCase() === "celular") {
        setCelOption(textValue);
      } else if (textValue.toLowerCase() === "célula") {
        setCelOption(textValue);
      }
    });
  }

  function handleClickButton(e) {
    let inputs = document.querySelectorAll("input");
    if (sentEmail) {
      inputs.forEach((input) => {
        setTimeout(() => {
          inputs.forEach((input) => (input.value = ""));
        }, 800);
      });
    }
  }
  const handleShowPassword = (e) => {
    setShowPassword(!showPassword);
  };

  var schema = new PasswordValidator();
  schema
    .is()
    .min(8) // Minimum length 8
    .is()
    .max(50) // Maximum length 50
    .has()
    .not()
    .spaces(); // Should not have spaces

  React.useEffect(() => {
    getFieldsInfo();
    // eslint-disable-next-line
  }, []);

  return (
    <form id={formId} onSubmit={handleFormSubmit} className={classes.form}>
      {fieldsInfo.map((el) => {
        return el[0].label.props.defaultMessage === celOption ? (
          <PhoneInput
            id="phone"
            name="phone"
            key="phone"
            style={el[0].style ? el[0].style : null}
            onChange={handlePhoneChange}
            specialLabel={
              <span style={{ fontFamily: "'Exo 2'" }}>
                <FormattedMessage id="signup.field5" defaultMessage="Celular" />
              </span>
            }
            preferredCountries={[
              locale.substring(3, 5).toLowerCase(),
              "us",
              "es",
              "br",
            ]}
            country={locale.substring(3, 5).toLowerCase()}
            enableSearch
            inputStyle={{
              border: "none",
              borderBottom: "ridge",
              borderBottomRightRadius: "unset",
              borderBottomLeftRadius: "unset",
              width: "-webkit-fill-available",
            }}
            inputProps={{
              // required: true,
              id: "phone",
              name: "phone",
            }}
          />
        ) : el[0].label.props.defaultMessage.toLowerCase() === "password" ||
          el[0].label.props.defaultMessage.toLowerCase() === "senha" ? (
          <TextField
            key={
              el[0].label.props.defaultMessage.toLowerCase() ||
              el[0].label.props.defaultMessage.toLowerCase()
            }
            error={el[0].error}
            helperText={
              <span style={{ fontFamily: "'Exo 2'" }}>{el[0].helperText}</span>
            }
            style={el[0].style ? el[0].style : null}
            className={el[0].className ? el[0].className : classes.textStyle}
            disabled={
              el[0].disabled ? (el[0].disabled === true ? true : false) : false
            }
            margin="normal"
            // required
            fullWidth={
              el[0].isFull ? (el[0].isFull === true ? true : false) : false
            }
            name="password"
            label={
              <FormattedMessage id="signup.pass1" defaultMessage="Password" />
            }
            type={showPassword ? "text" : "password"}
            id="password"
            onChange={handleInputChange}
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowPassword}
                    onMouseDown={handleShowPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <TextField
            error={el[0].error}
            autoFocus
            helperText={
              <span style={{ fontFamily: "'Exo 2'" }}>{el[0].helperText}</span>
            }
            disabled={
              el[0].disabled ? (el[0].disabled === true ? true : false) : false
            }
            value={el[0].value}
            style={
              el[0].style
                ? el[0].style
                : {
                    pointerEvents: el[0].disabled
                      ? el[0].disabled === true
                        ? "none"
                        : "all"
                      : "all",
                  }
            }
            className={el[0].className ? el[0].className : classes.textStyle}
            required={el[0].required ? el[0].required : false}
            fullWidth={
              el[0].isFull ? (el[0].isFull === true ? true : false) : false
            }
            key={
              el[1]
                ? el[1].toLowerCase().replace(/[/-\s]/g, "_")
                : el[0].label.props.defaultMessage
                    .toLowerCase()
                    .replace(/[/-\s]/g, "_")
            }
            select={el[0].options ? true : false}
            id={
              el[1]
                ? el[1].toLowerCase().replace(/[/-\s]/g, "_")
                : el[0].label.props.defaultMessage
                    .toLowerCase()
                    .replace(/[/-\s]/g, "_")
            }
            name={
              el[1]
                ? el[1].toLowerCase().replace(/[/-\s]/g, "_")
                : el[0].label.props.defaultMessage
                    .toLowerCase()
                    .replace(/[/-\s]/g, "_")
            }
            onChange={handleInputChange}
            label={<span style={{ fontFamily: "'Exo 2'" }}>{el[0].label}</span>}
            inputProps={{ maxLength: el[0].maxLength ? el[0].maxLength : "" }}
            InputProps={{
              endAdornment:
                el[0].label.props.defaultMessage.toLowerCase() ===
                "username" ? (
                  <InputAdornment position="end">
                    <div className={classes.wrapper}>
                      {el[0].icon ? el[0].icon : ""}
                      {el[0].loading && (
                        <CircularProgress
                          size={32}
                          value={el[0].time}
                          className={classes.fabProgress}
                        />
                      )}
                    </div>
                  </InputAdornment>
                ) : (
                  <InputAdornment position="end">
                    {el[0].icon ? el[0].icon : ""}
                  </InputAdornment>
                ),
            }}
          >
            {el[0].options
              ? Array.isArray(el[0].options)
                ? el[0].options.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))
                : Object.keys(el[0].options).map((loc) => (
                    <MenuItem
                      key={el[0].options[loc].locale + "key"}
                      id={el[0].options[loc].locale + "-key"}
                      style={{ fontFamily: "'Exo 2'" }}
                      value={el[0].options[loc].locale}
                    >
                      {el[0].options[loc].label}
                    </MenuItem>
                  ))
              : ""}
          </TextField>
        );
      })}
      {termsCheckbox ? (
        sentEmail === true ? (
          emailResponse
        ) : (
          <div>
            <FormControlLabel
              style={{ float: "left" }}
              control={
                <Checkbox
                  onChange={handleCheck}
                  checked={checked}
                  name="checkedTerms"
                  color="primary"
                  id="checkForm"
                />
              }
              label={
                <span className={classes.labelCheckBox}>
                  <FormattedMessage
                    id="signup.field8"
                    defaultMessage="Eu concordo com os "
                  />
                  <a
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                    href="/termos"
                  >
                    <FormattedMessage
                      id="signup.field9"
                      defaultMessage="Termos "
                    />
                  </a>
                  <FormattedMessage id="signup.field10" defaultMessage="e " />
                  <a
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                    href="/politica-privacidade"
                  >
                    <FormattedMessage
                      id="signup.field11"
                      defaultMessage="Políticas de Privacidade"
                    />
                  </a>
                  .
                </span>
              }
            />
            <br />
            <br />
            <div>
              <input
                className={classes.buttonStyle}
                disabled={disabledButton ? true : false}
                type="submit"
                id="submitButton"
                value={
                  buttonText
                    ? buttonText
                    : intl.formatMessage({
                        id: "signup.field7",
                        defaultMessage: "Olá, ",
                      })
                }
                onClick={handleClickButton}
                style={{
                  backgroundColor: disabledButton ? "#cccccc" : "#3f51b5",
                  pointerEvents: disabledButton === true ? "none" : "",
                }}
              />
            </div>
          </div>
        )
      ) : sentEmail === true ? (
        emailResponse
      ) : (
        <div>
          <input
            className={classes.buttonStyle}
            disabled={disabledButton ? true : false}
            type="submit"
            id="submitButton"
            value={
              buttonText
                ? buttonText
                : intl.formatMessage({
                    id: "signup.field7",
                    defaultMessage: "Olá, ",
                  })
            }
            onClick={handleClickButton}
            style={{
              backgroundColor: disabledButton ? "#cccccc" : "#3f51b5",
              pointerEvents: disabledButton === true ? "none" : "",
            }}
          />
        </div>
      )}
    </form>
  );
}

FormFields.defaultProps = {
  disabledButton: false,
  sentEmail: false,
};

FormFields.propTypes = {
  fields: PropTypes.array.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  formId: PropTypes.any.isRequired,
  termsCheckbox: PropTypes.bool,
  phoneInput: PropTypes.bool,
  disabledButton: PropTypes.bool,
  names: PropTypes.array,
  handlePhoneChange: PropTypes.func,
  handleCheck: PropTypes.any,
  checked: PropTypes.any,
  emailResponse: PropTypes.any,
  sentEmail: PropTypes.any,
};
