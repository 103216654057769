import React from "react";

import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { IntlContext } from "../../../intlProviderWrapper";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

// import { postList_en, postList_pt } from "/blogfiles/postList.js";

import sectionInterestedStyle from "assets/jss/material-kit-pro-react/Pages/blogPostsSections/sectionInterestedStyle.js";
const useStyles = makeStyles(sectionInterestedStyle);

const PillCard = (props) => {
  const classes = useStyles();
  return (
    <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
      <Card plain blog>
        <CardHeader image plain>
          <a href={"/blogpost?date=" + props.blogData.date}>
            <img src={props.blogData.bgImg} alt="..." />
          </a>
        </CardHeader>
        <CardBody plain>
          <h4 className={classes.cardTitle}>
            <a
              style={{ fontFamily: "'Exo 2'" }}
              href={"/blogpost?date=" + props.blogData.date}
            >
              {props.blogData.title}
            </a>
          </h4>
          <p className={classes.description}>
            {props.blogData.description}
            <br />
            <a href={"/blogpost?date=" + props.blogData.date}>
              <FormattedMessage
                id="blog.read_more"
                defaultMessage="Leia mais"
              />
            </a>
          </p>
        </CardBody>
      </Card>
    </GridItem>
  );
};

PillCard.propTypes = {
  blogData: PropTypes.object,
};

export default function SectionPills() {
  const [postList, setPostList] = React.useState([]);
  const [postsListAll, setPostsListAll] = React.useState();
  const { locale } = React.useContext(IntlContext);

  React.useEffect(() => {
    if (!postsListAll) {
      fetch(process.env.PUBLIC_URL + "/blogfiles/postList.json", {
        "Content-Type": "application/json",
      })
        .then(function (response) {
          if (response.status !== 200) {
            alert("Fail getting posts list");
          }
          return response.json();
        })
        .then(function (post_json) {
          setPostsListAll(post_json);
        });
    }
  }, [postsListAll]);

  React.useEffect(() => {
    if (postsListAll) {
      if (locale === "en-US") {
        setPostList(postsListAll.postList_en);
      } else if (locale === "es-ES") {
        setPostList(postsListAll.postList_es);
      } else {
        setPostList(postsListAll.postList_pt);
      }
    }
  }, [locale, postsListAll]);

  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer>
        {postList.map((el, index) => {
          return <PillCard key={index} blogData={el} />;
        })}
      </GridContainer>
    </div>
  );
}
