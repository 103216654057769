import {
  title,
  cardTitle,
  coloredShadow,
  grayColor
} from "assets/jss/material-kit-pro-react.js";

const sectionInterestedStyle = {
  title,
  cardTitle:{
    ...cardTitle,
    fontFamily: "'Exo 2'",
  },
  coloredShadow,
  textCenter: {
    textAlign: "center"
  },
  section: {
    backgroundPosition: "50%",
    backgroundSize: "cover",
    padding: "70px 0"
  },
  description: {
    color: grayColor[0],
    fontFamily: "'Exo 2'",
    fontWeight: "bolder"
  }
};

export default sectionInterestedStyle;
