import React from "react";
// import { FormattedMessage } from "react-intl";
// Style
import { makeStyles } from "@material-ui/core/styles";
import newHeaderStyle from "assets/jss/material-kit-pro-react/components/inspectionHeader.js";
// Images
import logo from "assets/img/eyeflow_inspection_logo_R_no_eye.png";

const useStyles = makeStyles(newHeaderStyle);

export default function NewHeader() {
  const classes = useStyles();
  return (
    <header className={classes.header}>
      <nav
        id="navbar-principal"
        className="navbar navbar-expand-lg shadow-lg bg-light"
      >
        <div className="container">
          <a className="navbar-brand" href="https://inspection.eyeflow.ai">
            <img alt="Logo 'EyeFlow Inspection'" src={logo} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <a
                  className={`${classes.navLink} nav-link`}
                  href="https://inspection.eyeflow.ai/#industries"
                >
                  {/* <FormattedMessage
                    id="header.nav1"
                    defaultMessage="Industries"
                  /> */}
                  Industries
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`${classes.navLink} nav-link`}
                  href="https://inspection.eyeflow.ai/#robust"
                >
                  {/* <FormattedMessage
                    id="header.nav2"
                    defaultMessage="Platform"
                  /> */}
                  Platform
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`${classes.navLink} nav-link`}
                  href="https://inspection.eyeflow.ai/#advantages"
                >
                  {/* <FormattedMessage
                    id="header.nav3"
                    defaultMessage="Advantages"
                  /> */}
                  Advantages
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`${classes.navLink} nav-link`}
                  href="https://inspection.eyeflow.ai/#clients"
                >
                  {/* <FormattedMessage id="header.nav4" defaultMessage="Clients" /> */}
                  Clients
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`${classes.navLink} nav-link`}
                  href="https://inspection.eyeflow.ai/#partners"
                >
                  {/* <FormattedMessage
                    id="header.nav5"
                    defaultMessage="Partners"
                  /> */}
                  Partners
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`${classes.navLink} nav-link`}
                  href="/partners-inspection"
                >
                  {/* <Format tedMessage id="header.nav6" defaultMessage="Contact" /> */}
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}
