import {
  title,
  grayColor,
  blackColor,
  hexToRgb,
} from "assets/jss/material-kit-pro-react.js";

import { palleteColor2 } from "assets/jss/land_cores.js";

const treinamentoStyle = {
  section: {
    padding: "150px 0 0 0",
    display: "flex",
    flexDirection: "row",
    // justifyContent: "space-between",
    textAlign: "left",
    // width: "max",
  },
  sectionText: {
    "@media (max-width: 600px)": {
      marginLeft: "1em",
    },
    marginLeft: "10em",
    // width: "24%",
  },
  sectionVideo: {
    // padding: "20px 0",
    // display: "flex",
    // flexDirection: "row",
    // justifyContent: "space-between",
    textAlign: "left",
    // width: "max",
    marginTop: "-220.5px",
  },
  sectionTextVideo: {
    // marginLeft: "1.5em",
    // width: "100%",
    marginTop: "-5em",
    marginLeft: "2rem",
    "@media (max-width: 600px)": {
      marginLeft: 0,
      marginTop: 0,
      textAlign: "center",
      width: "-webkit-fill-available",
    },
  },
  titleVideo: {
    ...title,
    fontFamily: "'Exo 2'",
    // fontSize: "60px",
    fontSize: "clamp(1em, 3em + 1vw, 5em)",
    // display: "inline-block",
    // minHeight: "32px",
    // lineHeight: "10vh",
    // textDecoration: "none",
    // position: "relative",
    // marginBottom: 0,
    // marginTop: 0,
  },
  sectionCardsVideo: {
    // padding: "20px 0",
    textAlign: "left",
    // width: "max",
  },
  iframeContainerVideo: {
    "& > iframe": {
      width: "100%",
      borderRadius: "20px",
      boxShadow:
        "0 16px 38px -12px rgba(" +
        hexToRgb(blackColor) +
        ", 0.56), 0 4px 25px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)",
    },
  },
  cardTitleVideo: {
    fontFamily: "'Exo 2'",
    fontSize: "16px",
    // display: "inline-block",
    // position: "relative",
    fontWeight: "700",
    // marginTop: "0px",
    // textDecoration: "none",
    color: grayColor[1],
  },
  cardDescriptionVideo: {
    fontFamily: "'Exo 2'",
    fontSize: "16px",
    fontWeight: "200",
    // display: "inline-block",
    // position: "relative",
    // textDecoration: "none",
    color: grayColor[1],
  },
  title: {
    ...title,
    "@media (max-width: 600px)": {
      fontSize: "8vw",
      width: "130%",
    },
    fontFamily: "'Exo 2'",
    fontSize: "clamp(1em, 3em + 1vw, 5em)",
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    width: "7em",
    // marginLeft: "2vw",
    color: palleteColor2,
    textDecoration: "none",
  },
  description: {
    ...title,
    "@media (max-width: 600px)": {
      width: "28rem",
      // paddingRight: "1em",
    },
    "@media (max-width: 430px)": {
      width: "22rem",
      // paddingRight: "1em",
    },
    "@media (max-width: 326px)": {
      width: "18rem",
      // paddingRight: "1em",
    },

    fontFamily: "'Exo 2'",
    fontSize: "clamp(1em, 2em + 1vw, 5em)",
    display: "inline-block",
    position: "relative",
    marginTop: "20px",
    minHeight: "32px",
    // marginLeft: "2vw",
    width: "12em",
    textDecoration: "none",
    color: grayColor[1],
    fontWeight: 100,
  },
  svgImage: {
    "@media (max-width: 600px)": {
      display: "none",
    },
    width: "1150px",
    marginTop: "10vh",
    marginLeft: "-2em",
  },
  sectionImage: {
    height: "900px",
    width: "670px",
    marginBottom: "0px",
    marginTop: "400px",
    marginLeft: "-420px",
    overflow: "hidden",
  },
  imageFluid: {
    "@media (max-width: 600px)": {
      display: "none",
    },
    height: "1100px",
    margin: "10vh  -1500px 0px 0px",
    WebkitTransform: "rotate(-90deg)",
    MozTransform: "rotate(-90deg)",
    MsTransform: "rotate(-90deg)",
    OTransform: "rotate(-90deg)",
    transform: "rotate(-90deg)",
  },
  sectionFlow: {
    padding: "20px 0",
    textAlign: "left",
    width: "max",
  },
  flowHeader: {
    fontFamily: "'Exo 2'",
    fontSize: "20px",
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: grayColor[1],
    textDecoration: "none",
  },
  sectionCards: {
    "@media (max-width: 600px)": {
      padding: "0px 0px 0px 0px",
      textAlign: "left",
      width: "90vw",
      marginBottom: "6em",
      display: "block",
    },
    display: "none",
  },
  cardBody: {
    padding: "20px 20px",
    textAlign: "left",
    width: "100%",
    // marginBottom:"10em"
  },
  cardTitle: {
    fontFamily: "'Exo 2'",
    fontSize: "16px",
    display: "inline-block",
    position: "relative",
    fontWeight: "500",
    // marginTop: "30px",
    // minHeight: "32px",
    // marginLeft: "2vw",
    textDecoration: "none",
    color: grayColor[1],
  },
  cardDescription: {
    "@media (max-width: 600px)": {
      fontSize: "4vw",
    },
    fontFamily: "'Exo 2'",
    fontSize: "16px",
    display: "inline-block",
    position: "relative",
    // marginTop: "30px",
    // minHeight: "32px",
    // marginLeft: "2vw",
    textDecoration: "none",
    color: grayColor[1],
  },
  imgIcon: {
    height: "60px",
  },
};

export default treinamentoStyle;
