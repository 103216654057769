import React from "react";
import { FormattedMessage } from "react-intl";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";

import solutionStyle from "assets/jss/material-kit-pro-react/Pages/landingPageSections/solutionStyle.js";

import fluid_blue_img from "assets/img/fluid-blue.png";

import SolutionSvgPT from "assets/img/logos/SolutionSvg.svg";
import SolutionSvgEng from "assets/img/logos/SolutionSvgEng.svg";
import { IntlContext } from "../../../intlProviderWrapper";

import { Parallax, Background } from "react-parallax";

import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// import TagManager from "react-gtm-module";

const style = {
  ...solutionStyle,
  justifyContentCenter: {
    justifyContent: "center",
  },
};

const useStyles = makeStyles(style);

export default function SectionSolution() {
  const classes = useStyles();
  const { locale } = React.useContext(IntlContext);

  const [xSvg1, setXSvg1] = React.useState("");
  const [xSvg2, setXSvg2] = React.useState("");
  const [xSvg3, setXSvg3] = React.useState("");
  const [xSvg4, setXSvg4] = React.useState("");

  React.useEffect(() => {
    if (locale === "en-US") {
      setXSvg1("30");
      setXSvg2("65");
      setXSvg3("30");
      setXSvg4("50");
    } else if (locale === "es-ES") {
      setXSvg1("15");
      setXSvg2("40");
      setXSvg3("25");
      setXSvg4("30");
    } else {
      setXSvg1("23");
      setXSvg2("20");
      setXSvg3("75");
      setXSvg4("35");
    }
  }, [locale]);

  const etapas = [
    {
      image: (
        <svg
          width="280"
          height="29"
          viewBox="0 0 230 29"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.7479 3.80779C18.1617 1.33273 22.2705 0 26.4872 0H163.513C167.729 0 171.838 1.33274 175.252 3.80779L190 14.5L175.252 25.1922C171.838 27.6673 167.729 29 163.513 29H26.4872C22.2705 29 18.1617 27.6673 14.7479 25.1922L0 14.5L14.7479 3.80779Z"
            fill="#5BC4F1"
          />
          <text x={xSvg1} y="19" fill="white">
            <FormattedMessage
              id="land.solution.step1_title"
              defaultMessage="Uso no seu browser"
            />
          </text>
        </svg>
      ),
      description: (
        <FormattedMessage
          id="land.solution.step1_description"
          defaultMessage="Plataforma totalmente na nuvem com resultados em tempo real, sem necessidade de equipamentos ou programas adicionais."
        />
      ),
      backgroundColor: "rgba(91, 196, 241, 1)",
    },
    {
      image: (
        <svg
          style={{ marginLeft: "-13px" }}
          width="277"
          height="29"
          viewBox="0 0 277 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.2054 3.06412C21.3926 1.06207 25.08 0 28.8438 0H248.156C251.92 0 255.607 1.06207 258.795 3.06412L277 14.5L258.795 25.9359C255.607 27.9379 251.92 29 248.156 29H28.8438C25.08 29 21.3926 27.9379 18.2054 25.9359L0 14.5L18.2054 3.06412Z"
            fill="#10ABEE"
          />
          <text x={xSvg2} y="19" fill="white">
            <FormattedMessage
              id="land.solution.step2_title"
              defaultMessage="Execute no seu dispositivo"
            />
          </text>
        </svg>
      ),
      description: (
        <FormattedMessage
          id="land.solution.step2_description"
          defaultMessage="Os modelos treinados são executados no seu próprio dispositivo, sem restrições ou requerimentos de equipamentos especializados."
        />
      ),
      backgroundColor: "rgba(16, 171, 238, 1)",
    },
    {
      image: (
        <svg
          style={{ marginLeft: "-15px" }}
          width="278"
          height="29"
          viewBox="0 0 278 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.7479 3.80779C18.1617 1.33273 22.2705 0 26.4872 0H251.513C255.729 0 259.838 1.33274 263.252 3.80779L278 14.5L263.252 25.1922C259.838 27.6673 255.729 29 251.513 29H26.4872C22.2705 29 18.1617 27.6673 14.7479 25.1922L0 14.5L14.7479 3.80779Z"
            fill="#0085FF"
          />
          <text x={xSvg3} y="19" fill="white">
            <FormattedMessage
              id="land.solution.step3_title"
              defaultMessage="Plataforma aberta e expansível"
            />
          </text>
        </svg>
      ),
      description: (
        <FormattedMessage
          id="land.solution.step3_description"
          defaultMessage="Você pode utilizar os objetos prontos para a Detecção, Classificação, OCR, Contagem e diversas ações out-of-the-box ou criar os seus."
        />
      ),
      backgroundColor: "rgba(0, 133, 255, 1)",
    },
    {
      image: (
        <svg
          width="275"
          height="29"
          viewBox="0 0 275 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.7479 3.80779C18.1617 1.33273 22.2705 0 26.4872 0H213.513C217.729 0 221.838 1.33274 225.252 3.80779L240 14.5L225.252 25.1922C221.838 27.6673 217.729 29 213.513 29H26.4872C22.2705 29 18.1617 27.6673 14.7479 25.1922L0 14.5L14.7479 3.80779Z"
            fill="#0057A7"
          />
          <text x={xSvg4} y="19" fill="white">
            <FormattedMessage
              id="land.solution.step4_title"
              defaultMessage="Processos personalizados"
            />
          </text>
        </svg>
      ),
      description: (
        <FormattedMessage
          id="land.solution.step4_description"
          defaultMessage="A criação de um fluxo personalizado de encadeamento de funções permite criar modelos adaptados a qualquer indústria ou modelo de negócios."
        />
      ),
      backgroundColor: "rgba(0, 87, 167, 1)",
    },
  ];

  const renderContainerMobile = (cssClass) => {
    return (
      <GridContainer className={classes.cardContainer}>
        {etapas.map((el, index) => {
          return (
            <GridItem
              md={3}
              sm={3}
              xs={12}
              key={el + "_" + index}
              className={cssClass.sectionCardsMobile}
            >
              <Card
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
                profile
                plain
              >
                <CardHeader
                  style={{ padding: "0px", alignSelf: "center" }}
                  plain
                >
                  <h4 className={cssClass.cardTitle}>{el.image}</h4>
                </CardHeader>
                <CardBody plain>
                  <p className={cssClass.cardDescription}>{el.description}</p>
                </CardBody>
              </Card>
            </GridItem>
          );
        })}
      </GridContainer>
    );
  };

  const renderContainerFluid = (cssClass) => {
    return (
      <GridContainer>
        {locale === "pt-BR" ? (
          <img src={SolutionSvgPT} className={cssClass.sectionSvg} alt="" />
        ) : (
          <img src={SolutionSvgEng} className={cssClass.sectionSvg} alt="" />
        )}
      </GridContainer>
    );
  };

  return (
    // <a href="\" id="SectionSoluções" style={{display:"block", paddingTop:"65px", marginTop:"-65px"}}>
    <div id="section_solution" className={classes.section}>
      <div className={classes.sectionText}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button className={classes.solutionButton}>
            <a
              href="/signup"
              rel="noopener noreferrer"
              style={{ color: "rgb(124, 162, 200)" }}
            >
              <FormattedMessage
                id="land.header13"
                defaultMessage="Crie sua conta"
                values={{ break: <br /> }}
              />
            </a>
          </button>
        </div>
        <br />
        <h1
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          className={classes.title}
        >
          <span style={{ fontWeight: 200 }}>
            <FormattedMessage
              id="land.solution.title"
              defaultMessage="Plataforma "
            />
          </span>
          <span style={{ fontWeight: 700 }}>
            <FormattedMessage
              id="land.solution.title2"
              defaultMessage="EyeFlow"
            />
          </span>
        </h1>
        <h5
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          className={classes.description}
        >
          <FormattedMessage
            id="land.solution.description"
            defaultMessage="EyeFlow é uma plataforma de análise de vídeo e modelos de inteligência artificial que impulsionam as soluções de negócio ao otimizar o desempenho, o custo e a eficiência das organizações. Faça o upload de seus vídeos ou imagens, identifique e marque o que deseja detectar, treine a rede neural e passe a utilizar."
          />
        </h5>
        <div
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          className={classes.sectionFlow}
        >
          <h5 className={classes.flowHeader}>
            <span style={{ fontWeight: 700 }}>
              <FormattedMessage
                id="land.solution.flow_description1"
                defaultMessage="Interface intuitiva"
              />
            </span>
            <span style={{ fontWeight: 400 }}>
              <FormattedMessage
                id="land.solution.flow_description2"
                defaultMessage="e Simples de usar "
              />
            </span>
            <span style={{ fontWeight: 400 }}>
              <FormattedMessage
                id="land.solution.flow_description3"
                defaultMessage="não precisa de conhecimento em programação ou Inteligência Artificial"
              />
            </span>
          </h5>
        </div>
        <div
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          className={classes.sectionCards}
        >
          {renderContainerFluid(classes)}
        </div>

        <div
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          className={classes.sectionCardsMobile}
        >
          {renderContainerMobile(classes)}
        </div>
      </div>

      <Parallax style={{ height: 150, overflow: "visible" }} strength={400}>
        <div className={classes.sectionImage}></div>
        <Background>
          <img src={fluid_blue_img} alt="" className={classes.imageFluid} />
        </Background>
      </Parallax>

      {/* <div className={classes.sectionImage}>
        <img src={fluid_blue_img} alt="" className={classes.imageFluid}/>
      </div> */}
    </div>
    // </a>
  );
}
