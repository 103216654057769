import React from "react";
import CookieConsent from "components/CookieConsent/CookieConsent";

import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { IntlProvider } from "react-intl";
import { languages } from "./intl-languages/messages";
// CSS
import "assets/bootstrap5/css/bootstrap.min.css";
import "assets/css/index.css";
import "assets/scss/material-kit-pro-react.scss?v=1.9.0";

import ls from "local-storage";
// pages/components for this product
import AboutUsPage from "Pages/AboutUsPage/AboutUsPage";
import BlogPostsPage from "Pages/BlogPostsPage/BlogPostsPage";
import BlogPostPage from "Pages/BlogPostPage/BlogPostPage";
import ComponentsPage from "Pages/ComponentsPage/ComponentsPage";
import ContactUsPage from "Pages/ContactUsPage/ContactUsPage";
import LandingPage from "Pages/LandingPage/LandingPage";
import LoginPage from "Pages/LoginPage/LoginPage";
import SectionsPage from "Pages/SectionsPage/SectionsPage";
import SignupPage from "Pages/SignupPage/SignupPage";
import TermsPage from "Pages/TermsPage/TermsPage";
import PoliticaPage from "Pages/PoliticaPage/PoliticaPage";
import PartnersPage from "Pages/PartnersPage/PartnersPage";
import InspectionPartnersPage from "Pages/PartnersPage/InspectionPartnersPage";
// ----------------------------------------------------------------------------------------------------------

const Context = React.createContext();

var hist = createBrowserHistory();

function IntlProviderWrapper() {
  function getBrowserDefault(browserLang) {
    if (browserLang.substring(0, 2) === "pt") {
      return "pt-BR";
    } else if (browserLang.substring(0, 2) === "en") {
      return "en-US";
    } else if (browserLang.substring(0, 2) === "es") {
      return "es-ES";
    } else {
      return "en-US";
    }
  }

  const [locale, setLocale] = React.useState(
    ls.get("locale")
      ? ls.get("locale")
      : getBrowserDefault(
          (navigator.languages && navigator.languages[0]) ||
            navigator.language ||
            window.navigator.userLanguage,
        ),
  );

  const [messages, setMessages] = React.useState(languages[locale].messages);

  React.useEffect(() => {
    const lang = ls.get("locale");
    if (lang) {
      setLocale(lang);
    }
  }, []);

  const onChangeLanguage = (lang) => {
    setMessages(languages[lang].messages);
    setLocale(lang);
    ls.set("locale", lang);
  };
  return (
    <Context.Provider value={{ onChangeLanguage, locale, languages }}>
      <IntlProvider locale={locale} messages={messages} defaultLocale="en-US">
        <CookieConsent />
        <Router history={hist}>
          <Switch>
            <Route path="/partners-solution" component={PartnersPage} />
            <Route
              path="/partners-inspection"
              component={InspectionPartnersPage}
            />
            <Route path="/blog" component={BlogPostsPage} />
            <Route path="/blogpost" component={BlogPostPage} />
            <Route path="/termos" component={TermsPage} />
            <Route path="/politica-privacidade" component={PoliticaPage} />
            <Route path="/about-us" component={AboutUsPage} />
            <Route path="/components" component={ComponentsPage} />
            <Route path="/contact-us" component={ContactUsPage} />
            <Route path="/login" component={LoginPage} />
            <Route path="/sections" component={SectionsPage} />
            <Route path="/signup" component={SignupPage} />
            <Route path="/" component={LandingPage} />
          </Switch>
        </Router>
      </IntlProvider>
    </Context.Provider>
  );
}

export { IntlProviderWrapper, Context as IntlContext };
