import React from "react";
import { FormattedMessage } from "react-intl";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import SignUpPopup from "components/SignUpPopup/SignUpPopup";
import PageFooter from "components/Footer/PageFooter.js";
import HeaderLinks from "components/Header/PageHeaderLinks.js";
import landingPageStyle from "assets/jss/material-kit-pro-react/Pages/landingPageStyle.js";

// Sections for this page
import SectionProduct from "./Sections/SectionProduct.js";
import SectionSolution from "./Sections/SectionSolution.js";
import SectionApplications from "./Sections/SectionApplications.js";
import SectionTreinamento from "./Sections/SectionTreinamento.js";
import SectionPlataforma from "./Sections/SectionPlataforma.js";
import SectionQuestions from "./Sections/SectionQuestions.js";
import SectionPartners from "./Sections/SectionPartners.js";
import "assets/css/homeVideo.css";

import TagManager from "react-gtm-module";
import ReactPlayer from "react-player/youtube";

// Animações
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const useStyles = makeStyles(landingPageStyle);

export default function LandingPage({ ...rest }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  TagManager.dataLayer({
    dataLayer: {
      path: "/",
      page: "landpage",
    },
    dataLayerName: "PageView",
  });

  const classes = useStyles();
  return (
    <div>
      <Header
        color="white"
        brand="EyeFlow.AI"
        links={<HeaderLinks dropdownHoverColor="info" />}
        displayImage="block"
        fixed
        changeColorOnScroll={{
          height: 300,
          color: "white",
        }}
        {...rest}
      />
      <SignUpPopup style={{ display: "none" }} />
      <div
        style={{
          overflow: "hidden",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
        className={classNames(classes.main)}
      >
        <div className={classes.videoText}>
          <div style={{ maxWidth: "36.5rem" }}>
            <h1
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              style={{ color: "#454545" }}
              className={classes.title}
            >
              <span style={{ fontWeight: 200, marginRight: "0em" }}>
                <FormattedMessage
                  id="land.title"
                  defaultMessage="Potencialize seu{break}projeto de"
                  values={{ break: <br /> }}
                />
              </span>
              <span style={{ fontWeight: 700 }}>
                <FormattedMessage
                  id="land.title2"
                  defaultMessage="VideoAnalytics?"
                />
              </span>
            </h1>
          </div>
          <div className="home">
            <div style={{ borderRadius: "20px" }} className="video-container">
              <div className="video_foreground">
                <ReactPlayer
                  className={classes.videoHome}
                  width="90%"
                  height="90%"
                  muted
                  loop
                  playing
                  url={
                    "https://www.youtube.com/embed/l1oYcxmfE7w?controls=0&showinfo=0&rel=0&autoplay=1&loop=1&mute=1&playlist=l1oYcxmfE7w"
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <SectionProduct />
        <SectionSolution />
        <SectionPartners />
        <SectionApplications />
        <SectionTreinamento />
        <SectionPlataforma />
        <SectionQuestions />
      </div>
      <PageFooter />
    </div>
  );
}
