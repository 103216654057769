import {
  title,
  description,
  mrAuto,
  mlAuto
} from "assets/jss/material-kit-pro-react.js";
import {
  palleteColor1,
} from "assets/jss/land_cores.js";

const servicesStyle = {
  title:{
    ...title,
    fontFamily: "'Exo 2'",
    // marginLeft:"-0.075em",
    fontSize: "35px",
    display: "inline-block",
    lineHeight: "1em",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    marginLeft: "-3em",
    color: palleteColor1,
    textDecoration: "none",
    whiteSpace: "nowrap",
    "@media (max-width: 480px)": {
      marginLeft: "0em",
      whiteSpace: "break-spaces",
      fontSize: "1.7em",
      marginBottom: "1.5em",
    },
  },
  eyeflow:{

  },
  mrAuto,
  mlAuto,
  services: {
    paddingTop: "5px",
    paddingBottom: "5px",
    justifyContent: "center"
  },
  textCenter: {
    textAlign: "center",
    fontFamily: "'Exo 2'",
    // margin: "0em 9em"
  },
  imgAlign: {
    textAlign: "left",
    fontFamily: "'Exo 2'",
    // margin: "0em 9em"
  },
  sectionImage: {
    "@media (max-width: 480px)": {
      display: "none"
    },
    height: "600px",
    width: "450px",
    // marginBottom: "-600px",
    overflow: "hidden",
  },
  imageFluid: {
    "@media (max-width: 480px)": {
      display: "none"
    },
    height: "1100px",
    margin: "-420px 0px 0px 620px",
  },
  imageDisplay:{
    "@media (max-width: 480px)":{
      display:"none"
    },
  },
  description
};

export default servicesStyle;
