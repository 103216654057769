import React from "react";
import { FormattedMessage } from "react-intl";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import applicationsStyle from "assets/jss/material-kit-pro-react/Pages/landingPageSections/applicationsStyle.js";

import fluid_yellow_png from "assets/img/fluid-yellow.png";

import { Parallax, Background } from "react-parallax";
// Vídeos
import Vagões from "assets/videos/Desafio3-c.mp4";
import Bancos from "assets/videos/Desafio1-c.mp4";
import Volêi from "assets/videos/Desafio2-c.mp4";
import EPI from "assets/videos/Desafio4-c.mp4";
import Presença from "assets/videos/Desafio5-c.mp4";
import Medicamentos from "assets/videos/Desafio6-c.mp4";
import Peças from "assets/videos/Desafio7-c.mp4";
import Chassi from "assets/videos/Desafio8-c.mp4";
import VideoCover from "react-video-cover";
import Grid from "@material-ui/core/Grid";
import RotateRightIcon from "@material-ui/icons/RotateRight";
// import TagManager from "react-gtm-module";

import { grayColor } from "assets/jss/material-kit-pro-react.js";

const style = {
  ...applicationsStyle,
  justifyContentCenter: {
    justifyContent: "center",
  },
};

const useStyles = makeStyles(style);

export default function SectionApplications() {
  const classes = useStyles();

  // TagManager.dataLayer({
  //   dataLayer: {
  //     path: "/#section_application",
  //     page: "section_application",
  //   },
  //   dataLayerName: "PageView",
  // });

  React.useEffect(() => {
    if (window) {
      window.addEventListener("resize", addStylesForExpandingCards);
    }

    addStylesForExpandingCards();

    return function cleanup() {
      if (window) {
        window.removeEventListener("resize", addStylesForExpandingCards);
      }
    };
  });

  const addStylesForExpandingCards = () => {
    var expandingCards = document.getElementsByClassName(classes.cardExpand);
    for (let i = 0; i < expandingCards.length; i++) {
      var expandingCard = expandingCards[i];
      var cardFront = expandingCard.getElementsByClassName(classes.front)[0];
      var cardBack = expandingCard.getElementsByClassName(classes.back)[0];
      cardFront.style.height = "unset";
      cardFront.style.width = "unset";
      cardBack.style.height = "unset";
      cardBack.style.width = "unset";
      var expandingWrapper = expandingCard.parentElement;
      var cardWidth = expandingCard.parentElement.offsetWidth;
      var cardHeight = expandingCard.children[0].children[0].offsetHeight;
      expandingWrapper.style.height = cardHeight + "px";
      expandingWrapper.style["margin-bottom"] = 30 + "px";
      cardFront.style.height = "unset";
      cardFront.style.width = "unset";
      cardBack.style.height = "unset";
      cardBack.style.width = "unset";
      cardFront.style.height = "316px";
      cardFront.style.width = cardWidth + 5 + "px";
      cardBack.style.height = "316px";
      cardBack.style.width = cardWidth + 5 + "px";
    }
  };

  const applications = [
    {
      desafio: (
        <FormattedMessage
          id="land.applications.app1_front"
          defaultMessage="Detecção de enrugamentos em bancos de couro"
        />
      ),
      title: (
        <FormattedMessage
          id="land.applications.app1title_front"
          defaultMessage="Manufatura"
        />
      ),
      video: Bancos,
      back: (
        <FormattedMessage
          id="land.applications.app1_back"
          defaultMessage="Detecção de enrugamentos em bancos de couro."
        />
      ),
    },
    {
      desafio: (
        <FormattedMessage
          id="land.applications.app2_front"
          defaultMessage="Identificação de marcas em campeonatos de vôlei"
        />
      ),
      title: (
        <FormattedMessage
          id="land.applications.app2title_front"
          defaultMessage="Mídia"
        />
      ),
      video: Volêi,
      back: (
        <FormattedMessage
          id="land.applications.app2_back"
          defaultMessage="Detecção de itens do veículo em linha de produção."
        />
      ),
    },
    {
      desafio: (
        <FormattedMessage
          id="land.applications.app3_front"
          defaultMessage="Verificação de quantidade de medicamentos"
        />
      ),
      title: (
        <FormattedMessage
          id="land.applications.app3_title_front"
          defaultMessage="Farmacêutica"
        />
      ),
      video: Medicamentos,
      back: (
        <FormattedMessage
          id="land.applications.app3_back"
          defaultMessage="Detecção do número do chassi na etiqueta do para-brisa do veículo em linha."
        />
      ),
    },
    {
      desafio: (
        <FormattedMessage
          id="land.applications.app4_front"
          defaultMessage="Conferência de EPI's em chão de fábrica"
        />
      ),
      title: (
        <FormattedMessage
          id="land.applications.app4title_front"
          defaultMessage="Segurança no trabalho"
        />
      ),
      video: EPI,
      style: "1.5rem",
      back: (
        <FormattedMessage
          id="land.applications.app4_back"
          defaultMessage="Detecção do uso de EPIs pelos funcionários."
        />
      ),
    },
    {
      desafio: (
        <FormattedMessage
          id="land.applications.app5_front"
          defaultMessage="Inspeção dos componentes dos vagões ferroviários."
        />
      ),
      title: (
        <FormattedMessage
          id="land.applications.app5title_front"
          defaultMessage="Ferrovias"
        />
      ),
      video: Vagões,
      back: (
        <FormattedMessage
          id="land.applications.app5_back"
          defaultMessage="Inspeção dos componentes dos vagões ferroviários."
        />
      ),
    },
    {
      desafio: (
        <FormattedMessage
          id="land.applications.app6_front"
          defaultMessage="Identificação de chassi em linha de produção"
        />
      ),
      title: (
        <FormattedMessage
          id="land.applications.app6title_front"
          defaultMessage="Automotiva"
        />
      ),
      video: Chassi,
      back: (
        <FormattedMessage
          id="land.applications.app6_back"
          defaultMessage="Detecção do número de fabricação gravado no bloco do motor."
        />
      ),
    },
    {
      desafio: (
        <FormattedMessage
          id="land.applications.app7_front"
          defaultMessage="Inspeção de presença em linha ferroviária"
        />
      ),
      title: (
        <FormattedMessage
          id="land.applications.app7title_front"
          defaultMessage="Mapeamento"
        />
      ),
      video: Presença,
      back: (
        <FormattedMessage
          id="land.applications.app7_back"
          defaultMessage="Detecção de falhas em chapas metálicas estampadas."
        />
      ),
    },
    {
      desafio: (
        <FormattedMessage
          id="land.applications.app8_front"
          defaultMessage="Inspeção de qualidade em peças metálicas."
        />
      ),
      title: (
        <FormattedMessage
          id="land.applications.app8title_front"
          defaultMessage="Metalurgia"
        />
      ),
      video: Peças,
      back: (
        <FormattedMessage id="land.applications.app8_back" defaultMessage="" />
      ),
    },
  ];

  const renderContainerFluid = (cssClass) => {
    return (
      <div
        className={classes.cards}
        style={{
          maxWidth: "1408px",
          margin: "0 auto",
          display: "grid",
          gap: "1rem",
        }}
      >
        {applications.map((el, index) => {
          const videoOptions = {
            src: el.video,
            autoPlay: true,
            loop: true,
            muted: true,
          };
          return (
            <div className={classes.card}>
              <div className={classes.cardStyle}>
                <div className={classes.cardFront}>
                  <h3
                    className={cssClass.cardTitle}
                    style={{ paddingInline: el.style }}
                  >
                    {el.title}
                  </h3>
                  <p className={cssClass.cardDescription}>{el.desafio}</p>
                  <div
                    className={classes.turnIcon}
                    style={{
                      position: "absolute",
                      bottom: "2%",
                      right: "2%",
                      opacity: ".5",
                    }}
                  >
                    <RotateRightIcon fontSize="medium" />
                  </div>
                </div>
                <div className={classes.cardBack}>
                  <VideoCover
                    videoOptions={videoOptions}
                    style={{
                      borderRadius: "6px",
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <Parallax
        style={{ height: 10, overflow: "visible", left: "-54rem" }}
        strength={500}
        className={classes.parallax}
      >
        <div className={classes.sectionImage} />
        <Background>
          <img src={fluid_yellow_png} alt="" className={classes.imageFluid} />
        </Background>
      </Parallax>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        id="section_application"
      >
        <Grid
          md={8}
          sm={9}
          xs={9}
          item
          className={classes.section}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10rem",
          }}
        >
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            className={classes.sectionText}
          >
            <h1 className={classes.title} style={{ fontWeight: 700 }}>
              <FormattedMessage
                id="land.applications.title"
                defaultMessage="Aplicações"
              />
            </h1>
            <br />
            <h1
              className={classes.title}
              style={{ fontWeight: 100, color: grayColor[1] }}
            >
              <FormattedMessage
                id="land.applications.title2"
                defaultMessage="Conheça os desafios que se tornaram soluções."
              />
            </h1>
            {renderContainerFluid(classes)}
          </div>
          <button className={classes.appButton}>
            <a
              href="/contact-us"
              rel="noopener noreferrer"
              style={{ color: "rgb(255 156 8)" }}
            >
              <FormattedMessage
                id="app.button"
                defaultMessage="Fale com um de nossos especialistas"
                values={{ break: <br /> }}
              />
            </a>
          </button>
        </Grid>
      </Grid>
    </>
  );
}
