import {
  title,
  description,
  mrAuto,
  mlAuto,
  grayColor
} from "assets/jss/material-kit-pro-react.js";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.js";

const contactStyle = {
  title:{
    ...title,
    fontFamily: "'Exo 2'"
  },
  mrAuto,
  mlAuto,
  ...customSelectStyle,
  thanksMessage: {
    backgroundColor: "lightblue",
    width: "-webkit-fill-available",
    padding: "1.5em",
    borderRadius: "25px",
    fontFamily: "'Exo 2'",
    fontWeight: "500",
    fontSize: "2em",
    lineHeight: "1em",
    textAlign: "-webkit-center",
    "& img": {
      width: "5em"
    }
  },
  description: {
    ...description,
    marginBottom: "70px"
  },
  textCenter: {
    textAlign: "center!important",
    fontFamily: "'Exo 2'"
  },
  buttonStyle:{
    textAlign: "center",
    backgroundColor: "#3f51b5",
    color: "white",
    padding: "12px 20px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    float: "right",
  },
  selectUnderlineRoot: {
    "& > div": {
      marginTop: "13px"
    }
  },
  aboutUs: {
    padding: "80px 0px"
  },
  inputAdornment: {
    marginRight: "18px",
    position: "relative",
  },
  inputAdornmentIcon: {
    color: grayColor[13],
  },
  form: {
    margin: "0",
    width: '-webkit-fill-available'
  },
  textStyle:{
    padding: "10px 0px",
    // width: "130%",
  },
};

export default contactStyle;
