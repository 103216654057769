import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import apisauce from 'apisauce';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

// @material-ui/icons
import Face from '@material-ui/icons/Face';
import Email from '@material-ui/icons/Email';
import Timeline from '@material-ui/icons/Timeline';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

// core components
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/PageHeaderLinks.js';
import PageFooter from 'components/Footer/PageFooter.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import InfoArea from 'components/InfoArea/InfoArea.js';
import Form from 'components/Form/FormFields.js';
import { IntlContext } from '../../intlProviderWrapper';
import PasswordValidator from 'password-validator';

import fluid_yellow_png from 'assets/img/fluid-yellow.png';
import fluid_blue_png from 'assets/img/fluid-blue.png';
import eyeflowImg from 'assets/img/logos/logoEyeFlowNameless32.png';
import GoogleOAuth from 'components/GoogleLogin/GoogleOAuth.js';
import signupPageStyle from 'assets/jss/material-kit-pro-react/Pages/signupPageStyle.js';
import TagManager from 'react-gtm-module';

const useStyles = makeStyles(signupPageStyle);
const tagManagerArgs = {
  dataLayer: {
    page: 'signup-page',
  },
  dataLayerName: 'PageView',
};

const SignUpPage = ({ intl, ...rest }) => {
  const classes = useStyles();
  const invitationToken = window.location.search.split('?token=')[1];
  const popUpUser = JSON.parse(new URLSearchParams(window.location.search).get('popUpUser'));
  const { locale } = React.useContext(IntlContext);
  const [formCondition, setFormCondition] = useState(false);
  const [error, setError] = useState(false);
  const [allValid, setAllValid] = useState(false);
  const [sentEmail, setSentEmail] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [notification, setNotification] = useState({
    message: '',
    type: '',
    loading: false,
  });
  const [open, setOpen] = useState(false);
  const [dados, setDados] = useState({
    first_name: '',
    last_name: '',
    username: '',
    email: '',
    phone: '',
    password: '',
    language: '',
    type: '',
    agreed: false,
  });

  const forgotPasswordLink = (
    <span
      style={{
        fontFamily: "'Exo 2'",
        color: 'white',
        fontWeight: '600',
      }}
    >
      <FormattedMessage id="signup.notification6" defaultMessage="Esse email já foi cadastrado, para redefinir sua senha clique" />
      <a
        className="external_link"
        target="_blank"
        rel="noreferrer noopener nofollow"
        href="https://app.eyeflow.ai/forgot-password/request"
        style={{ fontWeight: '800', color: '#27378a', paddingLeft: '0.3rem' }}
      >
        <FormattedMessage id="signup.notification6_link" defaultMessage="aqui" />
      </a>
    </span>
  );

  TagManager.dataLayer(tagManagerArgs);

  const api = apisauce.create({
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: invitationToken ? `Bearer ${invitationToken}` : '',
    },
  });

  const checkUserInvited = (uid, type) => api.get(`${window.WS_URL}/user/validate/uid/${uid}`, { type });
  const checkTokenInvitation = () => api.get(`${window.WS_URL}/is-token-valid`, {});
  const acceptInvitation = (profile, _token) =>
    api.post(`${window.WS_URL}/user/invite/accept?token=${_token}`, {
      ...profile,
    });
  const newUserNoToken = (profile) => api.post(`${window.WS_URL}/user/invite/accept`, { ...profile });
  const checkUserEmail = (email) => api.get(`${window.WS_URL}/user/validate/email/${email}`);

  const [fields, setFields] = useState([
    {
      label: <FormattedMessage id="signup.field1" defaultMessage="Nome" />,
      required: true,
      icon: <Face />,
      value: dados['nome'],
      error: false,
      helperText: '',
      valid: false,
      isFull: false,
      className: classes.nameField,
    },
    {
      label: <FormattedMessage id="signup.field2" defaultMessage="Sobrenome" />,
      required: true,
      icon: <Face />,
      value: dados['last_name'],
      error: false,
      helperText: '',
      valid: false,
      isFull: false,
      className: classes.lastNameField,
    },
    {
      label: <FormattedMessage id="signup.field3" defaultMessage="Email" />,
      required: true,
      icon: <Email />,
      value: dados['email'],
      disabled: false,
      valid: false,
      isFull: true,
      maxLength: 254,
      shrink: false,
    },
    {
      label: <FormattedMessage id="signup.field4" defaultMessage="Username" />,
      required: true,
      icon: <AssignmentIndIcon />,
      value: dados['username'],
      error: false,
      helperText: '',
      valid: false,
      isFull: true,
      disabled: false,
      loading: false,
      time: 0,
    },
    {
      label: <FormattedMessage id="signup.field5" defaultMessage="Celular" />,
      required: false,
      value: dados['phone'],
      error: false,
      helperText: '',
      valid: true,
      isFull: true,
    },
    {
      label: <FormattedMessage id="signup.field6" defaultMessage="Senha" />,
      required: true,
      value: dados['password'],
      error: false,
      helperText: '',
      valid: false,
      isFull: true,
    },
  ]);

  useEffect(() => {
    dados['language'] = locale;
    setDados(dados);
  }, [locale, dados, fields]);

  var schema = new PasswordValidator();
  schema
    .is()
    .min(8) // Minimum length 8
    .is()
    .max(50) // Maximum length 50
    .has()
    .not()
    .spaces(); // Should not have spaces

  useEffect(() => {
    if (window.location.search.split('?').length === 1) {
      Object.assign(dados, { type: 'ldap', engineType: "engine", loginType: 'Self' });
      setDados(dados);
    } else {
      if (window.location.search.includes('popUpUser')) {
        dados['type'] = 'ldap';
        dados['engineType'] = "engine";
        dados['loginType'] = 'Self';
        setDados(dados);
        if (/^[^\d]+$/g.test(popUpUser.nome)) {
          dados['first_name'] = popUpUser.nome;
          fields[0].error = false;
          fields[0].value = popUpUser.nome;
          fields[0].helperText = '';
          fields[0].valid = true;
          setFields([...fields]);
        } else {
          fields[0].error = true;
          fields[0].value = popUpUser.nome;
          fields[0].helperText = <FormattedMessage id="signup.helper1" defaultMessage="Nome Inválido" />;
          fields[0].valid = false;
          setFields([...fields]);
        }
        if (/^[^\d]+$/g.test(popUpUser.sobrenome)) {
          dados['last_name'] = popUpUser.sobrenome;
          fields[1].value = popUpUser.sobrenome;
          if (dados['first_name']) {
            checkUserInvitedFunc(`${dados['first_name']}.${popUpUser.sobrenome}`, 'last_name');
          }
          fields[1].error = false;
          fields[1].helperText = '';
          fields[1].valid = true;
          setFields([...fields]);
        } else {
          fields[1].error = true;
          fields[1].value = popUpUser.sobrenome;
          fields[1].helperText = <FormattedMessage id="signup.helper2" defaultMessage="Sobrenome Inválido" />;
          fields[1].valid = false;
          setFields([...fields]);
        }

        if (/\S+@\S+\.\S+/.test(popUpUser.email)) {
          checkUserEmail(popUpUser.email).then((result) => {
            if (!result.data.ok && result.data.user) {
              fields[2].error = true;
              fields[2].value = popUpUser.email;
              fields[2].helperText = <FormattedMessage id="signup.helper12" defaultMessage="Esse email já foi cadastrado" />;
              fields[2].valid = false;
              setFields([...fields]);
            } else {
              fields[2].error = false;
              fields[2].value = popUpUser.email;
              fields[2].helperText = '';
              fields[2].valid = true;
              setFields([...fields]);
            }
          });
          setFields([...fields]);
        } else {
          fields[2].error = true;
          fields[2].value = popUpUser.email;
          fields[2].helperText = <FormattedMessage id="signup.helper3" defaultMessage="Email Inválido" />;
          fields[2].valid = false;
          setFields([...fields]);
        }
      }

      if (invitationToken) {
        _checkTokenInvitation();
        dados['type'] = 'ldap';
        dados['engineType'] = "engine";
        dados['loginType'] = 'Invited';
        setDados(dados);
      } else {
        dados['type'] = 'ldap';
        dados['engineType'] = "engine";
        dados['loginType'] = 'Self';
        setDados(dados);
      }
    }
    // eslint-disable-next-line
  }, []);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const checkUserInvitedFunc = (userName, field) => {
    fields[3].loading = false;

    if (userName === '') {
      fields[3].valid = false;
      fields[3].error = true;
      fields[3].helperText = <FormattedMessage id="signup.helper11" defaultMessage="Campo obrigatório" />;
      setFields([...fields]);
      setAllValid(false);
      return;
    };

    let regex = /^[a-zA-Z0-9][a-zA-Z0-9._-]{0,253}[a-zA-Z0-9]$/;
    if (!regex.test(userName)) {
      console.log(`Invalid username: ${userName}`);
      fields[3].valid = false;
      fields[3].error = true;
      fields[3].helperText = <FormattedMessage id="signup.helper9" defaultMessage="Username Inválido" />;
      setFields([...fields]);
      setAllValid(false);
      return;
    };

    checkUserInvited(userName, 'engine')
      .then((result) => {
        fields[3].time = result.duration + 5;
        setFields([...fields]);
        if (result.ok) {
          if (result.data.user) {
            let Text = userName.split('.');
            if (field === 'username') {
              fields[3].valid = false;
              fields[3].error = true;
              if (userName === '') {
                fields[3].helperText = <FormattedMessage id="signup.helper11" defaultMessage="Campo obrigatório" />;
              } else {
                fields[3].helperText = (
                  <FormattedMessage
                    id="signup.helper10"
                    defaultMessage="Username sugerido: {first_name}.{last_name}{randomNumber}"
                    values={{
                      first_name: Text[0],
                      last_name: Text[1],
                      randomNumber: `${Math.floor(Math.random() * 10)}${Math.floor(Math.random() * 10)}`,
                    }}
                  />
                );
              }
              setFields([...fields]);
            }
          } else {
            if (field === 'username') {
              fields[3].valid = true;
              fields[3].error = false;
              if (dados['first_name'] && dados['last_name']) {
                if (userName === '') {
                  fields[3].helperText = <FormattedMessage id="signup.helper11" defaultMessage="Campo obrigatório" />;
                  fields[3].error = true;
                } else {
                  fields[3].helperText = (
                    <FormattedMessage
                      id="signup.helper8"
                      defaultMessage="Username sugerido: {first_name}.{last_name}{randomNumber}"
                      values={{
                        first_name: dados['first_name'],
                        last_name: dados['last_name'],
                        randomNumber: `${Math.floor(Math.random() * 10)}${Math.floor(Math.random() * 10)}`,
                      }}
                    />
                  );
                }
                fields[3].valid = true;
                fields[3].error = false;
                setFields([...fields]);
              } else {
                fields[3].helperText = '';
                fields[3].valid = true;
                fields[3].error = false;
                setFields([...fields]);
              }
            } else {
              if (dados['first_name'] && dados['last_name'] && fields[3].valid) {
                if (userName === '') {
                  fields[3].helperText = <FormattedMessage id="signup.helper11" defaultMessage="Campo obrigatório" />;
                  fields[3].error = true;
                } else {
                  fields[3].helperText = (
                    <FormattedMessage
                      id="signup.helper8"
                      defaultMessage="Username sugerido: {first_name}.{last_name}{randomNumber}"
                      values={{
                        first_name: dados['first_name'],
                        last_name: dados['last_name'],
                        randomNumber: `${Math.floor(Math.random() * 10)}${Math.floor(Math.random() * 10)}`,
                      }}
                    />
                  );
                }
                fields[3].valid = true;
                setFields([...fields]);
              }
            }
          }
        } else {
          if (field === 'username') {
            fields[3].valid = false;
            fields[3].error = true;
            if (userName === '') {
              fields[3].helperText = <FormattedMessage id="signup.helper11" defaultMessage="Campo obrigatório" />;
              fields[3].error = true;
            } else {
              fields[3].helperText = <FormattedMessage id="signup.helper9" defaultMessage="Username Inválido" />;
            }
            setFields([...fields]);
          } else {
            fields[3].valid = false;
            if (userName === '') {
              fields[3].helperText = <FormattedMessage id="signup.helper11" defaultMessage="Campo obrigatório" />;
              fields[3].error = true;
            } else {
              fields[3].helperText = '';
            }
            setFields([...fields]);
          }
        }
      })
      .finally(() => {
        if (fields.filter((it) => it.valid === true).length === 6) {
          setAllValid(true);
        } else {
          setAllValid(false);
        }
      });
  };

  const _checkTokenInvitation = () => {
    checkTokenInvitation(invitationToken).then((result) => {
      if (result.status === 200) {
        setIsTokenValid(true);
        setNotification({
          message: <FormattedMessage id="signup.notification1" defaultMessage="Seu token é válido, você pode ser registrar agora." />,
          type: 'success',
          loading: false,
        });
        setOpen(true);
        dados['email'] = result.data.payload.email;
        setDados(dados);
        fields[2].shrink = true;
        fields[2].disabled = true;
        fields[2].value = result.data.payload.email;
        fields[2].valid = true;
        setFields([...fields]);
      } else if (result.status === 401) {
        setNotification({
          message: (
            <FormattedMessage
              id="signup.notification2"
              defaultMessage="Seu token não é válido, contate seu administrador ou cadastre-se agora"
            />
          ),
          type: 'error',
          loading: false,
        });
        setOpen(true);
        dados['email'] = '';
        setIsTokenValid(false);
        fields[2].disabled = false;
        fields[2].value = dados['email'];
        fields[2].valid = false;
      }
    });
  };

  function handleFormSubmit(e) {
    e.preventDefault();
    // setDados(dados);
    if (invitationToken && isTokenValid) {
      setFormCondition(false);
      acceptInvitation(dados, invitationToken).then((result) => {
        if (result.status === 201) {
          setSentEmail(true);
          setNotification({
            message: <FormattedMessage id="signup.notification3" defaultMessage="Usuário Cadastrado, você vai ser redirecionado!" />,
            type: 'success',
            loading: true,
          });
          setOpen(true);
          setChecked(false);
          setDados({
            first_name: '',
            last_name: '',
            username: '',
            phone: '',
            password: '',
            language: '',
            agreed: false,
          });
          window.location.href = window.APP_URL;
        } else {
          if (result.data.error.message && result.data.error.message === 'User is registered in Eyeflow Db Admin.') {
            setFormCondition(true);
            console.log('Email was not send to ' + dados.email + '.');
            setNotification({
              message: forgotPasswordLink,
              type: 'error',
              loading: false,
            });
            setOpen(true);
            setError(true);
          }
        }
      });
    } else {
      setFormCondition(false);
      // if (invitationToken) {
      newUserNoToken(dados).then((result) => {
        if (result.status === 201) {
          setSentEmail(true);
          setFormCondition(false);
          console.log('The invite was send to ' + dados.email + '.');
          setNotification({
            message: <FormattedMessage id="signup.notification4" defaultMessage="Você vai receber um email de confirmação" />,
            type: 'success',
            loading: false,
          });
          setOpen(true);
          setChecked(false);
          setDados({
            first_name: '',
            last_name: '',
            username: '',
            phone: '',
            password: '',
            language: '',
            agreed: false,
          });
          window.location.href = window.APP_URL;
        } else {
          if (result.data.error.message && result.data.error.message === 'User is registered in Eyeflow Db Admin.') {
            setFormCondition(true);
            console.log('Email was not send to ' + dados.email + '.');
            setNotification({
              message: forgotPasswordLink,
              type: 'error',
              loading: false,
            });
            setOpen(true);
            setError(true);
          }
        }
      });
    }
  }

  const emailResponse =
    invitationToken || dados['loginType'] === 'Self Google' ? (
      <div className={classes.thanksMessage}>
        <img src={eyeflowImg} alt="" />
        <br />
        <br />
        <FormattedMessage id="signup.notification3" defaultMessage="Usuário Cadastrado, você vai ser redirecionado!" />
      </div>
    ) : (
      <div className={classes.thanksMessage}>
        <img src={eyeflowImg} alt="" />
        <br />
        <br />
        <FormattedMessage id="signup.notification4" defaultMessage="Você vai receber um email de confirmação" />
      </div>
    );

  function handleCheck(e) {
    if (e.target.checked) {
      setFormCondition(true);
      dados['agreed'] = true;
      setChecked(e.target.checked);
    } else {
      setFormCondition(false);
      dados['agreed'] = false;
      setChecked(e.target.checked);
    }
  }

  function handlePhoneChange(value, country, e, formattedValue) {
    e.preventDefault();
    dados['phone'] = formattedValue;
    fields[4].valid = true;
    setFields([...fields]);
    setDados(dados);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  function handleInputChange(e) {
    e.preventDefault();
    if (e.target.id === 'first_name') {
      if (/^[^\d]+$/g.test(e.target.value) && e.target.value.length > 0) {
        dados['first_name'] = e.target.value;
        fields[0].error = false;
        fields[0].value = e.target.value;
        if (e.target.value === '') {
          fields[0].helperText = <FormattedMessage id="signup.helper11" defaultMessage="Campo obrigatório" />;
        } else {
          fields[0].helperText = '';
        }
        fields[0].valid = true;
        setFields([...fields]);
      } else {
        fields[0].error = true;
        fields[0].value = e.target.value;
        if (e.target.value === '') {
          fields[0].helperText = <FormattedMessage id="signup.helper11" defaultMessage="Campo obrigatório" />;
        } else {
          fields[0].helperText = <FormattedMessage id="signup.helper1" defaultMessage="Nome Inválido" />;
        }
        fields[0].valid = false;
        setFields([...fields]);
      }
    }

    if (e.target.id === 'last_name') {
      if (/^[^\d]+$/g.test(e.target.value) && e.target.value.length > 0) {
        dados['last_name'] = e.target.value;
        fields[1].value = e.target.value;
        if (dados['first_name']) {
          checkUserInvitedFunc(`${dados['first_name']}.${e.target.value}`, e.target.id);
        }
        fields[1].error = false;
        if (e.target.value === '') {
          fields[1].helperText = <FormattedMessage id="signup.helper11" defaultMessage="Campo obrigatório" />;
        } else {
          fields[1].helperText = '';
        }
        fields[1].valid = true;
        setFields([...fields]);
      } else {
        fields[1].error = true;
        fields[1].value = e.target.value;
        if (e.target.value === '') {
          fields[1].helperText = <FormattedMessage id="signup.helper11" defaultMessage="Campo obrigatório" />;
        } else {
          fields[1].helperText = <FormattedMessage id="signup.helper2" defaultMessage="Sobrenome Inválido" />;
        }
        fields[1].valid = false;
        setFields([...fields]);
      }
    }
    if (e.target.id === 'username') {
      dados['username'] = e.target.value;
      fields[3].value = e.target.value;
      checkUserInvitedFunc(e.target.value, e.target.id);
      setFields([...fields]);
    }

    if (e.target.id === 'email') {
      e.persist();
      dados['email'] = e.target.value;
      fields[2].value = e.target.value;
      if (/\S+@\S+\.\S+/.test(e.target.value)) {
        checkUserEmail(e.target.value).then((result) => {
          if (!result.data.ok && result.data.user) {
            fields[2].error = true;
            fields[2].value = e.target.value;
            forgotPasswordLink.props.style.color = 'inherit';
            fields[2].helperText = forgotPasswordLink;
            fields[2].valid = false;
            setFields([...fields]);
          } else {
            fields[2].error = false;
            fields[2].value = e.target.value;
            fields[2].helperText = '';
            fields[2].valid = true;
            setFields([...fields]);
          }
        });
      } else {
        fields[2].error = true;
        fields[2].value = e.target.value;
        if (e.target.value === '') {
          fields[2].helperText = <FormattedMessage id="signup.helper11" defaultMessage="Campo obrigatório" />;
        } else {
          fields[2].helperText = <FormattedMessage id="signup.helper3" defaultMessage="Email Inválido" />;
        }
        fields[2].valid = false;
        setFields([...fields]);
      }
    }

    if (e.target.id === 'password') {
      if (schema.validate(e.target.value) && e.target.value.length > 0) {
        dados['password'] = e.target.value;
        fields[5].value = e.target.value;
        fields[5].error = false;
        if (e.target.value === '') {
          fields[0].helperText = <FormattedMessage id="signup.helper11" defaultMessage="Campo obrigatório" />;
        } else {
          fields[0].helperText = '';
        }
        fields[5].valid = true;
        setFields([...fields]);
      } else {
        if (e.target.value.length < 8) {
          if (e.target.value === '') {
            fields[5].helperText = <FormattedMessage id="signup.helper11" defaultMessage="Campo obrigatório" />;
          } else {
            fields[5].helperText = <FormattedMessage id="signup.helper4" defaultMessage="Minimum length 8" />;
          }
          fields[5].value = e.target.value;
          fields[5].error = true;
          fields[5].valid = false;
          setFields([...fields]);
        } else if (e.target.value.length > 50) {
          if (e.target.value === '') {
            fields[5].helperText = <FormattedMessage id="signup.helper11" defaultMessage="Campo obrigatório" />;
          } else {
            fields[5].helperText = <FormattedMessage id="signup.helper5" defaultMessage="Maximum length 50" />;
          }
          fields[5].value = e.target.value;
          fields[5].error = true;
          fields[5].valid = false;
          setFields([...fields]);
        } else if (e.target.value.indexOf(' ') >= 0) {
          if (e.target.value === '') {
            fields[5].helperText = <FormattedMessage id="signup.helper11" defaultMessage="Campo obrigatório" />;
          } else {
            fields[5].helperText = <FormattedMessage id="signup.helper6" defaultMessage="Should not have spaces" />;
          }
          fields[5].value = e.target.value;
          fields[5].error = true;
          fields[5].valid = false;
          setFields([...fields]);
        }
      }
    }
    setFields([...fields]);
    setDados(dados);
    if (fields.filter((it) => it.valid === true).length === 6) {
      setAllValid(true);
    } else {
      setAllValid(false);
    }
  }

  return (
    <div>
      <Header
        absolute
        brand="EyeFlow.AI"
        color="white"
        changeColorOnScroll={{
          height: 300,
          color: 'white',
        }}
        links={<HeaderLinks dropdownHoverColor="info" />}
        {...rest}
      />
      <div className={classes.sectionImageBlue}>
        <img src={fluid_blue_png} alt="" className={classes.imageFluidBlue} />
      </div>
      <div className={classes.sectionImageYellow}>
        <img src={fluid_yellow_png} alt="" className={classes.imageFluidYellow} />
      </div>
      <div
      // className={classes.pageHeader}
      >
        <div className={classes.container}>
          <GridContainer style={{ marginTop: '-42em' }} justify="center">
            <GridItem xs={12} sm={10} md={10}>
              <Card className={classes.cardSignup}>
                <h2
                  style={{
                    fontFamily: "'Exo 2'",
                    fontSize: '2rem',
                    fontWeight: 700,
                  }}
                  className={classes.cardTitle}
                >
                  <FormattedMessage id="signup.title" defaultMessage="Criar conta" />
                </h2>
                <CardBody style={{ textAlign: 'center' }}>
                  <GridContainer justify="center">
                    <GridItem style={{ textAlign: 'center' }} xs={12} sm={5} md={5}>
                      <InfoArea
                        style={{ fontFamily: "'Exo 2'", textAlign: 'left' }}
                        className={classes.infoArea}
                        title="EyeFlow"
                        description={
                          <div style={{ textAlign: 'left' }}>
                            <span style={{ fontWeight: 700, lineHeight: '1.8em' }}>
                              <FormattedMessage
                                id="signup.title2"
                                defaultMessage="Nos ajude a melhorar cada vez mais a ferramenta e tenha acesso antes de todo mundo às novidades!"
                              />
                            </span>
                            <span style={{ fontWeight: 700, lineHeight: '1.8em' }}>
                              <FormattedMessage
                                id="signup.description"
                                defaultMessage="Você tem um projeto ou aplicação que demanda{break}uma solução de  AI e Video Analytics?{break}"
                                values={{ break: <br /> }}
                              />
                            </span>
                            <div style={{ textAlign: 'center' }}>
                              <span
                                style={{
                                  fontWeight: 700,
                                  lineHeight: '4em',
                                  color: '#27378a',
                                }}
                              >
                                <FormattedMessage
                                  id="signup.description1"
                                  defaultMessage="O EyeFlow é a solução para você.{break}"
                                  values={{ break: <br /> }}
                                />
                              </span>
                            </div>
                            <span style={{ fontWeight: 700, lineHeight: '1.8em' }}>
                              <FormattedMessage
                                id="signup.description3"
                                defaultMessage="Preencha os dados ao lado, nos conte um pouco sobre o seu desafio e entraremos{break}em contato.{break}Nossa plataforma foi idealizada para atender às mais variadas necessidades dos projetos de Video Analytics + Inteligência Artificial e sua participação é fundamental para torna-la cada vez melhor."
                                values={{ break: <br /> }}
                              />
                            </span>
                            <div style={{ textAlign: 'center' }}>
                              <span
                                style={{
                                  fontWeight: 700,
                                  lineHeight: '4em',
                                  color: '#27378a',
                                  fontSize: '1.1em',
                                }}
                              >
                                <FormattedMessage
                                  id="signup.description2"
                                  defaultMessage="O EyeFlow é a solução para você.{break}"
                                  values={{ break: <br /> }}
                                />
                              </span>
                            </div>
                          </div>
                        }
                        getIcon={false}
                        icon={Timeline}
                        iconColor="rose"
                      />
                    </GridItem>
                    <GridItem style={{ display: 'inline-flex' }} xs={12} sm={12} md={6}>
                      {sentEmail ? (
                        emailResponse
                      ) : (
                        <span>
                          <GoogleOAuth
                            dados={dados}
                            setDados={setDados}
                            invitationToken={invitationToken}
                            setSentEmail={setSentEmail}
                            notification={notification}
                            setNotification={setNotification}
                            open={open}
                            setOpen={setOpen}
                            error={error}
                          />
                          <Form
                            disabledButton={formCondition === false || allValid === false ? true : false}
                            termsCheckbox={true}
                            fields={fields}
                            names={['first_name', 'last_name', 'email', 'username', 'phone', 'password']}
                            handleInputChange={handleInputChange}
                            handleFormSubmit={handleFormSubmit}
                            handlePhoneChange={handlePhoneChange}
                            handleCheck={handleCheck}
                            checked={checked}
                            sentEmail={sentEmail}
                            emailResponse={emailResponse}
                            formId={invitationToken ? 'inviteSignUp' : 'selfSignUp'}
                            className={classes.textStyle}
                          />
                        </span>
                      )}

                      {notification['message'] === 'User Registered. You are going to be redirected' ? (
                        <div className={classes.wrapper}>
                          <Snackbar open={open} autoHideDuration={6000}>
                            <Alert severity={notification['type']}>
                              {notification['message']}
                              {notification['loading'] && (
                                <CircularProgress
                                  size={32}
                                  style={{
                                    width: '32px',
                                    height: '32px',
                                    margin: '-0.8em',
                                    marginLeft: '1em',
                                    marginRight: '-0.3em',
                                  }}
                                  className={classes.fabProgress}
                                />
                              )}
                            </Alert>
                          </Snackbar>
                        </div>
                      ) : (
                        <div>
                          <Snackbar open={open} onClose={handleClose}>
                            <Alert onClose={handleClose} severity={notification['type']}>
                              {notification['message']}
                            </Alert>
                          </Snackbar>
                        </div>
                      )}
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <PageFooter />
      </div>
      <div className={classes.sectionImageYellowBottom}>
        <img src={fluid_blue_png} alt="" className={classes.imageFluidBlueBottom} />
      </div>
      <div className={classes.sectionImageBlueBottom}>
        <img src={fluid_yellow_png} alt="" className={classes.imageFluidYellowBottom} />
      </div>
    </div>
  );
};

export default injectIntl(SignUpPage);
