import React from "react";
import { FormattedMessage } from "react-intl";

// react component for creating beautiful carousel
import Slider from "react-slick";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Grid from "@material-ui/core/Grid";

// Images
import partner1 from "assets/img/partners/partner1.png";
import partner2 from "assets/img/partners/partner2.png";
import partner3 from "assets/img/partners/partner3.png";
import partner5 from "assets/img/partners/partner5.png";
import partner6 from "assets/img/partners/partner6.png";
import partner7 from "assets/img/partners/partner7.png";
import partner8 from "assets/img/partners/partner8.png";
import partner9 from "assets/img/partners/partner9.jpg";

// Style
import { makeStyles } from "@material-ui/core";
import partnersStyle from "assets/jss/material-kit-pro-react/Pages/landingPageSections/partnersStyle.js";
// CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const style = {
  ...partnersStyle,
  justifyContentCenter: {
    justifyContent: "center",
  },
};

const useStyles = makeStyles(style);

export default function SectionPartners() {
  const classes = useStyles();

  const partner_list = [
    {
      url: "https://www.dxc.technology",
      className: classes.logoStyle,
      logo: partner1,
      style: {
        marginTop: "4em",
        height: "4.5em",
      },
      name: "Logo DXC",
    },
    {
      url: "https://www.hpe.com",
      className: classes.logoStyle,
      logo: partner2,
      style: {
        marginTop: "4em",
        height: "5em",
      },
      name: "Logo da empresa HPE",
    },
    {
      url: "https://www.microsoft.com",
      className: classes.logoStyle,
      logo: partner3,
      style: {
        marginTop: "-1em",
        height: "14em",
      },
      name: "Logo da empresa Microsoft",
    },
    {
      url: "https://gautama-automacao.com.br",
      className: classes.logoStyle,
      logo: partner5,
      name: "Logo da empresa Gautama",
    },
    {
      url: "https://nublify.com",
      className: classes.logoStyle,
      logo: partner6,
      style: {
        marginTop: "4.5em",
        height: "3em",
      },
      name: "Logo da empresa Nublify",
    },
    {
      url: "https://www.dc2c.com.br",
      className: classes.logoStyle,
      logo: partner7,
      style: {
        marginTop: "3.3em",
        filter: "invert(100%) sepia(100%) saturate(3609%) hue-rotate(241deg) brightness(75%) contrast(152%)",
      },
      name: "Logo da empresa DC2C",
    },
    {
      url: "https://startup.google.com/intl/pt-BR_ALL/",
      className: classes.logoStyle,
      logo: partner8,
      style: {
        marginTop: "4.5em",
        height: "3em",
        paddingInline: "2rem",
      },
      name: "Logo da empresa Google",
    },
    {
      url: "https://www.nvidia.com/en-us/autonomous-machines/intelligent-video-analytics-platform/",
      className: classes.logoStyle,
      logo: partner9,
      style: {
        height: "7rem",
        marginTop: "1.8rem",
      },
      name: "Logo da empresa Nvidia",
    },
  ];

  var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    centerMode: true,
    slidesToScroll: 1,
    pauseOnHover: true,
    initialSlide: 6,
    autoplay: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div id="section_partners" className={classes.section}>
      <div className={classes.title} style={{ color: "rgb(63 94 172)" }}>
        <FormattedMessage id="land.partners.title" defaultMessage="Parcerias e alianças" />
      </div>
      <Grid
        spacing={4}
        container
        direction="row"
        alignItems="flex-end"
        justify="flex-end"
        style={{ marginTop: "0.5rem", backgroundColor: "rgb(222 241 255)" }}
      >
        {/* <Grid
          xs={12}
          item
          className={classes.description}
          style={{ paddingBottom: "0", marginBottom: "0" }}
        >
          <FormattedMessage
            id="land.partners.subTitle"
            defaultMessage="Clique ao lado e conheça também as vantagens de utilizar EyeFlow em suas soluções e produtos."
          />
        </Grid> */}
        <Grid
          item
          xs={2}
          className={classes.titleLink}
          style={{
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "space-between",
            fontWeight: 500,
          }}
        >
          <a
            style={{
              fontSize: "1.125rem",
              fontWeight: "600",
              color: "rgb(13, 110, 253)",
              display: "flex",
              justifyContent: "space-between",
              width: "7.7rem",
            }}
            href="partners-solution"
          >
            <FormattedMessage id="land.partners.subTitleTag" defaultMessage="Saiba Mais" />
            <ArrowForwardIcon />
          </a>
        </Grid>
        <Grid xs={12} item style={{ paddingTop: "0", marginTop: "0" }} className={classes.gridSlider}>
          <Slider style={{ maxHeight: "11rem" }} {...settings}>
            {partner_list.map((partner, index) => {
              return (
                <div key={index}>
                  <a href={partner.url} target="_blank" rel="noopener noreferrer">
                    <img className={partner.className} alt={partner.name} style={partner.style} src={partner.logo} />
                  </a>
                </div>
              );
            })}
          </Slider>
        </Grid>
      </Grid>
    </div>
  );
}
