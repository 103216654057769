import React from "react";

// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { IntlContext } from "../../intlProviderWrapper";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";

// Icons
import { ReactComponent as IoEarth } from "assets/img/earth.svg";
// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
import logo_icon from "assets/img/eyeflow_inspection_logo_R_no_eye.png";

const useStyles = makeStyles(styles);

export default function PageHeaderLinks(props) {
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScroll = (e, target) => {
    if (window.location.pathname === "/") {
      var isMobile = navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
      );
      if (isMobile) {
        // if we are on mobile device the scroll into view will be managed by the browser
      } else {
        e.preventDefault();
        var targetScroll = document.getElementById(target);
        scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
        console.log(targetScroll);
      }
    } else {
      setTimeout(() => {
        var targetScroll = document.getElementById(target);
        scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
      }, 1000);
    }
  };

  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };
  // var onClickSections = {};

  const { dropdownHoverColor } = props;
  const classes = useStyles();
  const LanguageSwitch = () => {
    const { onChangeLanguage, languages } = React.useContext(IntlContext);
    const handleChangeLanguage = (lang) => {
      onChangeLanguage(lang);
    };

    return (
      <CustomDropdown
        noLiPadding
        navDropdown
        hoverColor={dropdownHoverColor}
        buttonText=""
        buttonProps={{
          className: `${classes.navLink} ${classes.icon}`,
          color: "transparent",
          id: "switchLang",
        }}
        buttonIcon={IoEarth}
        dropdownList={Object.keys(languages).map((loc) => (
          <Button
            onClick={() => handleChangeLanguage(languages[loc].locale)}
            className={classes.dropdownLink}
            id="switchLang"
          >
            {languages[loc].label}
          </Button>
        ))}
      />
    );
  };

  return (
    <List className={classes.list + " " + classes.mlAuto}>
      <ListItem className={classes.listItem}>
        <a
          id="inspection"
          href="https://inspection.eyeflow.ai"
          className={classes.navLink}
          // activeClassName={classes.navLinkActive}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "#2C3697",
            height: "50px",
          }}
        >
          <img src={logo_icon} alt="" style={{ maxWidth: "5rem" }} />
        </a>
      </ListItem>

      <ListItem className={classes.listItem}>
        <NavLink
          id="home"
          to="/"
          className={classes.navLink}
          // activeClassName={classes.navLinkActive}
          exact
        >
          <FormattedMessage id="land.header1" defaultMessage="Home" />
        </NavLink>
      </ListItem>

      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor="blue"
          buttonText={
            <span style={{ textTransform: "capitalize" }}>
              <FormattedMessage
                id="land.header.dropDown-title1"
                defaultMessage="Navegue"
              />
            </span>
          }
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
            id: "browse",
          }}
          dropdownList={[
            <NavLink
              to="/#section_solution"
              id="solutions"
              onClick={(e) => smoothScroll(e, "section_solution")}
              className={classes.dropdownLink}
            >
              <FormattedMessage id="land.header2" defaultMessage="Soluções" />
            </NavLink>,
            <NavLink
              to="/#section_application"
              id="applications"
              onClick={(e) => smoothScroll(e, "section_application")}
              className={classes.dropdownLink}
            >
              <FormattedMessage id="land.header3" defaultMessage="Aplicações" />
            </NavLink>,
            <NavLink
              to="/#section_training"
              id="train"
              onClick={(e) => smoothScroll(e, "section_training")}
              className={classes.dropdownLink}
            >
              <FormattedMessage
                id="land.header4"
                defaultMessage="Treinamento"
              />
            </NavLink>,
            <NavLink
              to="/#section_platform"
              id="platform"
              onClick={(e) => smoothScroll(e, "section_platform")}
              className={classes.dropdownLink}
            >
              <FormattedMessage
                id="land.header5"
                defaultMessage="A plataforma"
              />
            </NavLink>,
            <NavLink
              to="/#section_invite"
              id="invite"
              onClick={(e) => smoothScroll(e, "section_invite")}
              className={classes.dropdownLink}
            >
              <FormattedMessage
                id="land.header6"
                defaultMessage="Vamos Começar"
              />
            </NavLink>,
            <NavLink
              to="/#section_questions"
              id="questions"
              onClick={(e) => smoothScroll(e, "section_questions")}
              className={classes.dropdownLink}
            >
              <FormattedMessage id="land.header7" defaultMessage="Dúvidas" />
            </NavLink>,
            // <NavLink to="/#section_forum" id="forum" onClick={e => smoothScroll(e, "section_forum")} className={classes.dropdownLink}>
            //     <FormattedMessage id="land.header8" defaultMessage="Fórum"/>
            // </NavLink>,
            <NavLink
              to="/#section_partners"
              id="partners"
              onClick={(e) => smoothScroll(e, "section_partners")}
              className={classes.dropdownLink}
            >
              <FormattedMessage id="land.header10" defaultMessage="Parceiros" />
            </NavLink>,
            <NavLink
              className={classes.dropdownLink}
              to="/about-us"
              id="aboutUs"
            >
              <FormattedMessage id="land.header12" defaultMessage="Sobre nós" />
            </NavLink>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink
          to="/partners-solution"
          id="partners"
          className={classes.navLink}
          // activeClassName={classes.navLinkActive}
          exact
        >
          <FormattedMessage id="land.header10" defaultMessage="Parceiros" />
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink
          to="/blog"
          id="blog"
          className={classes.navLink}
          // activeClassName={classes.navLinkActive}
          exact
        >
          <FormattedMessage id="land.header9" defaultMessage="Blog" />
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <a href={window.APP_URL} id="login" className={classes.navLink}>
          <FormattedMessage id="land.header11" defaultMessage="Login" />
        </a>
      </ListItem>
      <ListItem className={classes.listItemLanguage}>
        <LanguageSwitch />
      </ListItem>
      <ListItem className={classes.listItemSignUp}>
        <NavLink
          to="/signup"
          id="signUpHeader"
          className={classes.navLinkSignUp}
          exact
        >
          <FormattedMessage
            id="land.header13"
            defaultMessage="Seja um{break}beta tester"
            values={{ break: <br /> }}
          />
        </NavLink>
      </ListItem>
    </List>
  );
}

PageHeaderLinks.defaultProps = {
  hoverColor: "primary",
};

PageHeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
  ]),
};
