import React from "react";
import { FormattedMessage } from "react-intl";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import plataformaStyle from "assets/jss/material-kit-pro-react/Pages/landingPageSections/plataformaStyle.js";

//Imagens
import logo_eyeflow from "assets/img/eyeflow_logo_R.png";
import logo_eyeflow_edge from "assets/img/logos/LogoEyeflowEdge.png";
import logo_eyeflow_inspection from "assets/img/eyeflow_inspection_logo_R.png";
// import fluxo_image from 'assets/img/logos/platform.svg';
// import TagManager from "react-gtm-module";

const style = {
  ...plataformaStyle,
  justifyContentCenter: {
    justifyContent: "center",
  },
};

const useStyles = makeStyles(style);

export default function SectionPlataforma() {
  const classes = useStyles();

  // TagManager.dataLayer({
  //   dataLayer: {
  //     path: "/#section_platform",
  //     page: "section_platform",
  //   },
  //   dataLayerName: "PageView",
  // });

  return (
    <div id="section_platform" className={classes.section}>
      <div style={{ maxWidth: "71rem" }}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          style={{ maxWidth: "20rem" }}
        >
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            className={classes.title}
          >
            <FormattedMessage
              id="land.plataforma.title"
              defaultMessage=" A plataforma"
            />
          </h1>
        </Grid>

        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={3}
          // style={{ width: "min(47rem, 100%)" }}
        >
          <Grid
            item
            md={10}
            sm={8}
            xs={8}
            className={classes.subTitle}
            style={{ marginRight: "-1.6rem", paddingBottom: "0" }}
          >
            <div>EyeFlow Cloud</div>
          </Grid>
          <Grid md={2} sm={4} xs={4} item style={{ paddingBlock: "0" }}>
            <a href="https://inspection.eyeflow.ai">
              <img
                src={logo_eyeflow}
                alt={"logo eyeflow"}
                className={classes.edgeImage}
              />
            </a>
          </Grid>
          <Grid style={{ fontWeight: 400, paddingBlock: 0 }} item xs={9}>
            <span
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              className={classes.description}
            >
              <FormattedMessage
                id="land.plataforma.description1"
                defaultMessage="Plataforma na nuvem para testes e aprimoramento de modelos de Visão Computacional. Construa e treine datasets ou elabore fluxos personalizados através de uploads de imagens ou vídeos."
              />
            </span>
          </Grid>
        </Grid>

        <hr className={classes.line} />

        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={3}
          // style={{ width: "min(47rem, 100%)" }}
        >
          <Grid
            item
            md={10}
            sm={8}
            xs={8}
            className={classes.subTitle}
            style={{ marginRight: "-.5rem", paddingBottom: "0" }}
          >
            <div>EyeFlow Edge</div>
          </Grid>
          <Grid md={2} sm={4} xs={4} item style={{ paddingBlock: "0" }}>
            <img
              src={logo_eyeflow_edge}
              alt={"logo edge"}
              className={classes.edgeImage}
              style={{ width: "160px" }}
            />
          </Grid>
          <Grid item xs={9} style={{ fontWeight: 400, paddingBlock: "0" }}>
            <span
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              className={classes.description}
            >
              <FormattedMessage
                id="land.plataforma.description2"
                defaultMessage="Solução para execução dos modelos na borda, através de um end-point ou num dispositivo local."
              />
            </span>
          </Grid>
        </Grid>

        <hr className={classes.line} />

        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={3}
          // style={{ width: "min(47rem, 100%)" }}
        >
          <Grid
            item
            md={10}
            sm={8}
            xs={8}
            className={classes.subTitle}
            style={{ marginRight: "-1.6rem", paddingBottom: "0" }}
          >
            <div>EyeFlow Inspection</div>
          </Grid>
          <Grid md={2} sm={4} xs={4} item style={{ paddingBlock: "0" }}>
            <a href="https://inspection.eyeflow.ai">
              <img
                src={logo_eyeflow_inspection}
                alt={"logo eyeflow inspection"}
                className={classes.edgeImage}
              />
            </a>
          </Grid>
          <Grid style={{ fontWeight: 400, paddingBlock: "0" }} item xs={9}>
            <span
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              className={classes.description}
            >
              <FormattedMessage
                id="land.plataforma.description3"
                defaultMessage="Plataforma na nuvem para testes e aprimoramento de modelos de Visão Computacional. Construa e treine datasets ou elabore fluxos personalizados através de uploads de imagens ou vídeos."
              />
            </span>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
