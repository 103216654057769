import React from "react";
import { FormattedMessage } from "react-intl";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import Footer from "components/Footer/Footer";
import { ReactComponent as MemberSealSvg } from "./MemberSeal2021_A3.svg";

import logo_eyeflow_mask from "assets/img/eyeflow_logo_R.png";
import styles from "assets/jss/material-kit-pro-react/Pages/componentsSections/footerStyle.js";

const useStyles = makeStyles(styles);

export default function SectionFooter() {
  const classes = useStyles();

  return (
    <div
      className={classes.section}
      style={{ paddingTop: "0px", paddingBottom: "0px" }}
    >
      <div>
        <Footer
          // theme="white"
          content={
            <div
              style={{ fontFamily: "'Exo 2'", fontWeight: 400 }}
              className={classNames(classes.pullCenter, classes.copyRight)}
            >
              <a
                style={{ color: "inherit", fontFamily: "'Exo 2'" }}
                href="https://eyeflow.ai/"
                target="_blank"
                rel="noreferrer noopener nofollow"
                id="homePage"
              >
                EyeFlow
              </a>{" "}
              - {1900 + new Date().getYear()} - Copyright -{" "}
              <a
                style={{ color: "inherit", fontFamily: "'Exo 2'" }}
                href="https://www.siliconlife.ai/"
                target="_blank"
                rel="noreferrer noopener nofollow"
                id="siliconLife"
              >
                SiliconLife{" "}
              </a>
            </div>
          }
        >
          <div className={classes.footer}>
            <GridContainer justify="center">
              <GridItem className={classes.toMobile} xs={12} sm={3} md={3}>
                <ul className={classes.linksVertical}>
                  <a id="homePageImg" className={classes.block} href="/">
                    <img
                      style={{ width: "14em" }}
                      src={logo_eyeflow_mask}
                      alt=""
                    />
                  </a>
                </ul>
              </GridItem>
              <GridItem className={classes.toMobile} xs={12} sm={2} md={2}>
                <ul className={classes.linksVertical}>
                  <h6>
                    <a
                      className={classes.aLink}
                      id="signUpFooter"
                      href="/signup"
                      style={{ fontFamily: "'Exo 2'", color: "inherit" }}
                    >
                      <FormattedMessage
                        id="signup.title"
                        defaultMessage="Acesse o beta"
                      />
                    </a>
                  </h6>
                  <h6>
                    <a
                      id="partnersFooter"
                      rel="noreferrer"
                      href="/partners-solution"
                      style={{ fontFamily: "'Exo 2'", color: "inherit" }}
                    >
                      <FormattedMessage
                        id="land.footer8"
                        defaultMessage="Parceiros"
                      />
                    </a>
                  </h6>
                  <h6>
                    <a
                      id="aboutUsFooter"
                      rel="noreferrer"
                      href="/about-us"
                      style={{ fontFamily: "'Exo 2'", color: "inherit" }}
                    >
                      <FormattedMessage
                        id="land.footer1"
                        defaultMessage="Sobre nós"
                      />
                    </a>
                  </h6>
                  <h6>
                    <a
                      href="https://www.siliconlife.ai/"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                      style={{ fontFamily: "'Exo 2'", color: "inherit" }}
                      id="siliconLifeFooter"
                    >
                      <FormattedMessage
                        id="land.footer2"
                        defaultMessage="SiliconLIFE"
                      />
                    </a>
                  </h6>
                </ul>
              </GridItem>
              <GridItem className={classes.toMobile} xs={12} sm={2} md={3}>
                <ul className={classes.linksVertical}>
                  <h6>
                    <a
                      id="contactUsFooter"
                      href="/contact-us"
                      style={{ fontFamily: "'Exo 2'", color: "inherit" }}
                    >
                      <FormattedMessage
                        id="land.footer9"
                        defaultMessage="Fale Conosco"
                      />
                    </a>
                  </h6>
                  <h6>
                    <a
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                      href="https://doc.eyeflow.ai/"
                      style={{ fontFamily: "'Exo 2'", color: "inherit" }}
                      id="docFooter"
                    >
                      <FormattedMessage
                        id="land.footer4"
                        defaultMessage="Documentação"
                      />
                    </a>
                  </h6>
                  <h6>
                    <a
                      id="politicasFooter"
                      href="/politica-privacidade"
                      style={{ fontFamily: "'Exo 2'", color: "inherit" }}
                    >
                      <FormattedMessage
                        id="land.footer6"
                        defaultMessage="Política de Privacidade"
                      />
                    </a>
                  </h6>
                  <h6>
                    <a
                      id="termsFooter"
                      rel="noreferrer"
                      href="/termos"
                      style={{ fontFamily: "'Exo 2'", color: "inherit" }}
                    >
                      <FormattedMessage
                        id="land.footer7"
                        defaultMessage="Termos e condições de uso"
                      />
                    </a>
                  </h6>
                </ul>
              </GridItem>
              <GridItem className={classes.toMobile} xs={12} sm={2} md={2}>
                <h4
                  style={{
                    color: "inherit",
                    fontFamily: "'Exo 2'",
                    fontSize: "1.1em",
                  }}
                >
                  <FormattedMessage
                    id="land.footerFollow"
                    defaultMessage="Follow Us"
                  />
                </h4>
                <ul className={classes.socialButtons}>
                  <li>
                    <Button
                      style={{ color: "white" }}
                      justIcon
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                      href="https://www.youtube.com/channel/UCv_losmhW1ECaHtcjRHqjxg"
                      color="youtube"
                      id="youtubeIcon"
                    >
                      <i className="fab fa-youtube" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      style={{ color: "white" }}
                      color="linkedin"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                      href="https://www.linkedin.com/company/siliconlife/about/"
                      justIcon
                      id="linkedinIcon"
                    >
                      <i className="fab fa-linkedin-in" />
                    </Button>
                  </li>
                </ul>
              </GridItem>
              <GridItem
                style={{ marginTop: "1em" }}
                className={classes.toMobile}
                xs={12}
                sm={2}
                md={1}
              >
                <ul className={classes.linksVertical}>
                  <a
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                    href="https://www.automate.org/?utm_campaign=A3%20Membership&utm_medium=email&_hsmi=202242389&_hsenc=p2ANqtz-9aC_ZRfoPdWCP3yCF_TIyoI5Nxa8yatJdncHu6Ip47eYKq4sS3UpUx3ldK4r8XnJvCk2hjw9Htst6xo2HwIuIyrWrJw0ltjAdvAayKUvCuCpPO3sg&utm_content=202242389&utm_source=hs_email"
                  >
                    <MemberSealSvg
                      style={{
                        objectFit: "cover",
                        width: "200px",
                        height: "200px",
                      }}
                    />
                  </a>
                </ul>
              </GridItem>
            </GridContainer>
          </div>
        </Footer>
      </div>
    </div>
  );
}
