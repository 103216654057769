const newHeaderStyle = {
  navLink: {
    fontFamily: "'Poppins', sans-serif !important",
    color: "#192245 !important",
    fontSize: "18px !important",
    fontWeight: "400",
    textDecorationColor: "rgb(25, 34, 69) !important",
    "&:hover": {
      color: "#909090 !important",
    },
    "&:focus": {
      color: "#909090 !important",
    },
  },
};
export default newHeaderStyle;
