import React from "react";
import { Link } from "react-router-dom";

// nodejs library that concatenates classes
import classNames from "classnames";

// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";

// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";

// core components
import styles from "assets/jss/material-kit-pro-react/components/headerStyle.js";
import logo_eyeflow_mask from "assets/img/eyeflow_logo_R.png";

const useStyles = makeStyles(styles);

export default function Header(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const classes = useStyles();
  const [condition, setCondition] = React.useState(false);

  React.useEffect(() => {
    if (window.location.pathname !== "/") {
      setCondition(false);
    } else {
      setCondition(true);
    }
  }, []);

  React.useEffect(() => {
    window.onscroll = function () {
      if (window.location.pathname !== "/") {
        if (window.pageYOffset < 200 || window.pageYOffset === 0) {
          setCondition(false);
        }
      } else {
        if (window.pageYOffset < 100) {
          setCondition(true);
        } else if (window.pageYOffset > 100) {
          setCondition(false);
        }
      }
    };

    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }

    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const headerColorChange = () => {
    const { color, hoverColor, changeColorOnScroll } = props;

    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color], classes[hoverColor]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(
          classes[changeColorOnScroll.color],
          classes[changeColorOnScroll.hoverColor]
        );
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color], classes[hoverColor]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(
          classes[changeColorOnScroll.color],
          classes[changeColorOnScroll.hoverColor]
        );
    }
  };

  const { color, hoverColor, links, brand, fixed, absolute } = props;
  let paddingStyle = condition === true ? "0.625rem 0" : "0";
  let shadowStyle = condition === true ? "none" : "";
  // let marginTopStyle = ((condition === true ? '' : "-0.3em"))
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes[hoverColor]]: hoverColor,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
  });

  return (
    <AppBar
      style={{ padding: paddingStyle, boxShadow: shadowStyle }}
      className={appBarClasses}
    >
      <Toolbar
        className={
          condition === true ? classes.containerNew : classes.container
        }
      >
        <Button className={classes.title}>
          <Link to="/">
            <img
              src={logo_eyeflow_mask}
              alt={brand}
              className={classes.eyeflowImage}
            />
          </Link>
        </Button>
        <Hidden
          smDown
          implementation="css"
          className={condition === true ? classes.hiddenNew : classes.hidden}
        >
          <div className={classes.collapse}>{links}</div>
        </Hidden>
        <Hidden mdUp>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          style={{ overflow: "overlay" }}
          open={mobileOpen}
          classes={{ paper: classes.drawerPaper }}
          onClose={handleDrawerToggle}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className={classes.closeButtonDrawer}
          >
            <Close />
          </IconButton>
          <div className={classes.appResponsive}>{links}</div>
        </Drawer>
      </Hidden>
    </AppBar>
  );
}

Header.defaultProp = {
  color: "white",
  hoverColor: "white",
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
  ]),
  hoverColor: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
  ]),
  links: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark",
    ]).isRequired,
    hoverColor: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark",
    ]),
  }),
};
