import React from "react";
import { FormattedMessage } from "react-intl";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import servicesStyle from "assets/jss/material-kit-pro-react/Pages/partnersSections/servicesStyle.js";
const useStyles = makeStyles(servicesStyle);

export default function SectionEyeFlow() {
  const classes = useStyles();
  return (
    <div className={classNames(classes.main, classes.mainRaised, classes.services)} >
      {/* <Parallax style={{ height: 400, overflow: "visible" }} strength={450}>
        <div className={classes.sectionImage} />
        <Background>
            <img
              src={fluid_blue}
              alt=""
              className={classes.imageFluid}
            />
        </Background>
      </Parallax> */}
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.textCenter
          )}
        >
        <h2
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          className={classes.title}
        >
          <span style={{ fontWeight: 700 }}>
            <FormattedMessage
              id="partners.eyeflow.title1"
              defaultMessage="O EyeFlow"
            />
          </span>
          <span style={{ fontWeight: 200 }}>
            <FormattedMessage
              id="partners.eyeflow.title2"
              defaultMessage="pode ajudá-lo. Conheça as vantagens de ser nosso parceiro"
            />
          </span>
        </h2>
        </GridItem>
      </GridContainer>
    </div>
  );
}
