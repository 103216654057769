import {
  container,
  title,
  main,
  mainRaised,
  mrAuto,
  mlAuto,
} from "assets/jss/material-kit-pro-react.js";

const partnersStyle = {
  main,
  mainRaised,
  mrAuto,
  mlAuto,

  container: {
    ...container,
    zIndex: 1,
    minHeight: "calc(100vh - 17.1rem)",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
  },

  title: {
    ...title,
    fontSize: "30px",
    fontFamily: "'Exo 2'",
    "&, & + h4": {
      color: "white",
    },
  },
  title1: {
    ...title,
    "@media (max-width: 480px)": {
      fontSize: "1.5em",
    },
    marginBottom: "0px",
    paddingBottom: "0px",
    fontSize: "2.5em",
    fontFamily: "'Exo 2'",
    "&, & + h4": {
      color: "white",
    },
  },
  title2: {
    ...title,
    "@media (max-width: 480px)": {
      fontSize: "1.2em",
    },
    fontSize: "30px",
    marginTop: "0.3em",
    fontWeight: 400,
    fontFamily: "'Exo 2'",
    "&, & + h4": {
      color: "white",
    },
  },

  textCenter: {
    textAlign: "center",
  },

  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    fontFamily: "'Exo 2'",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
  },

  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },

  socialButtons: {
    margin: "-3vh",
    "& li": {
      display: "inline-block",
    },
  },

  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  left: {
    float: "left!important",
    padding: "15px 0",
  },
  right: {
    padding: "15px 0",
    float: "right!important",
  },
  center: {
    color: "inherit",
    fontFamily: "'Exo 2'",
    fontSize: "0.9em",
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative",
  },
};

export default partnersStyle;
