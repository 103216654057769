import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
// import SignUpPopup from "components/SignUpPopup/SignUpPopup";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/PageFooter.js";

// sections for this page
import HeaderLinks from "components/Header/PageHeaderLinks.js";
import SectionLogos from "./Sections/SectionLogos.js";
import SectionTargets from "./Sections/SectionTargets.js";
import SectionEyeFlow from "./Sections/SectionEyeFlow.js";
import SectionVantagens from "./Sections/SectionVantagens.js";
import SectionContact from "./Sections/SectionContact.js";
import SectionHPE from "./Sections/SectionHPE.js";

import partnersPageStyle from "assets/jss/material-kit-pro-react/Pages/partnersPageStyle.js";
import { FormattedMessage } from "react-intl";
const useStyles = makeStyles(partnersPageStyle);

export default function PartnersPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        brand="EyeFlow.AI"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="white"
      />
      {/* <SignUpPopup /> */}
      <Parallax image={require("assets/img/partnersBackGround4.jpg")} small>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              style={{
                backgroundColor: "rgb(0 0 0 / 70%)",
                borderRadius: "25px",
              }}
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter,
              )}
            >
              <h1 className={classes.title}>
                <FormattedMessage
                  id="partners.title2"
                  defaultMessage="Ter uma equipe interna especializada em IA e Video Analytics é algo caro e, muitas vezes, difícil de montar e manter."
                />
              </h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div
        style={{ backgroundColor: "white" }}
        className={classNames(classes.main)}
      >
        <div className={classes.container}>
          <SectionLogos />
          <SectionTargets />
        </div>
        <SectionEyeFlow />
        <div className={classes.container}>
          <SectionVantagens />
          <SectionHPE />
          <SectionContact />
        </div>
      </div>
      <Footer />
    </div>
  );
}
