import React from "react";
import { FormattedMessage } from "react-intl";

import { makeStyles } from "@material-ui/core/styles";

import questionStyle from "assets/jss/material-kit-pro-react/Pages/landingPageSections/questionStyle.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import Question1 from "assets/img/logos/Question1.svg";
import Question2 from "assets/img/logos/Question2.svg";
import Question3 from "assets/img/logos/Question3.svg";
import Question4 from "assets/img/logos/Question4.svg";

// import TagManager from "react-gtm-module";

const style = {
  ...questionStyle,
  justifyContentCenter: {
    justifyContent: "center",
  },
};

const useStyles = makeStyles(style);

export default function SectionQuestions() {
  const classes = useStyles();

  // TagManager.dataLayer({
  //   dataLayer: {
  //     path: "/#section_questions",
  //     page: "section_questions",
  //   },
  //   dataLayerName: "PageView",
  // });

  return (
    <div id="section_questions" className={classes.section}>
      <div
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        className={classes.sectionText}
      >
        <h1 className={classes.title}>
          <span style={{ fontWeight: 700 }}>
            <FormattedMessage
              id="land.questions.title"
              defaultMessage="Suas Dúvidas"
            />
          </span>
        </h1>
        <br />
        <h1 className={classes.subTitle}>
          <span style={{ fontWeight: 100 }}>
            <FormattedMessage
              id="land.questions.subtitle1"
              defaultMessage="Bem vindo ao "
            />
          </span>
          <span style={{ fontWeight: 700 }}>
            <FormattedMessage
              id="land.questions.subtitle2"
              defaultMessage="FAQ EyeFlow"
            />
          </span>
        </h1>
        <div style={{ marginTop: "5vh" }} className={classes.sectionCards}>
          <Card profile plain>
            <CardHeader className={classes.cardTitle}>
              <img className={classes.cardIcon} src={Question1} alt="" />
              <FormattedMessage
                id="land.questions.cardTitle1"
                defaultMessage="Para quem o EyeFlow é indicado?"
              />
            </CardHeader>
            <CardBody >
              <h1
                className={classes.cardDescription}
                style={{ fontWeight: 400 }}
              >
                <FormattedMessage
                  id="land.questions.cardBody1"
                  defaultMessage="Desenvolvedores de aplicativos que precisam incorporar análises de vídeo/imagens às suas soluções, empresas que precisam inspecionar processos produtivos, agências digitais que desejam desenvolver produtos disruptivos utilizando video analytics, entusiastas e estudantes de deep learning e suas aplicações. Todos são bem vindos!"
                />
              </h1>
            </CardBody>
          </Card>
          <Card profile plain>
            <CardHeader className={classes.cardTitle}>
              <img className={classes.cardIcon} src={Question2} alt="" />
              <FormattedMessage
                id="land.questions.cardTitle2"
                defaultMessage="Que equipamento é necessário?"
              />
            </CardHeader>
            <CardBody >
              <h1
                className={classes.cardDescription}
                style={{ fontWeight: 400 }}
              >
                <FormattedMessage
                  id="land.questions.cardBody2"
                  defaultMessage="Para o desenvolvimento dos flows, criação dos datasets, treinos e testes, basta ter um computador com acesso a internet e um browser. Para a execução das redes neurais treinadas, é necessário um equipamento com..."
                />
              </h1>
            </CardBody>
          </Card>
        </div>
        <br />
        <div className={classes.sectionCards}>
          <Card profile plain>
            <CardHeader className={classes.cardTitle}>
              <img className={classes.cardIcon} src={Question3} alt="" />
              <FormattedMessage
                id="land.questions.cardTitle3"
                defaultMessage="Tenho que pagar para usar a plataforma?"
              />
            </CardHeader>
            <CardBody >
              <h1
                className={classes.cardDescription}
                style={{ fontWeight: 400 }}
              >
                <FormattedMessage
                  id="land.questions.cardBody3"
                  defaultMessage="A plataforma pode ser utilizada de maneira gratuita para aplicações pessoais ou educacionais. Para obter horas de treinamento, espaço em disco e outras vantagens é necessário realizar uma assinatura. Para uso comercial das detecções em dispositivos de borda, é necessária uma licença do EyeFlow Edge"
                />
              </h1>
            </CardBody>
          </Card>
          <Card profile plain>
            <CardHeader className={classes.cardTitle}>
              <img className={classes.cardIcon} src={Question4} alt="" />
              <FormattedMessage
                id="land.questions.cardTitle4"
                defaultMessage="Já posso utilizar EyeFlow Comercialmente?"
              />
            </CardHeader>
            <CardBody >
              <h1
                className={classes.cardDescription}
                style={{ fontWeight: 400 }}
              >
                <FormattedMessage
                  id="land.questions.cardBody4"
                  defaultMessage="A plataforma na cloud está na fase de "
                />
                <a href="/signup">
                  <FormattedMessage
                    id="land.questions.cardBody4-2"
                    defaultMessage="Beta Fechado "
                  />
                </a>
                <FormattedMessage
                  id="land.questions.cardBody4-3"
                  defaultMessage="mas é possível utilizar a plataforma em sua versão Enterprise. Para isso, entre em contato com "
                />
                <a href="mailto:contact@eyeflow.ai">
                  <FormattedMessage
                    id="land.questions.cardBody4-4"
                    defaultMessage="contact@eyeflow.ai{break}"
                    values={{ break: <br /> }}
                  />
                </a>
                <FormattedMessage
                    id="land.questions.cardBody4-5"
                    defaultMessage="*A fase beta é totalmente gratuita"
                    values={{ break: <br /> }}
                  />
              </h1>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}
