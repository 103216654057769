import React from "react";
import { FormattedMessage } from "react-intl";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { IntlContext } from "../../../intlProviderWrapper";

import vantagensStyle from "assets/jss/material-kit-pro-react/Pages/partnersSections/vantagensStyle.js";
import svgPT from "assets/img/PartnersSvg.svg"
import svgEng from "assets/img/PartnersSvgEng.svg"

const useStyles = makeStyles(vantagensStyle);

export default function SectionVantagens() {
  const classes = useStyles();
  const { locale } = React.useContext(IntlContext);

  const [xSvg1, setXSvg1] = React.useState("")
  const [xSvg2, setXSvg2] = React.useState("")
  const [xSvg3, setXSvg3] = React.useState("")
  const [xSvg4, setXSvg4] = React.useState("")
  React.useEffect(() => {
    if (locale === "en-US") {
      setXSvg1("31")
      setXSvg2("24")
      setXSvg3("27")
      setXSvg4("39")
    } else if (locale === "es-ES") {
      setXSvg1("23")
      setXSvg2("20")
      setXSvg3("17")
      setXSvg4("38")
    } else {
      setXSvg1("24")
      setXSvg2("42")
      setXSvg3("20")
      setXSvg4("33")
    }
  }, [locale] )
  const cardsTitles = [
    {
      image: (
        <svg width="209" height="29" viewBox="0 0 209 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.9918 3.30083C20.2587 1.14765 24.0855 0 27.9981 0H181.002C184.915 0 188.741 1.14765 192.008 3.30084L209 14.5L192.008 25.6992C188.741 27.8523 184.915 29 181.002 29H27.9981C24.0855 29 20.2587 27.8523 16.9918 25.6992L0 14.5L16.9918 3.30083Z" fill="#5BC4F1"/>
        <text  y="20" x={xSvg1} fill="white">
          <FormattedMessage
            id="solution.vantagens.cardTitle1"
            defaultMessage="Suporte especializado"
          />
        </text>
        </svg>
      ),
      description: <FormattedMessage
      id="solution.vantagens.cardDescription1"
      defaultMessage="Equipe técnica especializada em AI com ampla experiência em plataformas de dados de larga escala"
    />
    },
    {
      image: (
        <svg width="366" height="29" viewBox="0 0 366 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.4249 1.93732C29.1079 0.661803 32.0414 0 35.0121 0H330.988C333.959 0 336.892 0.661802 339.575 1.93732L366 14.5L339.575 27.0627C336.892 28.3382 333.959 29 330.988 29H35.0121C32.0414 29 29.1079 28.3382 26.4249 27.0627L0 14.5L26.4249 1.93732Z" fill="#10ABEE"/>
        <text  y="20" x={xSvg2} fill="white" style={{justifyContent:"center"}}>
            <FormattedMessage
              id="solution.vantagens.cardTitle2"
              defaultMessage="Apoio na Prospecção e implementação"
              />
        </text>
        </svg>
      ),
      description: <FormattedMessage id="solution.vantagens.cardDescription2" defaultMessage="Equipe sênior e material de divulagação para apoio comercial, bem como nos processos de design e implementação da solução"/>
    },
    {
      image: (
          <svg width="158" height="29" viewBox="0 0 158 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.36126 7.661C9.15242 2.82487 14.9563 0 21.1013 0H136.899C143.044 0 148.848 2.82487 152.639 7.66099L158 14.5L152.639 21.339C148.848 26.1751 143.044 29 136.899 29H21.1013C14.9563 29 9.15242 26.1751 5.36127 21.339L0 14.5L5.36126 7.661Z" fill="#0085FF"/>
          <text  y="20" x={xSvg3} fill="white">
            <FormattedMessage
              id="solution.vantagens.cardTitle3"
              defaultMessage="Preços em reais"
            />
          </text>
        </svg>
      ),
      description: <FormattedMessage id="solution.vantagens.cardDescription3" defaultMessage="Toda a solução é fornecida com valores em reais e modelos flexíveis de comercialização"/>
    },
    {
      image: (
        <svg width="205" height="29" viewBox="0 0 205 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.4847 4.75205C15.0991 1.68415 19.6859 0 24.4268 0H180.573C185.314 0 189.901 1.68415 193.515 4.75204L205 14.5L193.515 24.248C189.901 27.3158 185.314 29 180.573 29H24.4268C19.6859 29 15.0991 27.3159 11.4847 24.248L0 14.5L11.4847 4.75205Z" fill="#0057A7"/>
          <text  y="20" x={xSvg4} fill="white">
            <FormattedMessage
              id="solution.vantagens.cardTitle4"
              defaultMessage="Alta conectividade"
            />
          </text>
        </svg>
      ),
      description: <FormattedMessage id="solution.vantagens.cardDescription4" defaultMessage="Fácil integração com quaisquer sensores e plataformas de mercado, numa plataforma low code e fácil de operar"/>
    }
  ]
  const renderContainerFluid = (cssClass) => {
    return (
      <GridContainer style={{ paddingLeft: "8.3px" }}>
        {cardsTitles.map((el, index) => {
          return (
            <span key={'key'+index}>
            <GridItem style={{ textAlign:"-webkit-center" }} data-aos="fade-right" md={2} sm={2}>
                <Card style={{ padding: "0px 0px" }} profile plain >
                  <CardHeader className={classes.cardBody}>
                    <h2 className={classes.descriptionCard}>
                      {el.image}
                    </h2>
                  </CardHeader>
                  <CardBody plain>
                    <p className={cssClass.cardDescription}>{el.description}</p>
                  </CardBody>
                </Card>
              </GridItem>
            </span>
          );
        })}
      </GridContainer>
    );
  };

  const renderSvgs = (cssClass) => {
    return (
      <GridContainer>
        {locale === "pt-BR" ? (
          <img src={svgPT} className={cssClass.sectionSvg} alt="" />
        ) : (
          <img src={svgEng} className={cssClass.sectionSvg} alt="" />
        )}
      </GridContainer>
    );
  };

  return (
    <div>
    <div  className={classes.vantagensMobile}>
      {renderContainerFluid(classes)}
    </div>
    <div className={classes.vantagens}>
      {renderSvgs(classes)}
    </div>
    </div>
  );
}
