import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import NewHeader from "components/Header/InspectionHeader.js";
// import SignUpPopup from "components/SignUpPopup/SignUpPopup";
import NewFooter from "components/Footer/InspectionFooter.js";

// sections for this page
import SectionContactInspection from "./Sections/SectionContactInspection.js";
import partnersPageStyle from "assets/jss/material-kit-pro-react/Pages/partnersPageStyle.js";
const useStyles = makeStyles(partnersPageStyle);

export default function PartnersPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();

  return (
    <div style={{ backgroundColor: "white" }}>
      <NewHeader />
      <div className={classes.container}>
        <SectionContactInspection />
      </div>
      <NewFooter />
    </div>
  );
}
