import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// images
import logo_eyeflow from "assets/img/logos/logoEyeFlowNameless32.png";
import logo_eyeflow_edge from "assets/img/logos/LogoEyeflowEdge.png";

import logosStyle from "assets/jss/material-kit-pro-react/Pages/partnersSections/logosStyle.js";

const useStyles = makeStyles(logosStyle);

export default function SectionLogos() {
  const classes = useStyles();
  return (
    <div className={classNames(classes.aboutDescription, classes.textCenter)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          style={{ maxWidth: "max-content" }}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <img
            alt=""
            className={classes.imgEyeflow}
            src={logo_eyeflow}
          />
          <img
            alt=""
            className={classes.imgEdge}
            src={logo_eyeflow_edge}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
