import {
  title,
  grayColor
} from "assets/jss/material-kit-pro-react.js";


const questionStyle = {
  section: {
    padding: "70px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    width: "max",
    background: "#E5E5E5"
  },
  sectionText: {
    "@media (max-width: 480px)": {
      marginLeft: "0",
      marginRight:"0"
    },
    marginLeft: "8.5em",
    width: "74em",
    marginRight:"8.5em"
  },
  title: {
    ...title,
    fontFamily: "'Exo 2'",
    "@media (max-width: 480px)": {
      fontSize: "10vw"
    },
    fontSize: "clamp(1em, 3em + 1vw, 5em)",
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#454545",
    textDecoration: "none"
  },
  subTitle:{
    "@media (max-width: 480px)": {
      width: "60%",
      fontSize: "7vw"
    },
    fontFamily: "'Exo 2'",
    fontSize: "clamp(1em, 2em + 1vw, 4em)",
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#454545",
    textDecoration: "none"
  },
  zoom:{
    width:"100%",
    height:"1000px",
    position:'relative',
    zIndex: "1000",
    backgroundSize:"cover"
  },
  img1:{
    overflow: "hidden",
    position:"absolute",
    transform:"translatex(-50%)",
    zIndex:"100s"
  },
  sectionImage: {
    height: "900px",
    width: "350px",
    marginBottom: "0px",
    marginTop: "400px",
    marginLeft: "-250px",
    overflow: "hidden"
  },
  imageFluid: {
    height: "1100px",
    margin: "-100px -300px 0px",
    marginLeft: "10vw"
  },
  sectionFlow: {
    padding: "20px 0",
    textAlign: "left",
    width: "max"
  },
  flowHeader: {
    fontFamily: "'Exo 2'",
    fontSize: "20px",
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: grayColor[1],
    textDecoration: "none"
  },
  sectionCards: {
    "@media (max-width: 480px)": {
      width: "100%",
      display:"block"
    },
    padding: "0px 0",
    textAlign: "left",
    width: "50%",
    marginTop:"-30px",
    display: "-webkit-box"
  },
  cardTitle: {
    "@media (max-width: 480px)": {
      display: "inline-grid",
      textAlign: "center"
    },
    fontWeight:700,
    fontSize:"20px",
    textAlign:"left",
    fontFamily:"'Exo 2'"
  },
  cardIcon:{
    "@media (max-width: 480px)": {
      placeSelf: "center"
    },
    paddingRight: "1vw",
  },
  cardDescription: {
    "@media (max-width: 480px)": {
      marginLeft: "0",
    },
    textAlign:"left",
    fontFamily: "'Exo 2'",
    fontSize: "20px",
    display: "inline-block",
    position: "relative",
    // marginLeft: "7vh",
    textDecoration: "none",
    color: grayColor[1]
  },
  imgIcon: {
    height: "60px"
  }
};

export default questionStyle;
