import React from "react";
import { FormattedMessage } from "react-intl";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";

import IconButton from "@material-ui/core/IconButton";

import productStyle from "assets/jss/material-kit-pro-react/Pages/landingPageSections/productStyle.js";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import ReactPlayer from "react-player/youtube";
import "assets/css/productVideo.css";
import { IntlContext } from "../../../intlProviderWrapper";

const useStyles = makeStyles(productStyle);

export default function SectionProduct() {
  const classes = useStyles();
  const [videoModal, setVideoModal] = React.useState(false);
  const { locale } = React.useContext(IntlContext);

  return (
    <div className={classes.sectionStyle} id="section_product">
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.section}
        class="product"
      >
        <Grid className="wrapper" item>
          <div className="video-container">
            <div className="video_foreground">
              <ReactPlayer
                className={classes.videoHome}
                muted
                loop
                playing
                url={"https://youtu.be/kCM0Xvon_70&vq=hd1080?modestbranding=1"}
              />
            </div>
          </div>
        </Grid>
        <Grid style={{ zIndex: 99 }} item className={classes.playIcon}>
          <IconButton
            color="inherit"
            aria-label="watch video"
            // href="https://www.youtube.com/watch?v=EIYSpikXZtU&t=3s"
            target="_blank"
            onClick={() => setVideoModal(true)}
          >
            <PlayCircleFilledIcon className={classes.playIconStyle} />
          </IconButton>
        </Grid>

        <Grid item className={classes.wrapperText}>
          <span className={classes.title}>
            <FormattedMessage
              id="land.product.title"
              defaultMessage="EyeFlow"
            />
          </span>
          <span className={classes.secondTitle}>
            <FormattedMessage
              id="land.product.title2"
              defaultMessage="Inteligência Artificial e Video Analytics"
            />
          </span>

          <span
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            className={classes.subTitle}
          >
            <FormattedMessage
              id="land.product.description"
              defaultMessage="A plataforma transforma o complexo em simples ensinando máquinas a entender o mundo real e solucionar problemas. O processo é personalizado de acordo com a sua necessidade."
            />
          </span>
        </Grid>
      </Grid>

      <Dialog
        open={videoModal}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => setVideoModal(false)}
        // fullWidth={true}
        maxWidth="xl"
        scroll="body"
        style={{ backgroundColor: "#000000cc" }}
      >
        <DialogContent style={{ padding: "7px 7px" }}>
          {locale === "pt-BR" ? (
            <div
              style={{
                borderRadius: "20px",
                margin: "auto",
                pointerEvents: "all",
              }}
              className="video-container-modal"
            >
              <div style={{ borderRadius: "20px" }}>
                <ReactPlayer
                  loop
                  controls
                  playing={videoModal}
                  url="https://www.youtube.com/embed/QqMf0tDCTGM"
                />
              </div>
            </div>
          ) : (
            <div
              style={{
                borderRadius: "20px",
                margin: "auto",
                pointerEvents: "all",
              }}
              className="video-container-modal"
            >
              <div style={{ borderRadius: "20px" }}>
                <ReactPlayer
                  loop
                  controls
                  playing={videoModal}
                  url="https://www.youtube.com/embed/FGW1XMCnn60"
                />
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
