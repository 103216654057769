/*eslint-disable*/
import React from "react";

import { injectIntl } from "react-intl";
import { IntlContext } from "../../intlProviderWrapper";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import SignUpPopup from "components/SignUpPopup/SignUpPopup";
import HeaderLinks from "components/Header/PageHeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import PageFooter from "components/Footer/PageFooter.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// sections for this page
import SectionText from "./Sections/SectionText.js";

import blogPostPageStyle from "assets/jss/material-kit-pro-react/Pages/blogPostPageStyle.js";

const useStyles = makeStyles(blogPostPageStyle);

const BlogPostPage = (props) => {
  const [postImg, setPostImg] = React.useState("");
  const [authorInfo, setAuthorInfo] = React.useState("");

  const [postText, setPostText] = React.useState("");
  const [postTitle, setPostTitle] = React.useState("");
  const [postDescription, setPostDescription] = React.useState("");
  const [videoLink, setVideoLink] = React.useState([]);
  const [postDate, setPostDate] = React.useState(
    window.location.href.substring(window.location.href.lastIndexOf("=") + 1),
  );
  const { locale } = React.useContext(IntlContext);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  React.useEffect(() => {
    if (postDate) {
      fetch(
        process.env.PUBLIC_URL +
          "/blogfiles/" +
          postDate +
          "/index-" +
          locale.substring(0, 2) +
          ".md",
        { "Content-Type": "text/plain" },
      )
        .then(function (response) {
          if (response.status !== 200) {
            alert("Fail getting blog text");
          }
          return response.text();
        })
        .then(function (text) {
          setPostImg(
            process.env.PUBLIC_URL +
              "/blogfiles/" +
              postDate +
              "/blog-" +
              postDate +
              ".jpg",
          );
          var lines = text.split("\n");
          setAuthorInfo(lines[4]);
          var header = true;
          const search = lines.includes("Video");

          lines.map(function (line, index) {
            if (line.includes("(Vídeo)")) {
              setVideoLink([
                line.substring(line.indexOf("https")).replaceAll(")", ""),
                index,
              ]);
              // lines[index] = `video${index}`;
            }
          });

          for (var idx = 0; idx < lines.length; idx++) {
            if (lines[idx].trim() === "---") {
              if (header) {
                header = false;
                continue;
              } else {
                setPostText(lines.slice(idx + 1).join("\n"));
                break;
              }
            }

            if (lines[idx].includes("title:")) {
              setPostTitle(
                lines[idx].substring(7, lines[idx].length).replace(/"/g, ""),
              );
            } else if (lines[idx].includes("description:")) {
              setPostDescription(
                lines[idx].substring(13, lines[idx].length).replace(/"/g, ""),
              );
            }
          }
        });
    }
  }, [locale, postDate]);

  const classes = useStyles();
  return (
    <div>
      <Header
        absolute
        brand="EyeFlow.AI"
        color="white"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        {...props}
      />
      <SignUpPopup />
      <Parallax image={postImg} filter="dark">
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem md={8} className={classes.textCenter}>
              <h1 className={classes.title}>{postTitle}</h1>
              <h4 className={classes.subtitle}>{postDescription}</h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.main}>
        <div className={classes.container}>
          <SectionText
            markdown={postText}
            authorInfo={authorInfo}
            videoLink={videoLink}
          />
        </div>
      </div>
      <PageFooter />
    </div>
  );
};

export default injectIntl(BlogPostPage);
