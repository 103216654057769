import React from "react";
import { FormattedMessage } from 'react-intl';
import CookieConsent from "react-cookie-consent";

export default function CookieConsentComp() {

  return (
    <CookieConsent
        flipButtons
        style={{ pointerEvents: "none", background: "rgb(86 181 255 / 80%)", color: "#001838", fontFamily: "'Exo 2'", fontWeight: "400" }}
        buttonStyle={{ color: "#rgb(86 181 255 / 80%)", fontSize: "13px", background: "#fec43c", fontFamily: "'Exo 2'", fontWeight: "600", pointerEvents: "all" }}
        declineButtonStyle={{ color: "#rgb(86 181 255 / 80%)", fontSize: "13px", background: "rgb(255 8 8 / 68%)", fontFamily: "'Exo 2'", fontWeight: "600" }}
        buttonText={<FormattedMessage id="land.cookies_btn1" defaultMessage="Eu aceito."/>}
        declineButtonText={<FormattedMessage id="land.cookies_btn2" defaultMessage="Não aceito."/>}
    >
        <FormattedMessage id="land.cookies" defaultMessage="Esse site usa cookies para melhorar a experiência do usuário ao clicar no botão você concorda com o uso deles."/>
    </CookieConsent>
  );
}
