import { title, whiteColor } from "assets/jss/material-kit-pro-react.js";

const productStyle = {
  sectionStyle: {
    display: "block",
    padding: "-15.25vh",
    "@media (max-width: 480px)": {
      height: "29rem",
    },
    "@media (max-width: 768px)": {
      height: "31rem",
    },
    "@media only screen and (max-width: 768px) and (min-width: 10px)": {
      backgroundColor: "#8080803b",
      paddingTop: "3rem",
      paddingBottom: "1.5em",
    },
  },
  section: {
    textAlign: "left",
    position: "relative",
    marginBottom: "0vh",
  },
  sectionItem: {
    // overflow: "hidden",
    // alignSelf: "center",
    maxWidth: "-webkit-fill-available",
    "@media (max-width: 768px)": {
      marginBottom: "10em",
    },
  },
  playIcon: {
    position: "absolute",
    alignSelf: "center",
    justifySelf: "center",
    // textAlign: "center",
    // marginLeft: "auto",
    // marginRight: "auto",
    color: "rgba(255, 255, 255, 0.54)",
    // right: "10%",
    // left: "10%",
    // top: "36%",
  },
  playIconStyle: {
    color: "steelblue",
    fontSize: "6rem",
    "@media (max-width: 1000px)": {
      fontSize: "5rem",
    },
    "@media (max-width: 768px)": {
      fontSize: "3.5rem",
    },
  },
  videoProduct: {
    "& iframe": {
      borderRadius: "25px",
      pointerEvents: "none",
    },
  },
  wrapperText: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    // "@media (max-width: 375px)": {
    //   width: "327px",
    //   marginTop: "19.5rem",
    // },
    // "@media (max-width: 424px)": {
    //   width: "364px",
    //   marginTop: "19.5rem",
    // },
    // "@media (max-width: 480px)": {
    //   width: "420px",
    //   marginTop: "21.5rem",
    // },
    // "@media (max-width: 768px)": {
    //   width: "640px",
    //   marginTop: "29.5rem",
    // },
    "@media (min-width: 10px)": {
      marginTop: "15rem",
      gap: "0.3rem",
      width: "23rem",
    },
    "@media (min-width: 480px)": {
      marginTop: "16rem",
      width: "26rem",
    },
    "@media (min-width: 550px)": {
      marginTop: "17.5rem",
      width: "30rem",
    },
    "@media (min-width: 768px)": {
      gap: "2rem",
      marginTop: "20rem",
      width: "53rem",
    },
    "@media (min-width: 1024px)": {
      gap: "2rem",
      marginTop: "22rem",
    },
    "@media (min-width: 1180px)": {
      gap: "2rem",
      marginTop: "28rem",
      width: "64rem",
    },
  },
  title: {
    ...title,
    "@media (max-width: 768px)": {
      color: "rgb(69, 69, 69)",
      fontSize: "15px",
      fontWeight: 700,
    },
    fontWeight: 700,
    color: whiteColor,
    fontSize: "3.5rem",
    fontFamily: "'Exo 2'",
    minHeight: 0,
    marginTop: 0,
    marginBottom: 0,
  },

  secondTitle: {
    "@media (max-width: 768px)": {
      fontSize: "15px",
      fontWeight: 700,
      color: "rgb(69, 69, 69)",
      marginBottom: "0rem",
    },
    fontWeight: 400,
    fontFamily: "'Exo 2'",
    color: whiteColor,
    fontSize: "1.875rem",
    marginBottom: "-0.5rem",
  },

  subTitle: {
    fontWeight: 400,
    fontFamily: "'Exo 2'",
    color: whiteColor,
    fontSize: "1.256rem",
    maxWidth: "55rem",
    "@media (max-width: 768px)": {
      fontSize: "15px",
      fontWeight: 700,
      color: "rgb(69, 69, 69)",
    },
  },

  textStyle: {
    "@media (max-width: 768px)": {
      width: "-webkit-fill-available",
    },
  },
  description: {
    "@media (max-width: 768px)": {
      top: "60%",
      color: "rgb(69, 69, 69)",
      fontSize: "15px",
      marginTop: "15px",
      fontWeight: 400,
    },
    position: "absolute",
    fontFamily: "'Exo 2'",
    fontSize: "1.256em",
    color: whiteColor,
  },
  videoDialog: {
    "@media (max-width: 480px)": {
      width: "390px",
      height: "220px",
      padding: "0px",
    },
    width: "900px",
    height: "594px",
  },
};

export default productStyle;
