const signUpPopUp = theme =>( {
  buttonStyle:{
    textAlign: "center",
    backgroundColor: "#3f51b5",
    color: "white",
    padding: "12px 20px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    float: "right",
    fontFamily: "'Exo 2'"
  },
  eyeflowImage: {
    "@media (max-width: 600px)": {
      maxWidth: "147px"
    },
    maxWidth: "190px",
    width: "auto"
  },
});

export default signUpPopUp;
