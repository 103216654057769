import {
  mrAuto,
  mlAuto,
  title,
  description,
  cardTitle,
  grayColor
} from "assets/jss/material-kit-pro-react.js";

const targetsStyle = {
  mrAuto,
  mlAuto,
  title:{
    ...title,
    fontFamily:"'Exo 2'",
    fontWeight:400,
    fontSize:"25px"
  },
  description: {
    ...description,
    marginBottom: "80px"
  },
  cardTitle,
  cardDescription: {
    color: grayColor[0]
  },
  targets: {
    padding: "80px 0px"
  },
  textCenter: {
    textAlign: "center!important",
    fontFamily: "'Exo 2'"
  },
  picProfile:{
    height: "100%"
  },
  img: {
    width: "100%",
    height: "auto"
  },
  textMuted: {
    color: grayColor[7] + " !important"
  },
  justifyContent: {
    justifyContent: "center!important",
    fontFamily: "'Exo 2'"
  }
};

export default targetsStyle;
