/*eslint-disable*/ 
import React from "react";
import { FormattedMessage } from "react-intl";

import { enUS, itIT, ptBR, esES, deDE, frFR, zhCN } from '@material-ui/core/locale';

import en_US from './translations/en-US.json';
import pt_BR from './translations/pt-BR.json';
import es_ES from './translations/es-ES.json';

export const languages = {
  'en-US': {
    locale: 'en-US',
    label: 'English US',
    muiLocale: enUS,
    messages: en_US
  },
  'pt-BR': {
    locale: 'pt-BR',
    label: 'Português BR',
    muiLocale: ptBR,
    messages: pt_BR
  },
  'es-ES': {
    locale: 'es-ES',
    label: 'Español ES',
    muiLocale: esES,
    messages: es_ES
  },
};

export const loginLanguages = {
  'en-US': {
    locale: 'en-US',
    label: 'English US',
    muiLocale: enUS,
    messages: en_US
  },
  'it-IT': {
    locale: 'it-IT',
    label: 'Italiano IT',
    muiLocale: itIT,
    // messages: it_IT
  },
  'pt-BR': {
    locale: 'pt-BR',
    label: 'Português BR',
    muiLocale: ptBR,
    messages: pt_BR
  },
  'it-IT': {
    locale: 'it-IT',
    label: 'Italiano IT',
    muiLocale: itIT,
    // messages: it_IT
  },
  'es-ES': {
    locale: 'es-ES',
    label: 'Español ES',
    muiLocale: esES,
    messages: es_ES
  },
  'de-DE': {
    locale: 'de-DE',
    label: 'Deutsch',
    muiLocale: deDE,
    // messages: de_DE
  },
  'fr-FR': {
    locale: 'fr-FR',
    label: 'Français',
    muiLocale: frFR,
    // messages: fr_FR
  },
  'zh-CN': {
    locale: 'zh-CN',
    label: '中文',
    muiLocale: zhCN,
    // messages: zh_CN
  },
};
