const partnersStyle = {
  section: {
    textAlign: "left",
    // backgroundColor: "rgb(245 245 245)",
    // paddingInline: "3rem",
    paddingTop: "2rem",
    fontFamily: "'Exo 2'",
    paddingBottom: "5rem",
  },

  logoStyle: {
    paddingInline: "4rem",
    "&:hover,&:focus": {
      transform: "scale(1.1)",
    },
  },

  titleLink: {
    "@media (max-width:1024px)": {
      flexGrow: 0,
      maxWidth: "10rem",
      flexBasis: "10rem",
    },
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
    fontSize: "40px",
    // backgroundColor: "white",
    padding: "1rem",
    paddingBottom: "2.5rem",
  },

  gridSlider: {
    "@media (max-width:1024px)": {
      paddingInline: "0",
    },
  },

  title: {
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
    fontSize: "32px",
    // backgroundColor: "white",
    padding: "1rem",
    paddingBottom: "2.5rem",
    "@media (max-width:396px)": {
      fontSize: "30px",
    },
  },

  description: {
    fontSize: "1.25rem",
    maxWidth: "32.125rem",
    fontWeight: 500,
  },
};

export default partnersStyle;
