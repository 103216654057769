import React from "react";
import { FormattedMessage } from "react-intl";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import treinamentoStyle from "assets/jss/material-kit-pro-react/Pages/landingPageSections/treinamentoStyle.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import fluid_yellow_png from "assets/img/fluid-yellow.png";
import TrainSvgEng from "assets/img/logos/TrainSvgEng.svg";
import TrainSvg from "assets/img/logos/TrainSvg.svg";
import Train1 from "assets/img/train_1.png";
import Train2 from "assets/img/train_2.png";
import Train3 from "assets/img/train_3.png";
import Train4 from "assets/img/train_4.png";
import Train5 from "assets/img/train_5.png";

import { Parallax, Background } from "react-parallax";
import { IntlContext } from "../../../intlProviderWrapper";
import Grid from "@material-ui/core/Grid";

// import TagManager from "react-gtm-module";

const style = {
  ...treinamentoStyle,
  justifyContentCenter: {
    justifyContent: "center",
  },
};

const useStyles = makeStyles(style);

export default function SectionTreinamento() {
  const classes = useStyles();
  const { locale } = React.useContext(IntlContext);

  // TagManager.dataLayer({
  //   dataLayer: {
  //     path: "/#section_training",
  //     page: "section_training",
  //   },
  //   dataLayerName: "PageView",
  // });

  const videos = [
    {
      url: "https://www.youtube.com/embed/RETIqwUt-wY",
      date: (
        <FormattedMessage
          id="land.treinamento.video_title"
          defaultMessage="Primeiros passos no EyeFlow"
        />
      ),
    },
  ];

  const etapas = [
    {
      step: (
        <FormattedMessage
          id="land.treinamento.step1_title"
          defaultMessage="Faça upload de vídeos ou imagens"
        />
      ),
      description: (
        <FormattedMessage
          id="land.treinamento.step1_description"
          defaultMessage="A plataforma facilita o processo de geração de exemplos, extraindo imagens automaticamente dos seus arquivos."
        />
      ),
      icon: Train1,
    },
    {
      step: (
        <FormattedMessage
          id="land.treinamento.step2_title"
          defaultMessage="Identifique o que deseja"
        />
      ),
      description: (
        <FormattedMessage
          id="land.treinamento.step2_description"
          defaultMessage="Crie e marque suas classes de forma rápida e prática. A plataforma aprimora e amplia automaticamente os datasets originais gerando exemplos artificiais que permitem melhores resultados com um número baixo de imagens, redução de tempo e trabalho operacional."
        />
      ),
      icon: Train2,
    },
    {
      step: (
        <FormattedMessage
          id="land.treinamento.step3_title"
          defaultMessage="Crie seu fluxo personalizado"
        />
      ),
      description: (
        <FormattedMessage
          id="land.treinamento.step3_description"
          defaultMessage="Os fluxos personalizados permitem utilizar uma sequência de objetos pré-existentes ou criar o seu próprio, o que possibilita atender às mais diversas necessidades de negócio."
        />
      ),
      icon: Train3,
    },
    {
      step: (
        <FormattedMessage
          id="land.treinamento.step4_title"
          defaultMessage="Acompanhe e entenda os resultados"
        />
      ),
      description: (
        <FormattedMessage
          id="land.treinamento.step4_description"
          defaultMessage="Treine seus datasets, aprimore o fluxo personalizado ajustando os parâmetros, submeta novos vídeos para avaliar os reultados e deixe a rede neural fazer o trabalho."
        />
      ),
      icon: Train4,
    },
    {
      step: (
        <FormattedMessage
          id="land.treinamento.step5_title"
          defaultMessage="Treine e evolua"
        />
      ),
      description: (
        <FormattedMessage
          id="land.treinamento.step5_description"
          defaultMessage="Na fase de treinos e testes, a ferramenta retorna e analisa as informações automaticamente, gerando novos exemplos e aumentando a assertividade dos resultados."
        />
      ),
      icon: Train5,
    },
  ];

  const renderContainerMobile = (cssClass) => {
    return (
      <GridContainer>
        {etapas.map((el, index) => {
          return (
            <GridItem
              md={3}
              sm={3}
              xs={12}
              key={el + "_" + index}
              className={cssClass.cardBody}
            >
              <Card profile plain>
                <CardHeader plain>
                  <img src={el.icon} alt="" className={cssClass.imgIcon} />
                </CardHeader>
                <CardBody plain>
                  <h1
                    className={classes.cardDescription}
                    style={{ fontWeight: 700 }}
                  >
                    {index + 1}. {el.step}
                  </h1>
                  <br />
                  <p
                    className={cssClass.cardDescription}
                    style={{ fontWeight: 400 }}
                  >
                    {el.description}
                  </p>
                </CardBody>
              </Card>
            </GridItem>
          );
        })}
      </GridContainer>
    );
  };

  const renderSvg = () => {
    return (
      <GridContainer>
        {locale === "pt-BR" ? (
          <img src={TrainSvg} className={classes.svgImage} alt="" />
        ) : (
          <img
            style={{ width: "1100px" }}
            src={TrainSvgEng}
            className={classes.svgImage}
            alt=""
          />
        )}
      </GridContainer>
    );
  };

  const renderContainerVideo = (cssClass) => {
    return (
      <GridContainer>
        {videos.map((el, index) => {
          return (
            <GridItem
              md={4}
              sm={3}
              xs={12}
              key={el + "_" + index}
              className={cssClass.sectionCardsVideo}
            >
              <Card profile plain>
                <CardHeader plain>
                  <div className={classes.iframeContainerVideo}>
                    <iframe
                      height="250"
                      src={el.url}
                      frameBorder="0"
                      allow="encrypted-media"
                      allowFullScreen
                      title={el.title}
                    />
                  </div>
                </CardHeader>
                <CardBody plain>
                  <h1 className={classes.cardTitleVideo}>
                    {/* {index + 1}. */}
                    {el.date}
                  </h1>
                  <br />
                  {/* <p className={cssClass.cardDescriptionVideo}>
                    {el.description}
                  </p> */}
                </CardBody>
              </Card>
            </GridItem>
          );
        })}
      </GridContainer>
    );
  };

  return (
    <div id="section_training" className={classes.section}>
      <Grid container direction="column" justify="center" alignItems="center">
        <div
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          className={classes.sectionText}
        >
          <h1 className={classes.title}>
            <span style={{ fontWeight: 100 }}>
              <FormattedMessage
                id="land.treinamento.title"
                defaultMessage="Etapas do "
              />
            </span>
            <span style={{ fontWeight: 700 }}>
              <FormattedMessage
                id="land.treinamento.title2"
                defaultMessage="treinamento"
              />
            </span>
          </h1>
          <br />
          <h1 className={classes.description}>
            <FormattedMessage
              id="land.treinamento.description"
              defaultMessage="Crie aplicações com Ai, treine a rede neural, teste e obtenha os melhores resultados em tempo real"
            />
          </h1>
          <div data-aos="zoom-in" className={classes.sectionCards}>
            {renderContainerMobile(classes)}
          </div>
          <div data-aos="zoom-in">{renderSvg(classes)}</div>

          <div
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            className={classes.sectionTextVideo}
          >
            <div>
              <h1
                className={classes.titleVideo}
                style={{ fontWeight: 100, color: "#454545" }}
              >
                <FormattedMessage
                  id="land.videos.title"
                  defaultMessage="Dê o play e veja o"
                />
              </h1>
              <h1
                className={classes.titleVideo}
                style={{
                  fontWeight: 700,
                  marginTop: "-1.5vh",
                  color: "#454545",
                }}
              >
                <FormattedMessage
                  id="land.videos.title2"
                  defaultMessage="passo a passo"
                />
              </h1>
            </div>
            <div data-aos="flip-up" className={classes.sectionCardsVideo}>
              {renderContainerVideo(classes)}
            </div>
          </div>
        </div>
      </Grid>
      <Parallax style={{ height: 500, overflow: "visible" }} strength={500}>
        <div className={classes.sectionImage}></div>
        <Background>
          <img src={fluid_yellow_png} alt="" className={classes.imageFluid} />
        </Background>
      </Parallax>
    </div>
  );
}
