import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import newFooterStyle from "assets/jss/material-kit-pro-react/components/inspectionFooter.js";
import logo_eyeflow from "assets/img/logos/logoEyeFlowNameless4.png";
import seloA3 from "assets/img/logos/selo.png";
const useStyles = makeStyles(newFooterStyle);

export default function NewFooter() {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <div
        className="modal fade"
        id="policy"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="exampleModalLabel"
                style={{ color: "#001c44" }}
              >
                Privacy Policy
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-dark" style={{ fontSize: "12px" }}>
              <p>
                EyeFlow or (“we” or “our”) respects the privacy of our users
                (“user” or “you”). This Privacy Policy explains how we collect,
                use, disclose and store your information when you visit our
                website https://eyelow.ai, including any other form of media,
                media channel, mobile website or mobile application related to
                or connected to it ( collectively, the “Site”). Please read this
                privacy policy carefully. IF YOU DO NOT AGREE TO THE TERMS OF
                THIS PRIVACY POLICY, DO NOT ACCESS THE SITE.
              </p>
              <p>
                We reserve the right to make any changes to this Privacy Policy
                at any time and for any reason. We will alert you of any changes
                by updating the “Revised” date of this Privacy Policy. Any
                changes or modifications will be effective immediately upon
                posting the updated Privacy Policy on the Site, and you waive
                the right to receive specific notice of each change or
                modification. We encourage you to periodically review this
                Privacy Policy to stay informed of updates. You will be deemed
                to have been informed of, subject to and accepted changes to any
                revised Privacy Policy by your continued use of the Site after
                the date of publication of the revised Privacy Policy.
              </p>
              <p>
                <strong>1. Collection of your information</strong>
                <br />
                We may collect information about you in a number of ways. The
                information we may collect on the Site includes:
                <br />
              </p>
              <p>
                <b>Personal data</b>
                <br />
                Personally identifiable information, such as your name, email
                address, cell phone number, the company you belong to and/or
                represent, and its business segment, information voluntarily
                provided by you when registering on the Site or when you choose
                to participate in various activities related to such as chat,
                FAQ and discussion forums. When purchasing one of our paid
                plans, information regarding financial data is collected to
                enable billing and contract. You are under no obligation to
                provide us with personal information of any kind; however, your
                refusal to do so may prevent you from using certain features of
                the Site.
                <br />
                <b>Derived Data</b>
                <br />
                Information that our servers automatically collect when you
                access the Site, such as your IP address, browser type,
                operating system, access times, and the pages you viewed
                directly before and after accessing the Site (Cookies).
              </p>

              <p>
                <strong>
                  2. Purpose of collecting, storing and using your information
                </strong>
                <br />
                Having accurate information about you allows us to provide you
                with an efficient and personalized experience. Specifically, we
                may use information collected about you through the Site to:
                <br />
                • Send a newsletter;
                <br />
                • Compiling anonymous statistical data and analysis for internal
                use or with third parties;
                <br />
                • Create and manage your account;
                <br />
                • Provide targeted advertising, coupons, newsletters and
                promotions and other information related to our website and
                mobile application to you;
                <br />
                • Email you about your account or order;
                <br />
                • Manage a personal profile about you to make future visits to
                the Site more personalized;
                <br />
                • Increase the efficiency and operation of the Site;
                <br />
                • Notify you of site updates;
                <br />
                • Offer new products, services and/or recommendations to you;
                <br />
                • Perform other business activities as necessary;
                <br />
                • Solicit feedback and contact you regarding your use of the
                Site;
                <br />
                • Provide security and ensure compliance and fraud prevention;
                and
                <br />• Comply with any laws and court orders
              </p>

              <p>
                <strong>3. Disclosure of your information</strong>
                <br />
                We may share information we collect about you in certain
                situations. Your information may be disclosed as follows:
              </p>
              <p>
                <b>By law or to protect rights</b>
                <br />
                If we believe that disclosure of information about you is
                necessary to respond to legal process, to investigate or remedy
                potential violations of our policies, or to protect the rights,
                property, and safety of others, we may share your information as
                permitted or required by any law. , applicable rule or
                regulation. This includes exchanging information with other
                entities to protect against fraud and reduce credit risk.
                <br />
                <b>Third-party service providers</b>
                <br />
                We may share your information with third parties who provide
                services for us or on our behalf, including payment processing,
                data analysis, email delivery, hosting services, customer
                service and marketing assistance.
                <br />
                <b>Marketing communications</b>
                <br />
                With your consent, or given the opportunity to withdraw consent,
                we may share your information with third parties for marketing
                purposes, as permitted by law.
                <br />
                <b>Online Posts</b>
                <br />
                When you post comments, contributions or other content on the
                Site, your posts can be seen by all users and may be publicly
                distributed outside the Site forever.
                <br />
                <b>Other Third Parties</b>
                <br />
                We may share your information with advertisers and investors for
                the purpose of conducting general business analysis. We may also
                share your information with third parties for their marketing
                purposes, as permitted by law.
                <br />
                <b>Sale or bankruptcy</b>
                <br />
                If we reorganize or sell all or part of our assets, undergo a
                merger or are acquired by another entity, we may transfer your
                information to the successor entity. If we close our doors or go
                bankrupt, your information will be an asset transferred or
                acquired by a third party. You acknowledge that such transfers
                may occur and that the transferee may refuse to honor the
                commitments we have made in this Privacy Policy.
                <br />
                We are not responsible for the actions of third parties with
                whom you share personal or confidential data, and we have no
                authority to manage or control third-party requests. If you no
                longer wish to receive correspondence, emails or other
                communications from third parties, you are responsible for
                contacting the third party directly.
              </p>

              <p>
                <strong>4. Tracking Technologies</strong>
                <br />
                Cookies and Web Beacons We may use cookies, web beacons,
                tracking pixels and other tracking technologies on the Site to
                help personalize the Site and improve your experience. When you
                access the Site, your personal information is not collected
                through the use of tracking technology. Most browsers are set to
                accept cookies by default. You can remove or reject cookies, but
                please be aware that doing so may affect the availability and
                functionality of the Site. You cannot refuse web beacons.
                However, they may be rendered ineffective by refusing all
                cookies or by modifying your web browser settings to notify you
                each time a cookie is offered, allowing you to individually
                accept or decline cookies. Website analysis We may also partner
                with selected third-party vendors, such as Google Analytics, to
                enable tracking technologies and remarketing services on the
                Site through the use of first-party cookies and third-party
                cookies, to, among other things, analyze and track users, use of
                Site, determine the popularity of certain content, and better
                understand online activity. By accessing the Site, you consent
                to the collection and use of your information by these third
                party providers. We recommend that you review their privacy
                policy and contact them directly for answers to your questions.
                We do not transfer personal information to these third-party
                providers. However, if you do not want any information collected
                and used by tracking technologies, you can visit the third-party
                provider. You should be aware that getting a new computer,
                installing a new browser, updating an existing browser, or
                deleting or changing your browser's cookie files may also clear
                certain opt-out cookies, plug-ins or settings.
              </p>

              <p>
                <strong>5. Third Party Sites</strong>
                <br />
                The Site may contain links to third party websites and
                applications of interest, including advertisements and external
                services, which are not affiliated with us. After using these
                links to leave the Site, any information you provide to these
                third parties will not be covered by this Privacy Policy and we
                cannot guarantee the security and privacy of your information.
                Before visiting and providing any information to any third party
                websites, you must inform yourself of the privacy policies and
                practices (if any) of the third party responsible for that
                website and you must take the necessary steps to, in your
                discretion, protect the privacy of your information. We are not
                responsible for the content or privacy and security practices
                and policies of third parties, including other websites,
                services or applications that may link to or from the Site.
              </p>

              <p>
                <strong>6. Security of your information</strong>
                <br />
                We use administrative, technical and physical security measures
                to help protect your personal information. Access to data is
                given by authorized professionals, respecting the pillars of
                confidentiality and privacy that govern this policy.
                <br />
                While we have taken reasonable steps to protect the personal
                information you provide to us, please be aware that despite our
                best efforts, no security measures are perfect or impenetrable
                and no method of data transmission can be guaranteed against any
                interception or other misuse. Any information disclosed online
                is vulnerable to interception and misuse by unauthorized third
                parties. Therefore, we cannot guarantee complete security if you
                provide personal information.
              </p>

              <p>
                <strong>7. Policy for minors</strong>
                <br />
                We do not knowingly solicit information or market to persons
                under the age of 18. If you become aware of any data we collect
                from persons under the age of 18, please contact us using the
                contact information provided below.
              </p>

              <p>
                <strong>8. Controls for do-not-track features</strong>
                <br />
                Most web browsers and some mobile operating systems include a
                Do-Not-Track (“DNT”) feature or setting that you can enable to
                signal your privacy preference to not have data about your
                online browsing activities monitored and collected. No uniform
                technology standard for DNT signal recognition and
                implementation has been finalized. As such, we currently do not
                respond to DNT browser signals or any other mechanism that
                automatically communicates your choice not to be tracked online.
                If a standard for online tracking is adopted that we must follow
                in the future, we will inform you of this practice in a revised
                version of this Privacy Policy.
              </p>

              <p>
                <strong>9. Options relating to your information</strong>
                <br />
                You may at any time revise or change the information in your
                account or terminate your account using the contact information
                provided. Upon request to close your account, we will deactivate
                or delete your account and information from our active
                databases. However, some information may be retained in our
                files to prevent fraud, troubleshoot issues, assist in any
                investigations, enforce our Terms of Use and/or comply with
                legal requirements.
              </p>

              <p>
                <strong>10. Emails and Communications</strong>
                <br />
                If you no longer wish to receive correspondence, emails or other
                communications from us, you may unsubscribe by contacting us
                using the contact information provided. If you no longer wish to
                receive correspondence, emails or other communications from
                third parties, you are responsible for contacting them directly.
              </p>

              <p>
                <strong>11. Data control</strong>
                <br />
                According to the General Data Protection Law (Law nº 13.709/18),
                “controller” is the one responsible for decisions on the
                processing of personal data – in this case, EyeFlow. Below are
                our data: SILICON LIFE PARTICIPACOES LTDA. Avenida Paulista, nº
                2202, Conj. 145, São Paulo/SP, CEP: 01310-932, Brazil. CNPJ:
                36.418.023/0001-68 Below are the data of the person in charge of
                the data: Name: Roberto Jones Neto Email:
                roberto.jones@eyeflow.ai
              </p>

              <p>
                <strong>12. User rights on the data provided</strong>
                <br />
                The rights of users regarding the data provided are found in
                art. 18 of the General Personal Data Protection Law (Law No.
                13.709/18): Art. 18. The holder of personal data has the right
                to obtain from the controller, in relation to the data of the
                holder processed by him, at any time and upon request: I.
                Confirmation of the existence of treatment; II. Access to data;
                III. Correction of incomplete, inaccurate or outdated data; IV.
                Anonymization, blocking or elimination of unnecessary, excessive
                or treated data in violation of the provisions of this Law; V.
                Data portability to another service or product provider, upon
                express request and observing commercial and industrial secrets,
                in accordance with the regulation of the controlling body; VI.
                Deletion of personal data processed with the consent of the
                holder, except in the cases provided for in art. 16 of this Law;
                VII. Information on the public and private entities with which
                the controller made shared use of data; VIII. Information about
                the possibility of not providing consent and about the
                consequences of denial; IX. Revocation of consent, pursuant to §
                5 of art. 8 of this Law. § 1 The holder of personal data has the
                right to petition in relation to their data against the
                controller before the national authority. § 2 The data subject
                may object to treatment carried out based on one of the
                hypotheses of waiver of consent, in the event of non-compliance
                with the provisions of this Law. § 3 The rights provided for in
                this article shall be exercised upon an express request from the
                holder or a legally constituted representative, to the
                processing agent. § 4 In case of impossibility of immediate
                adoption of the measure referred to in § 3 of this article, the
                controller will send the holder a response in which he can: I.
                Communicate that you are not a data processing agent and
                indicate, whenever possible, the agent; or II. Indicate the
                reasons in fact or in law that prevent the immediate adoption of
                the measure. § 5. The request referred to in § 3 of this article
                will be answered at no cost to the holder, within the deadlines
                and under the terms provided for in the regulation. § 6 The
                person in charge must immediately inform the processing agents
                with whom he has shared use of data of the correction,
                elimination, anonymization or blocking of the data, so that they
                repeat the same procedure, except in cases where this
                communication is demonstrably impossible or involves
                disproportionate effort. § 7 The portability of personal data
                referred to in item V of the caput of this article does not
                include data that have already been anonymized by the
                controller. § 8 The right referred to in § 1 of this article may
                also be exercised before consumer protection bodies.
              </p>

              <p>
                <strong>13. Legislation and Forum</strong>
                <br />
                This Privacy Policy and all consequences that may arise from it,
                as well as all possible disputes related to them, will be
                exclusively regulated by Brazilian legislation. The Central
                Forum of the District of São Paulo/SP is elected to resolve any
                issue related to these Policies, the parties waiving any other,
                however privileged it may be or will be.
              </p>

              <p>
                <strong>CONTACT US</strong>
                <br />
                If you have questions or comments about this Privacy Policy,
                please contact us at: contact@siliconlif.ai
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="terms"
        tabIndex="-1"
        aria-labelledby="termsLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="termsLabel"
                style={{ color: "#001c44" }}
              >
                Terms and Conditions of use
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-dark" style={{ fontSize: "12px" }}>
              <p>
                <b>1. Introduction</b>
                <br />
                EyeFlow is a video analytics platform and artificial
                intelligence models that drive business solutions by optimizing
                organizations' performance, cost and efficiency, teaching
                machines to understand the real world and solve problems. The
                user uploads his videos or images, identifies and marks which
                features he wants to detect, trains the neural network and
                starts using the application according to his needs. The EyeFlow
                platform is suitable for application developers that need to
                incorporate video/image analytics into their solutions,
                companies that need to inspect production processes, digital
                agencies that want to develop disruptive products using video
                analytics, enthusiasts and students of deep learning and its
                applications. The EyeFlow platform has the “EyeFlow Edge”
                version, intended for the execution of models in edge computing,
                through an endpoint or on a local device. The platform can be
                used free of charge for personal or educational applications.
                For hours of training, disk space and other benefits, a
                subscription is required. For commercial use of detections on
                edge devices, an EyeFlow Edge license is required. The EyeFlow
                platform in its cloud version is in the Closed Beta phase, but
                it is possible to use the platform in its Enterprise version.
                For this, contact contact@eyeflow.ai.
              </p>

              <p>
                <b>2. Agreeing to the Terms of Use</b>
                <br />
                These Terms and Conditions of Use constitute a legally binding
                agreement made between you, personally or on behalf of an entity
                ("you") and EyeFlow or ("we" or "our"), in connection with your
                access to and use of the website. https://eyeflow.ai, as well as
                any other form of media, media channel, mobile site or mobile
                application related to, linked to or otherwise connected to them
                (collectively, the “Site”). You agree that by accessing the
                Site, you have read, understood and agree to abide by all of
                these Terms and Conditions of Use. IF YOU DO NOT AGREE TO ALL OF
                THESE TERMS AND CONDITIONS OF USE, THEN YOU ARE EXPRESSLY
                PROHIBITED FROM USING THE SITE AND SHOULD DISCONTINUE USE
                IMMEDIATELY. The information provided on the Site is not
                intended for distribution or use by any person or entity in any
                jurisdiction or country where such distribution or use would be
                contrary to law or regulation or would subject us to any
                registration requirement within such jurisdiction or country.
                Consequently, persons who choose to access the Site from other
                locations do so on their own initiative and are solely
                responsible for compliance with local laws, if and to the extent
                local laws apply. Any third party software that may have any
                kind of relationship with EyeFlow have separate Terms of Use and
                Privacy Policies. You declare that you are aware of this fact
                and undertake to read, understand and agree to such terms and
                policies in accordance with the software that you use.
              </p>

              <p>
                <b>3. Changes to the Terms of Us</b>
                <br />
                Supplemental terms and conditions or documents which may be
                posted on the Site from time to time are expressly incorporated
                herein by reference. We reserve the right, in our sole
                discretion, to make changes or modifications to these Terms and
                Conditions of Use at any time and for any reason. We will notify
                you of any changes by updating the “Last Updated” date of these
                Terms and Conditions of Use, and you waive any right to receive
                specific notice of each change. It is your responsibility to
                periodically review these Terms and Conditions of Use to stay
                informed of updates. You will be subject to, and will be deemed
                to have been informed of and accepted by, changes to any revised
                Terms and Conditions of Use for your continued use of the Site
                after the date such revised Terms and Conditions of Use are
                posted.
              </p>

              <p>
                <b>4. Civilian capacity of users</b>
                <br />
                The Site is intended for individual users aged 18 (eighteen)
                years or older and legal representatives of companies that use
                the platform. All platform users declare knowledge and agreement
                with the fact that, if the provision of this clause is not
                complied with, EyeFlow may cancel the registration and access of
                the respective user.
              </p>

              <p>
                <b>5. Intellectual Property Right</b>
                <br />
                All source code, databases, functionality, software, website
                designs, audio, video, text, photographs, videos and graphics on
                the Site (collectively, the “Content”) and the trademarks,
                brands and logos contained therein ( the "Marks") are owned or
                controlled by or licensed to us, and are protected by copyright
                and trademark laws and various other intellectual property
                rights of Brazil, foreign jurisdictions and international
                conventions. The Content and Marks are provided on the Site "AS
                IS" for your information and personal use only. Except as
                expressly provided in these Terms and Conditions of Use, no part
                of the Site and no Content or Marks may be copied, reproduced,
                aggregated, republished, uploaded, posted, publicly displayed,
                encoded, translated, transmitted, distributed, sold, licensed or
                otherwise otherwise exploited for any commercial purpose without
                our express prior written permission. Provided that you are
                eligible to use the Site, you are granted a limited license to
                access and use the Site and to download or print a copy of any
                part of the Content to which you have gained adequate access. We
                reserve all rights not expressly granted to you in and to the
                Site, the Content and the Marks.
              </p>

              <p>
                <b>6. User Representations</b>
                <br />
                By using the Site, you represent and warrant that: (1) all
                registration information submitted will be true, accurate,
                current and complete; (2) you will maintain the accuracy of such
                information and promptly update such registration information as
                necessary; (3) you have legal capacity and agree to be bound by
                these Terms and Conditions of Use; (4) you are not under the age
                of 18; (5) you will not access the Site by automated or
                non-human means, whether via a bot, script or otherwise; (6) you
                will not use the Site for any illegal or unauthorized purpose;
                and (7) your use of the Site will not violate any applicable law
                or regulation. If you provide any information that is false,
                inaccurate, out of date or incomplete, we have the right to
                suspend or terminate your account and refuse any and all current
                or future use of the Site (or any part thereof).
              </p>

              <p>
                <b>7. Registration</b>
                <br />
                To use the platform, the User must register in advance,
                voluntarily providing information about himself, such as: name,
                e-mail, login, password, name of the company to which he
                belongs, cell phone, date of birth and other necessary
                information. The User declares that the information is true and
                undertakes to keep his data always up to date. The user declares
                and agrees that EyeFlow is not responsible for the information
                provided, but that the company may verify, at its discretion,
                the veracity of the information, as well as request the
                documentation that proves the information provided. EyeFlow
                reserves the right to unilaterally cancel any registration made
                from false information, losing the User's right to access or use
                the Site, without the right to compensation or reimbursement.
                The user is solely responsible for maintaining the secrecy of
                his login and password, being responsible for ensuring the
                security of his account. If the user, for any reason, loses his
                access data or suspects undue activities, he must notify EyeFlow
                immediately.
              </p>

              <p>
                <b>8. Access plans</b>
                <br />
                The user must choose between three access plans: (i) Free
                EyeFlow: guarantees user access to the basic functionalities of
                the EyeFlow Platform (currently in Beta version): creation of
                datasets, image tagging, training of neural networks,
                development of flows, testing of training videos and use of the
                network neural in personal solutions, among others. The free
                mode caters to personal or educational applications. (ii)
                EyeFlow Edge: intended for the execution of models in edge
                computing, through an endpoint or on a local device. For
                commercial use of detections on edge devices, a Smart Inspectio
                license is required, which can be obtained through our website
                or by emailing contact@eyeflow.ai.
              </p>

              <p>
                <b>9. Cancellations</b>
                <br />
                The EyeFlow platform's free access plan may be canceled at any
                time by the user, without prior notification and without any
                compensation or inherent fine. The free access plan of the
                EyeFlow platform, in its Beta version, may be canceled at any
                time by EyeFlow, without prior notification and without any
                compensation or inherent fine.
              </p>

              <p>
                <b>10. Prohibited Activities</b>
                <br />
                You may not access or use the Site for any purpose other than
                that for which we make the Site available. The Site may not be
                used in connection with any commercial endeavors, except those
                that are specifically endorsed or approved by us. As a user of
                the Site, you agree not to: a) Systematically retrieve data or
                other content from the Site to create or compile, directly or
                indirectly, a collection, compilation, database or directory
                without our written permission; b) Making any unauthorized use
                of the Site, including collecting usernames and/or email
                addresses from users by electronic or other means for the
                purpose of sending unsolicited emails, or creating user accounts
                by automated means or under false pretenses; c) Use the Site to
                advertise or offer the sale of goods and services other than
                those that are specifically endorsed or approved by us. d)
                Circumvent, disable or otherwise interfere with the
                security-related features of the Site, including features that
                prevent or restrict the use or copying of any Content or impose
                limitations on the use of the Site and/or the Content contained
                therein; e) Engage in unauthorized framing or linking to the
                Site; f) Mislead, defraud or deceive us and other users,
                especially in any attempt to obtain sensitive account
                information such as user passwords; g) Engage in any automated
                use of the system, such as using scripts to send comments or
                messages, or using data mining, robots, or similar data
                collection and extraction tools; h) Misuse our support services
                or submit false reports of abuse or misconduct; i) Interfere
                with, disrupt or create an undue load on the Site or the
                networks or services connected to the Site; j) Attempt to
                impersonate another user or person or use another user's
                username; k) Sell or transfer your profile; l) Use any
                information obtained on the Site to harass, abuse or harm
                another person; m) Decipher, decompile, disassemble or reverse
                engineer any software that includes or otherwise forms part of
                the Site; n) Copy or adapt the Site's software, including but
                not limited to Flash, React, HTML, JavaScript or other code; o)
                Delete the copyright or other proprietary rights notice from any
                Content; p) Harass, harass, intimidate or threaten any of our
                employees or agents involved in providing any part of the Site
                to you; q) Attempt to circumvent any measures on the Site
                designed to prevent or restrict access to the Site or any part
                of the Site; r) Upload or transmit (or attempt to upload or
                transmit) viruses, Trojan horses or other material, including
                excessive use of capital letters and spam (continuous posting of
                repetitive text), that interferes with your uninterrupted use of
                any part and enjoyment of the Site or modifies , impairs,
                interrupts, alters or interferes with the use, features,
                functions, operation or maintenance of the Site; s) Upload or
                transmit (or attempt to upload or transmit) any material that
                acts as a passive or active transmission or information
                collection mechanism, including, without limitation, clear
                graphics interchange formats ("gifs"), 1×1 pixels , web bugs,
                cookies, or other similar devices (sometimes called “spyware” or
                “passive collection mechanisms”); and t) Use the Site in a
                manner inconsistent with any applicable laws or regulations. If
                you take any action referred to in this clause, we have the
                right to suspend or terminate your account and refuse any and
                all current or future use of the Site (or any part thereof).
              </p>

              <p>
                <b>11. User Generated Contributions</b>
                <br />
                The Site may invite you to chat, contribute to or participate in
                blogs, FAQs, message boards, online forums and other
                functionality, and may provide you with the opportunity to
                create, submit, post, display, broadcast, perform, publish,
                distribute, or transmit content and materials to us or on the
                Site, including, but not limited to, text, writings, video,
                audio, photographs, graphics, comments, suggestions, or personal
                information or other material (collectively, "Contributions").
                Contributions may be viewed by other users of the Site and
                through third-party websites. As such, any contributions you
                transmit may be treated as non-confidential and non-proprietary.
                By creating or making available any Contributions, you represent
                and warrant that: a) The creation, distribution, transmission,
                public display or performance and access, downloading or copying
                of your contributions do not and will not infringe any
                proprietary rights, including but not limited to copyrights,
                patents, trademarks, trade secrets and/or or moral rights of
                third parties; b) You are the creator and owner of or have the
                necessary licenses, rights, consents, releases and permissions
                to use and authorize us, the Site and other users of the Site to
                use your Contributions in any way contemplated by the Site and
                these Terms and Conditions of Use; c) You have the consent,
                release and/or written permission of each individual
                identifiable person in your Contributions to use the name or
                likeness of each individual identifiable person to permit the
                inclusion and use of your Contributions in any manner
                contemplated by the Site and these Terms and Conditions of Use;
                d) Your contributions are not false, inaccurate or misleading;
                e) Your contributions are not unsolicited or unauthorized
                advertising, promotional materials, pyramid schemes, chain
                letters, spam, direct mail or other forms of solicitation; f)
                Your contributions are not obscene, lewd, dirty, violent,
                offensive, libelous or otherwise objectionable (as determined by
                us); g) Your contributions do not ridicule, mock, discredit,
                intimidate or abuse anyone; h) Your contributions do not
                advocate the violent overthrow of any government or incite,
                encourage, or threaten to physically harm another; i) Your
                contributions do not violate any applicable law, regulation or
                rule; j) Your contributions do not violate the privacy or public
                rights of the City or any third party; k) Your contributions do
                not contain any material that solicits personal information from
                anyone under the age of 18 or exploits anyone under the age of
                18 in a sexual or violent manner; l) Your contributions do not
                violate any federal or state law relating to child pornography,
                or otherwise intended to protect the health or well-being of
                minors; m) Your contributions do not include offensive comments
                related to race, nationality, gender, sexual preference or
                physical disability; n) Your contributions do not violate, or
                link to materials that violate, any provision of these Terms and
                Conditions of Use or any applicable law or regulation; and o)
                Any use of the Site in violation of the foregoing violates these
                Terms and Conditions of Use and may result, among other things,
                in the termination or suspension of your rights to use the Site.
                In the event that you violate any condition mentioned in this
                clause, we have the right to suspend or terminate your account
                and refuse any and all current or future use of the Site (or any
                part thereof).
              </p>

              <p>
                <b>12. Contribution License</b>
                <br />
                By posting your contributions on any part of the site, you
                automatically grant, represent and warrant that you have the
                right to grant us a perpetual, non-exclusive worldwide right and
                license to host, use, copy, reproduce, disclose, publish,
                transmit , archive, store, publicly display and distribute such
                Contributions for the sole purpose of enabling us to operate the
                Site. We will not use your contributions for any other purpose,
                such as commercial use, advertising, preparation of derivatives,
                or incorporation into other works. This license will apply to
                any form, media or technology now known or later developed and
                includes the use of your name, company name and franchise name,
                as applicable, and any of the trademarks, service marks, trade
                names, logos , and personal and business images that you
                provide. You waive all moral rights in your contributions and
                warrant that moral rights have not been claimed in your
                contributions. We do not claim any ownership of your
                contributions. You retain full ownership of all your
                contributions and any intellectual property rights or other
                proprietary rights associated with your contributions. We are
                not responsible for any statements or representations in your
                Contributions provided by you in any area of the Site. You are
                solely responsible for your contributions to the site and you
                expressly agree to release us from any and all liability and to
                refrain from any legal action against us in connection with your
                contributions. We have the right, in our sole and absolute
                discretion, (1) to edit, redact or otherwise change any
                Submissions; (2) to reclassify any Contributions to place them
                in more appropriate locations on the Site; and (3) to pre-screen
                or exclude any contributions at any time and for any reason,
                without notice. We are under no obligation to monitor your
                contributions.
              </p>

              <p>
                <b>13. Website Management</b>
                <br />
                We reserve the right, but not the obligation, to: (1) monitor
                the Site for violations of these Terms and Conditions of Use;
                (2) take appropriate legal action against any person who, in our
                sole discretion, violates the law or these Terms and Conditions
                of Use, including, without limitation, reporting such user to
                law enforcement authorities; (3) in our sole discretion and
                without limitation, refuse, restrict access to, limit the
                availability or disable (to the extent technologically feasible)
                any of your Contributions or any portion thereof; (4) in our
                sole discretion and without limitation, notice or liability,
                remove from the Site or otherwise disable all files and content
                that are oversized or otherwise burdensome to our systems; and
                (5) otherwise administer the Site in a manner designed to
                protect our rights and property and to facilitate the proper
                functioning of the Site.
              </p>

              <p>
                <b>14. Privacy Policy</b>
                <br />
                We care about data privacy and security. Read our Privacy
                Policy: https://eyeflow.ai/politica-privacy. By using the Site,
                you agree to abide by our Privacy Policy, which is incorporated
                into these Terms and Conditions of Use. We inform you that the
                site is hosted in Brazil. If you access the Site from the
                European Union, the Americas, Asia or any other region of the
                world with laws or other requirements governing the collection,
                use or disclosure of personal data that differ from applicable
                laws in Brazil, then through your continued use of the Website,
                you are transferring your data to Brazil and you expressly agree
                that your data will be transferred and processed in Brazil. In
                addition, we do not accept or solicit information from minors or
                knowingly market to minors. Therefore, if we receive actual
                knowledge that anyone under the age of 18 has provided us with
                personal information without the required and verifiable
                parental consent, we will delete that information from the Site
                as quickly as is reasonably practicable.
              </p>

              <p>
                <b>15. Term and termination</b>
                <br />
                These Terms and Conditions of Use will remain in full force and
                effect while you use the Site. WITHOUT LIMITING ANY OTHER
                PROVISION OF THESE TERMS AND CONDITIONS OF USE, WE RESERVE THE
                RIGHT, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY,
                TO DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING
                CERTAIN IP ADDRESSES), TO ANYONE OR ANYONE WITHOUT REASON,
                INCLUDING, WITHOUT LIMITATION, BREACH OF ANY REPRESENTATION,
                WARRANTY OR ALLIANCE CONTAINED IN THESE TERMS AND CONDITIONS OF
                USE OR ANY APPLICABLE LAW OR REGULATIONS. WE MAY TERMINATE YOUR
                USE OF OR PARTICIPATION IN THE SITE OR DELETE YOUR ACCOUNT AND
                ANY CONTENT OR INFORMATION YOU POST AT ANY TIME, WITHOUT NOTICE,
                IN OUR JUDGMENT. If we terminate or suspend your account for any
                reason, you are prohibited from registering and creating a new
                account in your name, a false or borrowed name, or the name of a
                third party, even if you are acting on behalf of a third party.
                In addition to terminating or suspending your account, we
                reserve the right to take appropriate legal action, including,
                without limitation, civil, criminal and injunctive actions.
              </p>

              <p>
                <b>16. Modifications and interruptions</b>
                <br />
                We reserve the right to change, modify or remove the content of
                the Site at any time or for any reason, in our sole discretion,
                without notice. However, we are under no obligation to update
                any information on our Site. We also reserve the right to modify
                or discontinue all or part of the Site without notice at any
                time. We will not be liable to you or any third party for any
                modification, price change, suspension or discontinuation of the
                Site. We cannot guarantee that the Site will always be
                available. We may experience hardware, software or other
                problems or need to perform maintenance related to the Site,
                resulting in interruptions, delays or errors. We reserve the
                right to change, revise, update, suspend, discontinue or modify
                the Site at any time or for any reason without notice. You agree
                that we have no liability for any loss, damage or inconvenience
                caused by your inability to access or use the Site during any
                downtime or interruption of the Site. Nothing in these Terms and
                Conditions of Use will be construed to oblige us to maintain and
                support the Site or to provide any corrections, updates or
                releases with respect thereto.
              </p>

              <p>
                <b>17. Applicable law</b>
                <br />
                These Terms and Conditions of Use and your use of the Site are
                governed by and construed in accordance with the laws of Brazil.
              </p>

              <p>
                <b>18. Dispute Resolution</b>
                <br />
                The Parties declare and agree that the competent forum for
                opening and conducting any legal action regarding matters
                related to this Site or to the Services provided by EyeFlow is
                São Paulo/SP, the Parties waiving any other forum, however
                advantageous whatever.
              </p>

              <p>
                <b>19. Corrections</b>
                <br />
                There may be information on the Site that contains typographical
                errors, inaccuracies or omissions, including descriptions,
                pricing, availability and various other information. We reserve
                the right to correct any errors, inaccuracies or omissions and
                to change or update information on the Site at any time without
                notice.
              </p>

              <p>
                <b>20. Legal notice</b>
                <br />
                THE SITE IS PROVIDED AS IS AND AS AVAILABLE. YOU AGREE THAT YOUR
                USE OF THE SITE AND OUR SERVICES IS AT YOUR OWN RISK. TO THE
                FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
                EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND USE THEREOF,
                INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
                MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE
                INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
                ACCURACY OR COMPLETENESS OF THE CONTENT ON THE SITE OR THE
                CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE ASSUME NO
                RESPONSIBILITY FOR ANY (1) ERRORS, FAILURES, INTERRUPTIONS AND
                MALFUNCTIONS, (2) PERSONAL INJURY OR DAMAGE PROPERTY OF ANY KIND
                ARISING OUT OF YOUR ACCESS TO AND USE OF THE SITE, (3)
                UNAUTHORIZED ACCESS, (4) ANY AND ALL PERSONAL AND/OR FINANCIAL
                INFORMATION STORED HEREIN, (5) ANY INTERRUPTION OR TERMINATION
                OF TRANSMISSION OF OR TO THE SITE, (6) ANY BUGS, VIRUSES, TROJAN
                HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH THE
                SITE BY ANY THIRD PARTY, (7) ANY ERRORS OR OMISSIONS IN ANY
                CONTENT AND MATERIAL MADE AVAILABLE ON THE SITE, AND/OR (8) )
                FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE
                USE OF ANY CONTENT POSTED, TRANSMITTED OR OTHERWISE MADE
                AVAILABLE VIA THE SITE. WE DO NOT WARRANT, ENDORSE OR ASSUME
                RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED
                BY THIRD PARTIES THROUGH THE SITE, ANY LINKED SITE OR ANY SITE
                OR MOBILE APPLICATION ADVERTISED OR OFFERED BY THIRD PARTIES
                THROUGH THE SITE, OR ANY OTHER PUBLICITY OR ANY OTHER PUBLICITY.
                IN ANY WAY YOU ARE RESPONSIBLE FOR MONITORING ANY TRANSACTION
                BETWEEN YOU AND ANY THIRD-PARTY PRODUCT OR SERVICE PROVIDERS. AS
                WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR
                IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND USE
                APPROPRIATE CARE.
              </p>

              <p>
                <b>21. Limitations of Liability</b>
                <br />
                IN NO EVENT SHALL WE OR OUR SUBSIDIARIES, AFFILIATES AND ALL OF
                OUR RESPECTIVE DIRECTORS, AGENTS, PARTNERS AND EMPLOYEES BE
                LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
                CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE,
                CONSEQUENTIAL DAMAGES. OF INCOME OR OTHER DAMAGES ARISING OUT OF
                YOUR USE OF THE SITE. NOTWITHSTANDING, OUR LIABILITY FOR ANY
                CAUSE AND REGARDLESS OF THE FORM OF ACTION SHALL AT ALL TIMES BE
                LIMITED TO THE AMOUNT PAID BY USER TO EYEFLOW, IF ANY, DURING
                THE SIX (6) MONTHS PRIOR TO ANY CAUSE OF ACTION ARISING.
              </p>

              <p>
                <b>22. Indemnity</b>
                <br />
                You agree to defend, indemnify and hold us harmless, including
                our subsidiaries, affiliates and all of our respective
                directors, agents, partners and employees, from and against any
                loss, damage, liability, claim or demand, including attorneys,
                fees and expenses. reasonable, made by third parties due to or
                arising out of: (1) your contributions; (2) use of the Site; (3)
                breach of these Terms and Conditions of Use; (4) any breach of
                your representations and warranties set forth in these Terms and
                Conditions of Use; (5) your violation of third party rights,
                including but not limited to intellectual property rights; or
                (6) any act evidently harmful to any other user of the Site with
                whom you have connected through the Site. Notwithstanding the
                foregoing, we reserve the right, at your expense, to assume the
                exclusive defense and control of any matter for which you are
                required to indemnify us, and you agree to cooperate, at your
                expense, with our defense of such claims. We will use reasonable
                efforts to notify you of any claim, action or proceeding that is
                subject to such indemnification as soon as we become aware of
                it.
              </p>

              <p>
                <b>23. User Data</b>
                <br />
                We will retain certain data that you transmit to the Site for
                the purpose of managing the performance of the Site, as well as
                data relating to your use of the Site. Although we do routine
                data backups on a regular basis, you are solely responsible for
                all data that you transmit or that relates to any activity you
                have performed using the Site. You agree that we will have no
                liability to you for any loss or corruption of such data, and
                you waive any right of action against us arising from any loss
                or corruption of such data.
              </p>

              <p>
                <b>
                  24. Electronic communications, transactions and signatures
                </b>
                <br />
                Visiting the Site, sending emails and completing online forms
                constitute electronic communications. You consent to receive
                electronic communications and agree that all agreements,
                notices, disclosures and other communications that we provide to
                you electronically, via email and on the Site, meet any legal
                requirement that such communication be in writing. YOU AGREE TO
                THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS AND OTHER
                RECORDS AND THE ELECTRONIC DELIVERY OF NOTICES, POLICIES AND
                RECORDS OF TRANSACTIONS INITIATE OR COMPLETED BY US OR VIA THE
                SITE. You hereby waive any rights or requirements under any
                statutes, regulations, rules, ordinances or other laws in any
                jurisdiction that require an original signature or delivery or
                retention of non-electronic records, or payments or credits for
                any other medium than electronic media.
              </p>

              <p>
                <b>25. Others</b>
                <br />
                These Terms and Conditions of Use and any policies or operating
                rules posted by us on the Site or in connection with the Site
                constitute the entire agreement and understanding between you
                and us. Our failure to exercise or enforce any right or
                provision of these Terms and Conditions of Use shall not act as
                a waiver of such right or provision. These Terms and Conditions
                of Use operate to the fullest extent permitted by law. We may
                assign any or all of our rights and obligations to third parties
                at any time. We will not be liable for any loss, damage, delay
                or failure to act caused by any cause beyond our reasonable
                control. If any provision or part of a provision of these Terms
                and Conditions of Use is determined to be illegal, void or
                unenforceable, that provision or part of the provision is deemed
                severable from these Terms and Conditions of Use and does not
                affect the validity and enforceability of any provisions. There
                is no joint venture, partnership, employment or agency
                relationship created between you and us as a result of these
                Terms and Conditions of Use of the Site. You agree that these
                Terms and Conditions of Use will not be construed against us for
                making them. You hereby waive any and all defenses you may have
                based on the electronic form of these Terms and Conditions of
                Use and the parties' failure to execute these Terms and
                Conditions of Use.
              </p>

              <p>
                <strong>CONTACT US</strong>
                <br />
                To resolve a complaint about the Site or to receive more
                information about your use of the Site, please contact us at:
                contact@siliconlif.ai
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-4" style={{ backgroundColor: "#d5effa" }}>
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <p
                className="footerTopP"
                style={{
                  color: "#5a7196",
                  fontWeight: 400,
                  lineHeight: "21px",
                  fontSize: "14px",
                }}
              >
                EyeFlow Inspection - Copyright - SiliconLife
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="footer-bottom py-4"
        style={{ backgroundColor: "#869ec1" }}
      >
        <div className="container">
          <ul className="nav justify-content-center">
            <li className="nav-item me-3">
              <button
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className={`${classes.navItem} nav-link p-0 text-white`}
                data-bs-toggle="modal"
                data-bs-target="#policy"
                style={{
                  background: "none",
                  backgroundColor: "transparent",
                  border: "none",
                  fontWeight: 400,
                }}
              >
                Privacy Policy
              </button>
            </li>
            <li className="nav-item me-3" style={{ fontSize: "14px" }}>
              <button
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className={`${classes.navItem} nav-link p-0 text-white`}
                data-bs-toggle="modal"
                data-bs-target="#terms"
                style={{
                  background: "none",
                  backgroundColor: "transparent",
                  border: "none",
                  fontWeight: 400,
                }}
              >
                Terms and conditions
              </button>
            </li>
            <li className="nav-item me-3">
              <a
                className="nav-link p-0 text-white"
                href="https://www.linkedin.com/company/siliconlife/about/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="bi bi-linkedin"></i>
              </a>
            </li>
            <li className="nav-item me-3">
              <a
                className="nav-link p-0 text-white"
                href="https://www.youtube.com/channel/UCv_losmhW1ECaHtcjRHqjxg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="bi bi-youtube"></i>
              </a>
            </li>
            <li className="nav-item me-3">
              <a
                href="https://www.eyeflow.ai"
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link p-0 text-white"
              >
                <img
                  alt="logo do site EyeFlow"
                  src={logo_eyeflow}
                  className="img-fluid"
                  style={{
                    maxHeight: "40px",
                  }}
                />
              </a>
            </li>
            <li className="nav-item me-3">
              <a
                href="https://www.automate.org/?utm_campaign=A3%20Membership&utm_medium=email&_hsmi=202242389&_hsenc=p2ANqtz-9aC_ZRfoPdWCP3yCF_TIyoI5Nxa8yatJdncHu6Ip47eYKq4sS3UpUx3ldK4r8XnJvCk2hjw9Htst6xo2HwIuIyrWrJw0ltjAdvAayKUvCuCpPO3sg&utm_content=202242389&utm_source=hs_email"
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link p-0 text-white"
              >
                <img
                  alt="selo de parceria A3"
                  className="img-fluid"
                  src={seloA3}
                  style={{
                    maxHeight: "40px",
                  }}
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
