import {
  container,
  title,
  main,
  mainRaised,
  mlAuto,
  description
} from "assets/jss/material-kit-pro-react.js";

const contactUsStyle = {
  main: {
    ...main,
    fontFamily: "'Exo 2'"
  },
  mlAuto: {
    ...mlAuto,
    fontFamily: "'Exo 2'"
  },
  mainRaised: {
    ...mainRaised,
    margin: "-450px 30px 0px"
  },
  thanksMessage: {
    backgroundColor: "lightblue",
    width: "-webkit-fill-available",
    textAlign: "center",
    padding: "6em 1em",
    borderRadius: "25px",
    fontFamily: "'Exo 2'",
    fontWeight: "500",
    fontSize: "2em",
    lineHeight: "1em",
    "& img": {
      width: "5em"
    }
  },
  textTitle: {
    "@media (max-width: 1022px)": {
      whiteSpace: "break-spaces"
    },
    fontFamily: "'Exo 2'",
    whiteSpace: "nowrap"
  },
  textTitle2: {
    "@media (max-width: 783px)": {
      whiteSpace: "break-spaces"
    },
    fontFamily: "'Exo 2'",
    whiteSpace: "nowrap"
  },
  imageFluidYellow: {
    height: "1100px",
    margin: "-1300px 0px 0px -800px",
    WebkitTransform: "rotate(-90deg)",
    MozTransform: "rotate(-90deg)",
    MsTransform: "rotate(-90deg)",
    OTransform: "rotate(-90deg)",
    transform: "rotate(-90deg)"
  },
  imageFluidBlue: {
    height: "1100px",
    margin: "-550px 0px 0px 1310px",
  },
  sectionImageYellowBottom: {
    "@media (max-width: 480px)": {
      display: "none"
    }
  },
  imageFluidYellowBottom: {
    height: "1100px",
    margin: "-550px 0px -600px 1310px",
    WebkitTransform: "rotate(-90deg)",
    MozTransform: "rotate(-90deg)",
    MsTransform: "rotate(-90deg)",
    OTransform: "rotate(-90deg)",
    transform: "rotate(-90deg)"
  },
  imageFluidBlueBottom: {
    margin: "-700px 0px -1100px -850px",
    height: "1100px",
  },
  // root:{
  //   backgroundImage: `url(${background})`
  // },
  title: {
    ...title,
    fontFamily: "'Exo 2'",
    fontSize: "2em"
  },
  description: {
    ...description,
    fontFamily: "'Exo 2'"
  },
  container: {
    ...container,
    maxWidth: "970px !important"
  },
  contactContent: {
    padding: "50px 0.5em"
  },
  bigMap: {
    height: "55vh",
    maxHeight: "550px",
    width: "100%",
    display: "block"
  },
  info: {
    paddingBottom: "10px",
    paddingTop: 0,
    fontFamily: "'Exo 2'",
    maxWidth: "600px !important"
  },
  textCenter: {
    textAlign: "center !important"
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right"
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative"
  }
};

export default contactUsStyle;
