import React from "react";

// core components
import Header from "components/Header/Header.js";
import SignUpPopup from "components/SignUpPopup/SignUpPopup";
import HeaderLinks from "components/Header/PageHeaderLinks.js";
import PageFooter from "components/Footer/PageFooter.js";
// import FloatingButton from "components/FloatingButton/FloatingButton.js"

export default function TermsandConditions() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  return (
    <div>
      {/* <FloatingButton/> */}
      <Header
        brand="EyeFlow.AI"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
      />
      <SignUpPopup />
      <div
        style={{
          marginTop: "80px",
          marginRight: "30px",
          marginLeft: "35px",
          fontFamily: "'Exo 2'",
          fontWeight: 500,
        }}
      >
        <div style={{ marginTop: "20px" }}>
          <div style={{ textAlign: "center" }}>
            <p style={{ textAlign: "center", lineHeight: "normal" }}>
              <strong>
                <span
                  style={{
                    textAlign: "center",
                    fontSize: "13px",
                    color: "#415071",
                  }}
                >
                  TERMOS E CONDI&Ccedil;&Otilde;ES DE USO
                </span>
              </strong>
            </p>
            <ol style={{ listStyleType: "none", textAlign: "left" }}>
              <li>
                <strong>
                  <span>1. Introdu&ccedil;&atilde;o</span>
                </strong>
              </li>
            </ol>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                EyeFlow &eacute; uma plataforma de an&aacute;lise de
                v&iacute;deo e modelos de intelig&ecirc;ncia artificial que
                impulsionam as solu&ccedil;&otilde;es de neg&oacute;cio ao
                otimizar o desempenho, o custo e a efici&ecirc;ncia das
                organiza&ccedil;&otilde;es, ensinando m&aacute;quinas a entender
                o mundo real e solucionar problemas. O usu&aacute;rio faz o
                upload de seus v&iacute;deos ou imagens, identifica e marca
                quais funcionalidades deseja detectar, treina a rede neural e
                passa a utilizar a aplica&ccedil;&atilde;o de acordo com sua
                necessidade.
              </span>
            </p>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                A plataforma EyeFlow &eacute; indicada para desenvolvedores de
                aplicativos que precisam incorporar an&aacute;lises de
                v&iacute;deo/imagens &agrave;s suas solu&ccedil;&otilde;es,
                empresas que precisam inspecionar processos produtivos,
                ag&ecirc;ncias digitais que desejam desenvolver produtos
                disruptivos utilizando video analytics, entusiastas e estudantes
                de deep learning e suas aplica&ccedil;&otilde;es.
              </span>
            </p>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                A plataforma EyeFlow possui a vers&atilde;o &ldquo;EyeFlow
                Edge&rdquo;, destinada &agrave; execu&ccedil;&atilde;o dos
                modelos em computa&ccedil;&atilde;o de borda, atrav&eacute;s de
                um end-point ou em um dispositivo local.
              </span>
            </p>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                A plataforma pode ser utilizada de maneira gratuita para
                aplica&ccedil;&otilde;es pessoais ou educacionais. Para obter
                horas de treinamento, espa&ccedil;o em disco e outras vantagens
                &eacute; necess&aacute;rio realizar uma assinatura. Para uso
                comercial das detec&ccedil;&otilde;es em dispositivos de borda,
                &eacute; necess&aacute;ria uma licen&ccedil;a EyeFlow Edge.
              </span>
            </p>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                A plataforma EyeFlow em sua vers&atilde;o cloud est&aacute; na
                fase de Beta Fechado, mas &eacute; poss&iacute;vel utilizar a
                plataforma em sua vers&atilde;o Enterprise. Para isso, entre em
                contato com&nbsp;
              </span>
              <a href="mailto:contact@eyeflow.ai">
                <span style={{ fontSize: "13px" }}>contact@eyeflow.ai</span>
              </a>
              <span style={{ fontSize: "13px", color: "#415071" }}>.</span>
            </p>
            <ol style={{ listStyleType: "none", textAlign: "left" }}>
              <li>
                <strong>
                  <span>2. Concord&acirc;ncia com os Termos de Uso&nbsp;</span>
                </strong>
              </li>
            </ol>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                Estes Termos e Condi&ccedil;&otilde;es de Uso constituem um
                acordo legalmente vinculativo feito entre voc&ecirc;,
                pessoalmente ou em nome de uma entidade (&quot;voc&ecirc;&quot;)
                e EyeFlow ou (&quot;n&oacute;s&quot; ou &quot;nosso&quot;), em
                rela&ccedil;&atilde;o ao seu acesso e uso de o site{" "}
                <a
                  rel="noopener noreferrer nofollow"
                  href="https://eyeflow.ai/"
                >
                  https://eyeflow.ai
                </a>
                , bem como qualquer outra forma de m&iacute;dia, canal de
                m&iacute;dia, site m&oacute;vel ou aplicativo m&oacute;vel
                relacionado, vinculado ou de outra forma conectado a eles
                (coletivamente, o &ldquo;Site&rdquo;). Voc&ecirc; concorda que,
                ao acessar o Site, leu, compreendeu e concorda em obedecer a
                todos estes Termos e Condi&ccedil;&otilde;es de Uso. SE
                VOC&Ecirc; N&Atilde;O CONCORDAR COM TODOS ESTES TERMOS E
                CONDI&Ccedil;&Otilde;ES DE USO, ENT&Atilde;O VOC&Ecirc;
                EST&Aacute; EXPRESSAMENTE PROIBIDO DE USAR O SITE E DEVE
                INTERROMPER O USO IMEDIATAMENTE.
              </span>
            </p>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                As informa&ccedil;&otilde;es fornecidas no Site n&atilde;o se
                destinam &agrave; distribui&ccedil;&atilde;o ou uso por qualquer
                pessoa ou entidade em qualquer jurisdi&ccedil;&atilde;o ou
                pa&iacute;s onde tal distribui&ccedil;&atilde;o ou uso seja
                contr&aacute;rio &agrave; lei ou regulamento ou que nos sujeite
                a qualquer exig&ecirc;ncia de registro dentro de tal
                jurisdi&ccedil;&atilde;o ou pa&iacute;s. Consequentemente, as
                pessoas que optam por acessar o Site de outras localidades o
                fazem por sua pr&oacute;pria iniciativa e s&atilde;o as
                &uacute;nicas respons&aacute;veis pelo cumprimento das leis
                locais, se e na medida em que as leis locais sejam
                aplic&aacute;veis.
              </span>
            </p>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                Quaisquer softwares de terceiros que possam vir a guardar
                qualquer tipo de relação com a EyeFlow possuem Termos de Uso e
                Políticas de Privacidades independentes. Você declara ciência
                sobre esse fato e se compromete a ler, compreender e concordar
                com tais termos e políticas de acordo com os softwares que forem
                por você utilizados.{" "}
              </span>
            </p>
            {/* <p style={{ textAlign: "justify", lineHeight: "normal" }}> */}
            <ol style={{ listStyleType: "none", textAlign: "left" }}>
              <li>
                <strong>
                  <span>3. Altera&ccedil;&otilde;es aos Termos de Uso</span>
                </strong>
              </li>
            </ol>
            {/* </p> */}
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                Termos e condi&ccedil;&otilde;es suplementares ou documentos que
                podem ser publicados no Site de tempos em tempos s&atilde;o aqui
                expressamente incorporados por refer&ecirc;ncia. N&oacute;s nos
                reservamos o direito, a nosso crit&eacute;rio exclusivo, de
                fazer altera&ccedil;&otilde;es ou modifica&ccedil;&otilde;es a
                estes Termos e Condi&ccedil;&otilde;es de Uso a qualquer momento
                e por qualquer motivo. Iremos alert&aacute;-lo sobre quaisquer
                altera&ccedil;&otilde;es atualizando a data da
                &ldquo;&Uacute;ltima atualiza&ccedil;&atilde;o&rdquo; destes
                Termos e Condi&ccedil;&otilde;es de Uso, e voc&ecirc; renuncia a
                qualquer direito de receber notifica&ccedil;&atilde;o
                espec&iacute;fica de cada altera&ccedil;&atilde;o. &Eacute; sua
                responsabilidade revisar periodicamente estes Termos e
                Condi&ccedil;&otilde;es de Uso para se manter informado sobre as
                atualiza&ccedil;&otilde;es. Voc&ecirc; estar&aacute; sujeito, e
                ser&aacute; considerado como tendo sido informado e aceito, as
                mudan&ccedil;as em quaisquer Termos e Condi&ccedil;&otilde;es de
                Uso revisados pelo seu uso continuado do Site ap&oacute;s a data
                em que tais Termos e Condi&ccedil;&otilde;es de Uso revisados
                forem publicados.
              </span>
            </p>
            <ol style={{ listStyleType: "none", textAlign: "left" }}>
              <li>
                <strong>
                  <span>4. Capacidade civil dos usu&aacute;rios</span>
                </strong>
              </li>
            </ol>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                O Site &eacute; destinado a usu&aacute;rios pessoas
                f&iacute;sicas com idade igual ou superior a 18 (dezoito) anos e
                representantes legais de empresas usu&aacute;rias da plataforma.
                Todos os usu&aacute;rios da plataforma declaram ci&ecirc;ncia e
                concord&acirc;ncia com o fato de que, caso a
                disposi&ccedil;&atilde;o dessa cl&aacute;usula seja descumprida,
                a EyeFlow poder&aacute; cancelar o cadastro e o acesso do
                respectivo usu&aacute;rio.&nbsp;
              </span>
            </p>
            <ol style={{ listStyleType: "none", textAlign: "left" }}>
              <li>
                <strong>
                  <span>5. Direito de Propriedade Intelectual</span>
                </strong>
              </li>
            </ol>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                Todos os c&oacute;digos-fonte, bancos de dados, funcionalidade,
                software, designs de site, &aacute;udio, v&iacute;deo, texto,
                fotografias, v&iacute;deos e gr&aacute;ficos no Site
                (coletivamente, o &ldquo;Conte&uacute;do&rdquo;) e as marcas
                registradas, marcas e logotipos nelas contidos (as
                &quot;Marcas&quot;) s&atilde;o de propriedade ou controlados por
                n&oacute;s ou licenciados para n&oacute;s, e s&atilde;o
                protegidos por direitos autorais e leis de marcas registradas e
                v&aacute;rios outros direitos de propriedade intelectual do
                Brasil, jurisdi&ccedil;&otilde;es estrangeiras e
                conven&ccedil;&otilde;es internacionais. O Conte&uacute;do e as
                Marcas s&atilde;o fornecidos no Site &quot;COMO
                EST&Atilde;O&quot; apenas para sua informa&ccedil;&atilde;o e
                uso pessoal. Exceto conforme expressamente previsto nestes
                Termos e Condi&ccedil;&otilde;es de Uso, nenhuma parte do Site e
                nenhum Conte&uacute;do ou Marcas podem ser copiados,
                reproduzidos, agregados, republicados, enviados, postados,
                exibidos publicamente, codificados, traduzidos, transmitidos,
                distribu&iacute;dos, vendidos, licenciados ou de outra forma
                explorados para quaisquer fins comerciais, sem a nossa
                autoriza&ccedil;&atilde;o expressa pr&eacute;via por escrito.
              </span>
            </p>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                Desde que voc&ecirc; seja eleg&iacute;vel para usar o Site,
                voc&ecirc; recebe uma licen&ccedil;a limitada para acessar e
                usar o Site e para baixar ou imprimir uma c&oacute;pia de
                qualquer parte do Conte&uacute;do ao qual voc&ecirc; obteve
                acesso adequado. N&oacute;s nos reservamos todos os direitos
                n&atilde;o expressamente concedidos a voc&ecirc; no e para o
                Site, o Conte&uacute;do e as Marcas.
              </span>
            </p>
            <ol style={{ listStyleType: "none", textAlign: "left" }}>
              <li>
                <strong>
                  <span>6. Representa&ccedil;&otilde;es do usu&aacute;rio</span>
                </strong>
              </li>
            </ol>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                Ao usar o Site, voc&ecirc; declara e garante que: (1) todas as
                informa&ccedil;&otilde;es de registro enviadas ser&atilde;o
                verdadeiras, precisas, atuais e completas; (2) voc&ecirc;
                manter&aacute; a precis&atilde;o de tais
                informa&ccedil;&otilde;es e prontamente atualizar&aacute; tais
                informa&ccedil;&otilde;es de registro conforme
                necess&aacute;rio; (3) voc&ecirc; tem capacidade legal e
                concorda em cumprir estes Termos e Condi&ccedil;&otilde;es de
                Uso; (4) voc&ecirc; n&atilde;o &eacute; menor de 18 anos; (5)
                voc&ecirc; n&atilde;o acessar&aacute; o Site por meios
                automatizados ou n&atilde;o humanos, seja por meio de um bot,
                script ou outro; (6) voc&ecirc; n&atilde;o usar&aacute; o Site
                para qualquer prop&oacute;sito ilegal ou n&atilde;o autorizado;
                e (7) seu uso do Site n&atilde;o violar&aacute; nenhuma lei ou
                regulamento aplic&aacute;vel.
              </span>
            </p>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                Se voc&ecirc; fornecer qualquer informa&ccedil;&atilde;o falsa,
                inexata, desatualizada ou incompleta, temos o direito de
                suspender ou encerrar sua conta e recusar todo e qualquer uso
                atual ou futuro do Site (ou qualquer parte dele). &nbsp;&nbsp;
              </span>
            </p>
            <ol style={{ listStyleType: "none", textAlign: "left" }}>
              <li>
                <strong>
                  <span>7. Cadastro</span>
                </strong>
              </li>
            </ol>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                Para utilizar a plataforma o Usu&aacute;rio deve realizar um
                cadastro pr&eacute;vio fornecendo voluntariamente
                informa&ccedil;&otilde;es sobre si, tais como: nome, e-mail,
                login, senha, nome da empresa o qual faz parte, celular, data de
                nascimento e outras informa&ccedil;&otilde;es necess&aacute;rias
              </span>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                . O Usu&aacute;rio declara que as informa&ccedil;&otilde;es
                s&atilde;o verdadeiras e compromete-se a manter seus dados
                sempre atualizados. O usu&aacute;rio declara e concorda que a
                EyeFlow n&atilde;o &eacute; respons&aacute;vel pelas
                informa&ccedil;&otilde;es prestadas, mas que a empresa
                poder&aacute; verificar, a seu crit&eacute;rio, a veracidade das
                informa&ccedil;&otilde;es, bem como solicitar a
                documenta&ccedil;&atilde;o que comprove as
                informa&ccedil;&otilde;es prestadas. A EyeFlow se reserva no
                direito de cancelar unilateralmente qualquer cadastro feito a
                partir de informa&ccedil;&otilde;es falsas, perdendo o
                Usu&aacute;rio o direito de acesso ou uso do Site, sem direito
                &agrave; indeniza&ccedil;&atilde;o ou ressarcimento.
              </span>
            </p>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                O usu&aacute;rio &eacute; o &uacute;nico e exclusivo
                respons&aacute;vel por manter o sigilo de seu login e senha,
                sendo respons&aacute;vel por assegurar a seguran&ccedil;a de sua
                conta. Caso o usu&aacute;rio, por qualquer motivo, perca seus
                dados de acesso ou suspeite de atividades indevidas,
                dever&aacute; comunicar a EyeFlow imediatamente.
              </span>
            </p>
            <ol style={{ listStyleType: "none", textAlign: "left" }}>
              <li>
                <strong>
                  <span>8. Planos de acesso</span>
                </strong>
              </li>
            </ol>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                O usu&aacute;rio dever&aacute; optar entre tr&ecirc;s planos de
                acesso:&nbsp;
              </span>
            </p>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                (i) EyeFlow gratuito: garante o acesso do usu&aacute;rio
                &agrave;s funcionalidades b&aacute;sicas da Plataforma EyeFlow
                (atualmente em vers&atilde;o Beta): cria&ccedil;&atilde;o de
                datasets, marca&ccedil;&atilde;o de imagens, treinamento das
                redes neurais, desenvolvimento de flows, teste dos treinamentos
                em v&iacute;deos e utiliza&ccedil;&atilde;o da rede neural em
                solu&ccedil;&otilde;es pessoais, dentre outros. O modo gratuito
                atende &agrave; aplica&ccedil;&otilde;es pessoais ou
                educacionais.&nbsp;
              </span>
            </p>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                (ii) EyeFlow Edge: destinada &agrave; execu&ccedil;&atilde;o dos
                modelos em computa&ccedil;&atilde;o de borda, atrav&eacute;s de
                um end-point ou em um dispositivo local. Para uso comercial das
                detec&ccedil;&otilde;es em dispositivos de borda, &eacute;
                necess&aacute;ria uma licen&ccedil;a Smart Inspectio, que pode
                ser obtida atrav&eacute;s do nosso site ou no email{" "}
                <a href="mailto:contact@eyeflow.ai">
                  <span style={{ fontSize: "13px" }}>
                    contact@eyeflow.ai
                  </span>
                </a>
                .
              </span>
            </p>
            <ol style={{ listStyleType: "none", textAlign: "left" }}>
              <li>
                <strong>
                  <span>9. Cancelamentos</span>
                </strong>
              </li>
            </ol>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                O plano de acesso gratuito da plataforma EyeFlow poder&aacute;
                ser cancelado a qualquer momento pelo usu&aacute;rio, sem
                notifica&ccedil;&atilde;o pr&eacute;via e sem qualquer
                indeniza&ccedil;&atilde;o ou multa inerente.
              </span>
            </p>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                O plano de acesso gratuito da plataforma EyeFlow, em sua
                vers&atilde;o Beta, poder&aacute; ser cancelado a qualquer
                momento pela EyeFlow, sem notifica&ccedil;&atilde;o
                pr&eacute;via e sem qualquer indeniza&ccedil;&atilde;o ou multa
                inerente.
              </span>
            </p>
            <ol style={{ listStyleType: "none", textAlign: "left" }}>
              <li>
                <strong>
                  <span>10. Atividades proibidas</span>
                </strong>
              </li>
            </ol>
            <p style={{ textAlign: "left", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                Voc&ecirc; n&atilde;o pode acessar ou usar o Site para qualquer
                prop&oacute;sito diferente daquele para o qual disponibilizamos
                o Site. O Site n&atilde;o pode ser usado em conex&atilde;o com
                quaisquer empreendimentos comerciais, exceto aqueles que
                s&atilde;o especificamente endossados ou aprovados por
                n&oacute;s.
              </span>
            </p>
            <p style={{ textAlign: "left", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                Como usu&aacute;rio do Site, voc&ecirc; concorda em n&atilde;o:
              </span>
            </p>
            <ol
              style={{
                textAlign: "left",
                listStyleType: "lower-latin",
              }}
            >
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Recuperar sistematicamente dados ou outro conte&uacute;do do
                  Site para criar ou compilar, direta ou indiretamente, uma
                  cole&ccedil;&atilde;o, compila&ccedil;&atilde;o, banco de
                  dados ou diret&oacute;rio sem nossa permiss&atilde;o por
                  escrito;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Fazer qualquer uso n&atilde;o autorizado do Site, incluindo a
                  coleta de nomes de usu&aacute;rio e/ou endere&ccedil;os de
                  e-mail de usu&aacute;rios por meios eletr&ocirc;nicos ou
                  outros com a finalidade de enviar e-mails n&atilde;o
                  solicitados, ou criar contas de usu&aacute;rio por meios
                  automatizados ou sob falsos pretextos;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Usar o Site para anunciar ou oferecer a venda de bens e
                  servi&ccedil;os que n&atilde;o aqueles que s&atilde;o
                  especificamente endossados ou aprovados por n&oacute;s.
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Circunver, desabilitar ou interferir de outra forma nos
                  recursos relacionados &agrave; seguran&ccedil;a do Site,
                  incluindo recursos que impedem ou restringem o uso ou
                  c&oacute;pia de qualquer Conte&uacute;do ou imp&otilde;em
                  limita&ccedil;&otilde;es no uso do Site e/ou do
                  Conte&uacute;do nele contido;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Envolver-se em enquadramento n&atilde;o autorizado ou link
                  para o Site;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Enganar, fraudar ou enganar a n&oacute;s e a outros
                  usu&aacute;rios, especialmente em qualquer tentativa de obter
                  informa&ccedil;&otilde;es confidenciais da conta, como senhas
                  de usu&aacute;rio;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Envolver-se em qualquer uso automatizado do sistema, como o
                  uso de scripts para enviar coment&aacute;rios ou mensagens, ou
                  o uso de minera&ccedil;&atilde;o de dados, rob&ocirc;s ou
                  ferramentas semelhantes de coleta e extra&ccedil;&atilde;o de
                  dados;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Fazer uso impr&oacute;prio de nossos servi&ccedil;os de
                  suporte ou envie den&uacute;ncias falsas de abuso ou m&aacute;
                  conduta;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Interferir, interromper ou criar uma carga indevida no Site ou
                  nas redes ou servi&ccedil;os conectados ao Site;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Tentar se passar por outro usu&aacute;rio ou pessoa ou usar o
                  nome de usu&aacute;rio de outro usu&aacute;rio;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Vender ou transferir seu perfil;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Usar qualquer informa&ccedil;&atilde;o obtida no Site para
                  assediar, abusar ou prejudicar outra pessoa;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Decifrar, decompilar, desmontar ou fazer engenharia reversa de
                  qualquer software que inclua ou de alguma forma fa&ccedil;a
                  parte do Site;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Copiar ou adaptar o software do Site, incluindo, mas
                  n&atilde;o se limitando a Flash, React, HTML, JavaScript ou
                  outro c&oacute;digo;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Excluir o copyright ou outro aviso de direitos de propriedade
                  de qualquer Conte&uacute;do;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Assediar, incomodar, intimidar ou amea&ccedil;ar qualquer um
                  dos nossos funcion&aacute;rios ou agentes envolvidos no
                  fornecimento de qualquer parte do Site para voc&ecirc;;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Tentar contornar quaisquer medidas do Site destinadas a
                  impedir ou restringir o acesso ao Site ou a qualquer parte do
                  Site;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Carregar ou transmitir (ou tentar carregar ou transmitir)
                  v&iacute;rus, cavalos de Tr&oacute;ia ou outro material,
                  incluindo o uso excessivo de letras mai&uacute;sculas e spam
                  (postagem cont&iacute;nua de texto repetitivo), que interfere
                  no uso ininterrupto de qualquer parte e aproveitamento do Site
                  ou modifica, prejudica, interrompe, altera ou interfere no
                  uso, recursos, fun&ccedil;&otilde;es, opera&ccedil;&atilde;o
                  ou manuten&ccedil;&atilde;o do Site;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Fazer upload ou transmitir (ou tentar fazer upload ou
                  transmitir) qualquer material que atue como um mecanismo de
                  transmiss&atilde;o ou coleta de informa&ccedil;&otilde;es
                  passivo ou ativo, incluindo, sem limita&ccedil;&atilde;o,
                  formatos de interc&acirc;mbio de gr&aacute;ficos claros
                  (&quot;gifs&quot;), pixels 1 &times; 1, web bugs, cookies ou
                  outros dispositivos semelhantes (&agrave;s vezes chamados de
                  &ldquo;spyware&rdquo; ou &ldquo;mecanismos de coleta
                  passivos&rdquo;); e
                </span>
              </li>
              <li>
                <span>
                  Usar o Site de maneira inconsistente com quaisquer leis ou
                  regulamentos aplic&aacute;veis.
                </span>
              </li>
            </ol>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                Caso voc&ecirc; pratique qualquer a&ccedil;&atilde;o citada
                nessa cl&aacute;usula, temos o direito de suspender ou encerrar
                sua conta e recusar todo e qualquer uso atual ou futuro do Site
                (ou qualquer parte dele).
              </span>
            </p>
            <ol style={{ listStyleType: "none", textAlign: "left" }}>
              <li>
                <strong>
                  <span>
                    11. Contribui&ccedil;&otilde;es geradas pelo usu&aacute;rio
                    &nbsp; &nbsp; &nbsp; &nbsp;
                  </span>
                </strong>
              </li>
            </ol>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                O Site pode convid&aacute;-lo para um chat, contribuir ou
                participar de blogs, FAQs, quadros de mensagens, f&oacute;runs
                online e outras funcionalidades, e pode fornecer a voc&ecirc; a
                oportunidade de criar, enviar, postar, exibir, transmitir,
                executar, publicar, distribuir, ou transmitir conte&uacute;do e
                materiais para n&oacute;s ou no Site, incluindo, mas n&atilde;o
                se limitando a, texto, escritos, v&iacute;deo, &aacute;udio,
                fotografias, gr&aacute;ficos, coment&aacute;rios,
                sugest&otilde;es ou informa&ccedil;&otilde;es pessoais ou outro
                material (coletivamente,
                &quot;Contribui&ccedil;&otilde;es&quot;). As
                contribui&ccedil;&otilde;es podem ser visualizadas por outros
                usu&aacute;rios do Site e por meio de sites de terceiros. Como
                tal, quaisquer contribui&ccedil;&otilde;es que voc&ecirc;
                transmitir podem ser tratadas como n&atilde;o confidenciais e
                n&atilde;o propriet&aacute;rias. Ao criar ou disponibilizar
                quaisquer Contribui&ccedil;&otilde;es, voc&ecirc; declara e
                garante que:
              </span>
            </p>
            <ol
              style={{
                textAlign: "left",
                listStyleType: "lower-latin",
              }}
            >
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  A cria&ccedil;&atilde;o, distribui&ccedil;&atilde;o,
                  transmiss&atilde;o, exibi&ccedil;&atilde;o p&uacute;blica ou
                  desempenho e o acesso, download ou c&oacute;pia de suas
                  contribui&ccedil;&otilde;es n&atilde;o infringem e n&atilde;o
                  infringir&atilde;o os direitos de propriedade, incluindo, mas
                  n&atilde;o se limitando a direitos autorais, patentes, marcas
                  registradas, segredos comerciais e/ou direitos morais de
                  terceiros;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Voc&ecirc; &eacute; o criador e propriet&aacute;rio de ou tem
                  as licen&ccedil;as, direitos, consentimentos,
                  libera&ccedil;&otilde;es e permiss&otilde;es
                  necess&aacute;rias para usar e autorizar a n&oacute;s, o Site
                  e outros usu&aacute;rios do Site, a usar suas
                  Contribui&ccedil;&otilde;es de qualquer maneira contemplada
                  pelo Site e estes Termos e Condi&ccedil;&otilde;es de Uso;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Voc&ecirc; tem o consentimento, libera&ccedil;&atilde;o e / ou
                  permiss&atilde;o por escrito de cada pessoa individual
                  identific&aacute;vel em suas Contribui&ccedil;&otilde;es para
                  usar o nome ou a imagem de cada pessoa individual
                  identific&aacute;vel para permitir a inclus&atilde;o e uso de
                  suas Contribui&ccedil;&otilde;es de qualquer maneira
                  contemplada pelo Site e estes Termos e Condi&ccedil;&otilde;es
                  de Uso;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Suas contribui&ccedil;&otilde;es n&atilde;o s&atilde;o falsas,
                  imprecisas ou enganosas;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Suas contribui&ccedil;&otilde;es n&atilde;o s&atilde;o
                  publicidade n&atilde;o solicitada ou n&atilde;o autorizada,
                  materiais promocionais, esquemas de pir&acirc;mide, correntes,
                  spam, mala direta ou outras formas de
                  solicita&ccedil;&atilde;o;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Suas contribui&ccedil;&otilde;es n&atilde;o s&atilde;o
                  obscenas, lascivas, sujas, violentas, ofensivas, caluniosas ou
                  de outra forma question&aacute;veis (conforme determinado por
                  n&oacute;s);
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Suas contribui&ccedil;&otilde;es n&atilde;o ridicularizam,
                  zombam, desacreditam, intimidam ou abusam de ningu&eacute;m;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Suas contribui&ccedil;&otilde;es n&atilde;o defendem a
                  derrubada violenta de qualquer governo ou incitam, encorajam
                  ou amea&ccedil;am ferir fisicamente outro;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Suas contribui&ccedil;&otilde;es n&atilde;o violam nenhuma
                  lei, regulamento ou regra aplic&aacute;vel;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Suas contribui&ccedil;&otilde;es n&atilde;o violam a
                  privacidade ou direitos p&uacute;blicos da cidade ou de
                  qualquer terceiro;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Suas contribui&ccedil;&otilde;es n&atilde;o cont&ecirc;m
                  nenhum material que solicite informa&ccedil;&otilde;es
                  pessoais de menores de 18 anos ou explore menores de 18 anos
                  de maneira sexual ou violenta;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Suas contribui&ccedil;&otilde;es n&atilde;o violam nenhuma lei
                  federal ou estadual relativa &agrave; pornografia infantil, ou
                  de outra forma destinada a proteger a sa&uacute;de ou o
                  bem-estar de menores;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Suas contribui&ccedil;&otilde;es n&atilde;o incluem
                  coment&aacute;rios ofensivos relacionados a ra&ccedil;a,
                  nacionalidade, g&ecirc;nero, prefer&ecirc;ncia sexual ou
                  defici&ecirc;ncia f&iacute;sica;
                </span>
              </li>
              <li>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  Suas contribui&ccedil;&otilde;es n&atilde;o violam, ou
                  vinculam a materiais que violem, qualquer
                  disposi&ccedil;&atilde;o destes Termos e
                  Condi&ccedil;&otilde;es de Uso ou qualquer lei ou regulamento
                  aplic&aacute;vel; e
                </span>
              </li>
              <li>
                <span>
                  Qualquer uso do Site em viola&ccedil;&atilde;o ao precedente
                  viola estes Termos e Condi&ccedil;&otilde;es de Uso e pode
                  resultar, entre outras coisas, na rescis&atilde;o ou
                  suspens&atilde;o de seus direitos de uso do Site.
                </span>
              </li>
            </ol>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                Caso voc&ecirc; viole qualquer condi&ccedil;&atilde;o citada
                nessa cl&aacute;usula, temos o direito de suspender ou encerrar
                sua conta e recusar todo e qualquer uso atual ou futuro do Site
                (ou qualquer parte dele). &nbsp;
              </span>
            </p>
            <ol style={{ listStyleType: "none", textAlign: "left" }}>
              <li>
                <strong>
                  <span>
                    12. Licen&ccedil;a de contribui&ccedil;&atilde;o &nbsp;
                    &nbsp; &nbsp;
                  </span>
                </strong>
              </li>
            </ol>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                Ao postar suas contribui&ccedil;&otilde;es em qualquer parte do
                site, voc&ecirc; concede automaticamente, representa e garante
                que tem o direito de conceder, para n&oacute;s, um direito
                mundial perp&eacute;tuo e n&atilde;o exclusivo e licen&ccedil;a
                para hospedar, usar, copiar, reproduzir, divulgar, publicar,
                transmitir, arquivar, armazenar, exibir publicamente e
                distribuir tais Contribui&ccedil;&otilde;es com o &uacute;nico
                prop&oacute;sito de nos permitir operar o Site. N&atilde;o
                usaremos suas contribui&ccedil;&otilde;es para qualquer outro
                prop&oacute;sito, como uso comercial, publicidade,
                prepara&ccedil;&atilde;o de derivados ou
                incorpora&ccedil;&atilde;o em outros trabalhos.
              </span>
            </p>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                Esta licen&ccedil;a se aplicar&aacute; a qualquer forma,
                m&iacute;dia ou tecnologia agora conhecida ou desenvolvida
                posteriormente e inclui o uso de seu nome, nome da empresa e
                nome da franquia, conforme aplic&aacute;vel, e qualquer uma das
                marcas registradas, marcas de servi&ccedil;o, nomes comerciais,
                logotipos, e imagens pessoais e comerciais que voc&ecirc;
                fornece. Voc&ecirc; renuncia a todos os direitos morais em suas
                contribui&ccedil;&otilde;es e garante que os direitos morais
                n&atilde;o foram reivindicados em suas
                contribui&ccedil;&otilde;es. &nbsp; &nbsp; &nbsp;&nbsp;
              </span>
            </p>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                N&atilde;o afirmamos qualquer propriedade sobre suas
                contribui&ccedil;&otilde;es. Voc&ecirc; ret&eacute;m a
                propriedade total de todas as suas contribui&ccedil;&otilde;es e
                de quaisquer direitos de propriedade intelectual ou outros
                direitos de propriedade associados &agrave;s suas
                contribui&ccedil;&otilde;es. N&atilde;o somos
                respons&aacute;veis por quaisquer declara&ccedil;&otilde;es ou
                representa&ccedil;&otilde;es em suas Contribui&ccedil;&otilde;es
                fornecidas por voc&ecirc; em qualquer &aacute;rea do Site.
                Voc&ecirc; &eacute; o &uacute;nico respons&aacute;vel por suas
                contribui&ccedil;&otilde;es para o site e concorda expressamente
                em nos exonerar de toda e qualquer responsabilidade e em
                abster-se de qualquer a&ccedil;&atilde;o legal contra n&oacute;s
                em rela&ccedil;&atilde;o &agrave;s suas
                contribui&ccedil;&otilde;es. &nbsp; &nbsp; &nbsp;&nbsp;
              </span>
            </p>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                Temos o direito, a nosso crit&eacute;rio exclusivo e absoluto,
                (1) de editar, redigir ou de outra forma alterar quaisquer
                Contribui&ccedil;&otilde;es; (2) para reclassificar quaisquer
                Contribui&ccedil;&otilde;es para coloc&aacute;-las em locais
                mais apropriados no Site; e (3) para pr&eacute;-selecionar ou
                excluir quaisquer contribui&ccedil;&otilde;es a qualquer momento
                e por qualquer motivo, sem aviso pr&eacute;vio. N&atilde;o temos
                obriga&ccedil;&atilde;o de monitorar suas
                contribui&ccedil;&otilde;es. &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;&nbsp;
              </span>
            </p>
            <ol style={{ listStyleType: "none", textAlign: "left" }}>
              <li>
                <strong>
                  <span>13. Gerenciamento de site</span>
                </strong>
              </li>
            </ol>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                N&oacute;s nos reservamos o direito, mas n&atilde;o a
                obriga&ccedil;&atilde;o, de: (1) monitorar o Site em busca de
                viola&ccedil;&otilde;es destes Termos e Condi&ccedil;&otilde;es
                de Uso; (2) tomar as medidas legais cab&iacute;veis contra
                qualquer pessoa que, a nosso exclusivo crit&eacute;rio, viole a
                lei ou estes Termos e Condi&ccedil;&otilde;es de Uso, incluindo,
                sem limita&ccedil;&atilde;o, relatar tal usu&aacute;rio
                &agrave;s autoridades policiais; (3) a nosso exclusivo
                crit&eacute;rio e sem limita&ccedil;&atilde;o, recusar,
                restringir o acesso a, limitar a disponibilidade ou
                desabilita&ccedil;&atilde;o (na medida do tecnologicamente
                vi&aacute;vel) de qualquer uma de suas
                Contribui&ccedil;&otilde;es ou qualquer parte delas; (4) a nosso
                exclusivo crit&eacute;rio e sem limita&ccedil;&atilde;o,
                notifica&ccedil;&atilde;o ou responsabilidade, remover do Site
                ou de outra forma desativar todos os arquivos e conte&uacute;do
                de tamanho excessivo ou de qualquer forma onerosos para nossos
                sistemas; e (5) de outra forma administrar o Site de uma maneira
                projetada para proteger nossos direitos e propriedade e para
                facilitar o funcionamento adequado do Site.
              </span>
            </p>
            <ol style={{ listStyleType: "none", textAlign: "left" }}>
              <li>
                <strong>
                  <span>14. Pol&iacute;tica de privacidade &nbsp;</span>
                </strong>
              </li>
            </ol>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                Nos preocupamos com a privacidade e seguran&ccedil;a dos dados.
                Leia nossa Pol&iacute;tica de Privacidade:{" "}
                <a href="https://eyeflow.ai/politica-privacidade">
                  https://eyeflow.ai/politica-privacidade.
                </a>{" "}
                Ao usar o Site, voc&ecirc; concorda em obedecer &agrave; nossa
                Pol&iacute;tica de Privacidade, que est&aacute; incorporada a
                estes Termos e Condi&ccedil;&otilde;es de Uso. Informamos que o
                site est&aacute; hospedado no Brasil. Se voc&ecirc; acessar o
                Site da Uni&atilde;o Europeia, Am&eacute;ricas, &Aacute;sia ou
                qualquer outra regi&atilde;o do mundo com leis ou outros
                requisitos que regem a coleta, uso ou divulga&ccedil;&atilde;o
                de dados pessoais que diferem das leis aplic&aacute;veis no
                Brasil, ent&atilde;o por meio de seu uso continuado do Site,
                voc&ecirc; est&aacute; transferindo seus dados para o Brasil e
                concorda expressamente que seus dados sejam transferidos e
                processados no Brasil. Al&eacute;m disso, n&atilde;o aceitamos
                ou solicitamos informa&ccedil;&otilde;es de menores de idade ou
                comercializamos intencionalmente para menores de idade.
                Portanto, se recebermos conhecimento real de que qualquer pessoa
                com menos de 18 anos nos forneceu informa&ccedil;&otilde;es
                pessoais sem o consentimento dos pais exigidos e
                verific&aacute;veis, excluiremos essas informa&ccedil;&otilde;es
                do Site o mais r&aacute;pido &eacute; razoavelmente
                pr&aacute;tico.
              </span>
            </p>
            <ol style={{ listStyleType: "none", textAlign: "left" }}>
              <li>
                <strong>
                  <span>15. Prazo e rescis&atilde;o</span>
                </strong>
              </li>
            </ol>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                Estes Termos e Condi&ccedil;&otilde;es de Uso
                permanecer&atilde;o em pleno vigor e efeito enquanto voc&ecirc;
                usar o Site. SEM LIMITAR QUALQUER OUTRA DISPOSI&Ccedil;&Atilde;O
                DESTES TERMOS E CONDI&Ccedil;&Otilde;ES DE USO, NOS RESERVAMOS O
                DIREITO DE, A NOSSO JU&Iacute;ZO E SEM AVISO OU
                RESPONSABILIDADE, NEGAR O ACESSO E USO DO SITE (INCLUINDO O
                BLOQUEIO DE CERTOS ENDERE&Ccedil;OS IP), A QUALQUER PESSOA OU
                POR QUALQUER RAZ&Atilde;O SEM MOTIVO, INCLUINDO, SEM
                LIMITA&Ccedil;&Atilde;O, A VIOLA&Ccedil;&Atilde;O DE QUALQUER
                REPRESENTA&Ccedil;&Atilde;O, GARANTIA OU ALIAN&Ccedil;A CONTIDA
                NESTES TERMOS E CONDI&Ccedil;&Otilde;ES DE USO OU DE QUALQUER
                LEI OU REGULAMENTO APLIC&Aacute;VEL. PODEMOS TERMINAR SEU USO OU
                PARTICIPA&Ccedil;&Atilde;O NO SITE OU EXCLUIR SUA CONTA E
                QUALQUER CONTE&Uacute;DO OU INFORMA&Ccedil;&Atilde;O QUE
                VOC&Ecirc; POSTOU A QUALQUER MOMENTO, SEM AVISO PR&Eacute;VIO, A
                NOSSO JU&Iacute;ZO.
              </span>
            </p>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                Se encerrarmos ou suspendermos sua conta por qualquer motivo,
                voc&ecirc; est&aacute; proibido de registrar e criar uma nova
                conta em seu nome, um nome falso ou emprestado, ou o nome de
                terceiros, mesmo se voc&ecirc; estiver agindo em nome de
                terceiros festa. Al&eacute;m de encerrar ou suspender sua conta,
                nos reservamos o direito de tomar as medidas legais
                cab&iacute;veis, incluindo, sem limita&ccedil;&atilde;o,
                a&ccedil;&otilde;es civis, criminais e liminares.
              </span>
            </p>
            <ol style={{ listStyleType: "none", textAlign: "left" }}>
              <li>
                <strong>
                  <span>
                    16. Modifica&ccedil;&otilde;es e interrup&ccedil;&otilde;es
                  </span>
                </strong>
              </li>
            </ol>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                N&oacute;s nos reservamos o direito de alterar, modificar ou
                remover o conte&uacute;do do Site a qualquer momento ou por
                qualquer motivo, a nosso exclusivo crit&eacute;rio, sem aviso
                pr&eacute;vio. No entanto, n&atilde;o temos
                obriga&ccedil;&atilde;o de atualizar nenhuma
                informa&ccedil;&atilde;o em nosso Site. Tamb&eacute;m nos
                reservamos o direito de modificar ou descontinuar todo ou parte
                do Site sem aviso pr&eacute;vio a qualquer momento. N&atilde;o
                seremos respons&aacute;veis por voc&ecirc; ou terceiros por
                qualquer modifica&ccedil;&atilde;o, altera&ccedil;&atilde;o de
                pre&ccedil;o, suspens&atilde;o ou descontinua&ccedil;&atilde;o
                do Site.
              </span>
            </p>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                N&atilde;o podemos garantir que o Site estar&aacute; sempre
                dispon&iacute;vel. Podemos enfrentar problemas de hardware,
                software ou outros problemas ou precisar realizar
                manuten&ccedil;&atilde;o relacionada ao Site, resultando em
                interrup&ccedil;&otilde;es, atrasos ou erros. N&oacute;s nos
                reservamos o direito de alterar, revisar, atualizar, suspender,
                descontinuar ou modificar o Site a qualquer momento ou por
                qualquer raz&atilde;o, sem aviso pr&eacute;vio. Voc&ecirc;
                concorda que n&atilde;o temos qualquer responsabilidade por
                qualquer perda, dano ou inconveniente causado por sua
                incapacidade de acessar ou usar o Site durante qualquer tempo de
                inatividade ou interrup&ccedil;&atilde;o do Site. Nada nestes
                Termos e Condi&ccedil;&otilde;es de Uso ser&aacute; interpretado
                de forma a nos obrigar a manter e apoiar o Site ou a fornecer
                quaisquer corre&ccedil;&otilde;es, atualiza&ccedil;&otilde;es ou
                libera&ccedil;&otilde;es em rela&ccedil;&atilde;o a ele.
              </span>
            </p>
            <ol style={{ listStyleType: "none", textAlign: "left" }}>
              <li>
                <strong>
                  <span>17. Lei aplic&aacute;vel</span>
                </strong>
              </li>
            </ol>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                Estes Termos e Condi&ccedil;&otilde;es de Uso e seu uso do Site
                s&atilde;o regidos e interpretados de acordo com as leis do
                Brasil.
              </span>
            </p>
            <ol style={{ listStyleType: "none", textAlign: "left" }}>
              <li>
                <strong>
                  <span>18. Resolu&ccedil;&atilde;o de disputas &nbsp;</span>
                </strong>
              </li>
            </ol>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                As Partes declaram e concordam que o foro competente para a
                abertura e condu&ccedil;&atilde;o de qualquer a&ccedil;&atilde;o
                legal referente a temas relacionados a esse Site ou aos
                Servi&ccedil;os prestados pela EyeFlow &eacute; o de S&atilde;o
                Paulo/SP, renunciando as Partes a qualquer outro foro, por mais
                vantajoso que seja.
              </span>
            </p>
            <ol style={{ listStyleType: "none", textAlign: "left" }}>
              <li>
                <strong>
                  <span>19. Corre&ccedil;&otilde;es</span>
                </strong>
              </li>
            </ol>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                Pode haver informa&ccedil;&otilde;es no Site que contenham erros
                tipogr&aacute;ficos, imprecis&otilde;es ou omiss&otilde;es,
                incluindo descri&ccedil;&otilde;es, pre&ccedil;os,
                disponibilidade e v&aacute;rias outras
                informa&ccedil;&otilde;es. N&oacute;s nos reservamos o direito
                de corrigir quaisquer erros, imprecis&otilde;es ou
                omiss&otilde;es e de alterar ou atualizar as
                informa&ccedil;&otilde;es no Site a qualquer momento, sem aviso
                pr&eacute;vio.
              </span>
            </p>
            <ol style={{ listStyleType: "none", textAlign: "left" }}>
              <li>
                <strong>
                  <span>20. Aviso legal</span>
                </strong>
              </li>
            </ol>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                O SITE &Eacute; FORNECIDO COMO EST&Aacute; E CONFORME
                DISPON&Iacute;VEL. VOC&Ecirc; CONCORDA QUE SEU USO DO SITE E DOS
                NOSSOS SERVI&Ccedil;OS SER&Aacute; POR SUA CONTA E RISCO. NA
                EXTENS&Atilde;O M&Aacute;XIMA PERMITIDA POR LEI, NOS ISENTAMOS
                DE TODAS AS GARANTIAS, EXPRESSAS OU IMPL&Iacute;CITAS, EM
                CONEX&Atilde;O COM O SITE E O USO DO MESMO, INCLUINDO, SEM
                LIMITA&Ccedil;&Atilde;O, AS GARANTIAS IMPL&Iacute;CITAS DE
                COMERCIALIZA&Ccedil;&Atilde;O E ADEQUA&Ccedil;&Atilde;O A UM
                DETERMINADO PROP&Oacute;SITO DE VIOLA&Ccedil;&Atilde;O.
                N&Atilde;O OFERECEMOS NENHUMA GARANTIA OU
                DECLARA&Ccedil;&Otilde;ES SOBRE A EXATID&Atilde;O OU INTEGRIDADE
                DO CONTE&Uacute;DO DO SITE OU O CONTE&Uacute;DO DE QUAISQUER
                WEBSITES LIGADOS AO SITE E N&Atilde;O ASSUMIMOS NENHUMA
                RESPONSABILIDADE POR QUAISQUER (1) ERROS, FALHAS,
                INTERRUP&Ccedil;&Otilde;ES E MAL FUNCIONAMENTO, (2)
                LES&Otilde;ES PESSOAIS OU DANOS &Agrave; PROPRIEDADE, DE
                QUALQUER NATUREZA, RESULTANTES DO SEU ACESSO E USO DO SITE, (3)
                ACESSOS N&Atilde;O AUTORIZADOS, (4) TODA E QUALQUER
                INFORMA&Ccedil;&Atilde;O PESSOAL E/OU INFORMA&Ccedil;&Otilde;ES
                FINANCEIRAS ARMAZENADAS AQUI, (5) QUALQUER
                INTERRUP&Ccedil;&Atilde;O OU CESSA&Ccedil;&Atilde;O DA
                TRANSMISS&Atilde;O DE OU PARA O SITE, (6) QUAISQUER BUGS,
                V&Iacute;RUS, CAVALOS TROJAN, OU SEMELHANTES QUE POSSAM SER
                TRANSMITIDOS PARA OU ATRAV&Eacute;S DO SITE POR QUALQUER
                TERCEIRO, (7) QUAISQUER ERROS OU OMISS&Otilde;ES EM QUALQUER
                CONTE&Uacute;DO E MATERIAL DISPONIBILIZADO NO SITE E/OU (8) POR
                QUALQUER PERDA OU DANO DE QUALQUER TIPO INCORRIDO COMO RESULTADO
                DO USO DE QUALQUER CONTE&Uacute;DO PUBLICADO, TRANSMITIDO OU
                DISPONIBILIZADO DE OUTRA FORMA VIA SITE. N&Atilde;O GARANTIMOS,
                ENDOSSAMOS OU ASSUMIMOS RESPONSABILIDADE POR QUALQUER PRODUTO OU
                SERVI&Ccedil;O ANUNCIADO OU OFERECIDO POR TERCEIROS
                ATRAV&Eacute;S DO SITE, QUALQUER SITE LINKADO OU QUALQUER SITE
                OU APLICATIVO PARA DISPOSITIVOS M&Oacute;VEIS ANUNCIADOS OU
                OFERECIDOS POR TERCEIROS ATRAV&Eacute;S DO SITE, BEM COMO
                QUALQUER BANNER OU OUTRA PUBLICIDADE QUE DE QUALQUER FORMA SEJA
                RESPONS&Aacute;VEL PELO MONITORAMENTO DE QUALQUER
                TRANSA&Ccedil;&Atilde;O ENTRE VOC&Ecirc; E QUAISQUER PROVEDORES
                DE PRODUTOS OU SERVI&Ccedil;OS TERCEIROS. ASSIM COMO COM A
                COMPRA DE UM PRODUTO OU SERVI&Ccedil;O ATRAV&Eacute;S DE
                QUALQUER MEIO OU EM QUALQUER AMBIENTE, VOC&Ecirc; DEVE USAR SEU
                MELHOR JULGAMENTO E TOMAR O CUIDADO APROPRIADO.
              </span>
            </p>
            <ol style={{ listStyleType: "none", textAlign: "left" }}>
              <li>
                <strong>
                  <span>21. Limita&ccedil;&otilde;es de responsabilidade</span>
                </strong>
              </li>
            </ol>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                EM NENHUMA HIP&Oacute;TESE N&Oacute;S OU NOOSAS
                SUBSIDI&Aacute;RIAS, AFILIADAS E TODOS OS NOSSOS RESPECTIVOS
                DIRETORES, AGENTES, PARCEIROS E FUNCION&Aacute;RIOS SEREMOS
                RESPONS&Aacute;VEIS POR VOC&Ecirc; OU QUALQUER TERCEIRO POR
                QUAISQUER DANOS DIRETOS, INDIRETOS, CONSEQUENCIAIS, EXEMPLARES,
                INCIDENTAIS, ESPECIAIS OU PUNITIVOS, INCLUINDO LUCROS CESSANTES,
                PERDA DE RECEITA OU OUTROS DANOS DECORRENTES DE SEU USO DO SITE.
                N&Atilde;O OBSTANTE, NOSSA RESPONSABILIDADE POR QUALQUER CAUSA E
                INDEPENDENTEMENTE DA FORMA DA A&Ccedil;&Atilde;O ESTAR&Aacute; A
                TODO O MOMENTO LIMITADA AO MONTANTE PAGO PELO USU&Aacute;RIO
                &agrave; EYEFLOW, SE HOUVER, DURANTE OS SEIS (6) MESES
                ANTERIORES A QUALQUER CAUSA DE A&Ccedil;&Atilde;O
                OCORRIDA.&nbsp;
              </span>
            </p>
            <ol style={{ listStyleType: "none", textAlign: "left" }}>
              <li>
                <strong>
                  <span>22. Indeniza&ccedil;&atilde;o</span>
                </strong>
              </li>
            </ol>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                Voc&ecirc; concorda em nos defender, indenizar e isentar de
                responsabilidade, incluindo nossas subsidi&aacute;rias,
                afiliadas e todos os nossos respectivos diretores, agentes,
                parceiros e funcion&aacute;rios, de e contra qualquer perda,
                dano, responsabilidade, reclama&ccedil;&atilde;o ou demanda,
                incluindo advogados, taxas e despesas razo&aacute;veis, feitas
                por terceiros devido a ou decorrentes de: (1) suas
                contribui&ccedil;&otilde;es; (2) uso do Site; (3)
                viola&ccedil;&atilde;o destes Termos e Condi&ccedil;&otilde;es
                de Uso; (4) qualquer viola&ccedil;&atilde;o de suas
                representa&ccedil;&otilde;es e garantias estabelecidas nestes
                Termos e Condi&ccedil;&otilde;es de Uso; (5) sua
                viola&ccedil;&atilde;o dos direitos de terceiros, incluindo, mas
                n&atilde;o se limitando a direitos de propriedade intelectual;
                ou (6) qualquer ato prejudicial evidente para qualquer outro
                usu&aacute;rio do Site com quem voc&ecirc; se conectou
                atrav&eacute;s do Site. N&atilde;o obstante o acima exposto,
                reservamo-nos o direito, &agrave;s suas custas, de assumir a
                defesa e o controle exclusivos de qualquer quest&atilde;o pela
                qual voc&ecirc; seja obrigado a nos indenizar, e voc&ecirc;
                concorda em cooperar, &agrave;s suas custas, com nossa defesa de
                tais reivindica&ccedil;&otilde;es. Faremos todos os
                esfor&ccedil;os razo&aacute;veis para notific&aacute;-lo de
                qualquer reclama&ccedil;&atilde;o, a&ccedil;&atilde;o ou
                processo que esteja sujeito a essa indeniza&ccedil;&atilde;o
                assim que tomarmos conhecimento disso.
              </span>
            </p>
            <ol style={{ listStyleType: "none", textAlign: "left" }}>
              <li>
                <strong>
                  <span>23. Dados do usu&aacute;rio</span>
                </strong>
              </li>
            </ol>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                Manteremos certos dados que voc&ecirc; transmite ao Site com a
                finalidade de gerenciar o desempenho do Site, bem como dados
                relacionados ao seu uso do Site. Embora fa&ccedil;amos backups
                regulares de dados de rotina, voc&ecirc; &eacute; o &uacute;nico
                respons&aacute;vel por todos os dados que transmitir ou que se
                relacionem com qualquer atividade que voc&ecirc; tenha realizado
                usando o Site. Voc&ecirc; concorda que n&atilde;o teremos
                qualquer responsabilidade para com voc&ecirc; por qualquer perda
                ou corrup&ccedil;&atilde;o de tais dados, e voc&ecirc; renuncia
                a qualquer direito de a&ccedil;&atilde;o contra n&oacute;s
                decorrente de qualquer perda ou corrup&ccedil;&atilde;o de tais
                dados.
              </span>
            </p>
            <ol style={{ listStyleType: "none", textAlign: "left" }}>
              <li>
                <strong>
                  <span>
                    24. Comunica&ccedil;&otilde;es eletr&ocirc;nicas,
                    transa&ccedil;&otilde;es e assinaturas
                  </span>
                </strong>
              </li>
            </ol>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                Visitar o Site, enviar e-mails e preencher formul&aacute;rios
                online constituem comunica&ccedil;&otilde;es eletr&ocirc;nicas.
                Voc&ecirc; consente em receber comunica&ccedil;&otilde;es
                eletr&ocirc;nicas e concorda que todos os acordos, avisos,
                divulga&ccedil;&otilde;es e outras comunica&ccedil;&otilde;es
                que fornecemos a voc&ecirc; eletronicamente, via e-mail e no
                Site, atendem a qualquer requisito legal de que tal
                comunica&ccedil;&atilde;o seja por escrito. VOC&Ecirc; CONCORDA
                COM O USO DE ASSINATURAS ELETR&Ocirc;NICAS, CONTRATOS, ORDENS E
                OUTROS REGISTROS E COM A ENTREGA ELETR&Ocirc;NICA DE AVISOS,
                POL&Iacute;TICAS E REGISTROS DE TRANSA&Ccedil;&Otilde;ES
                INICIADAS OU CONCLU&Iacute;DAS POR N&Oacute;S OU VIA SITE.
                Voc&ecirc;, por meio deste, renuncia a quaisquer direitos ou
                requisitos sob quaisquer estatutos, regulamentos, regras,
                decretos ou outras leis em qualquer jurisdi&ccedil;&atilde;o que
                exijam uma assinatura original ou entrega ou
                reten&ccedil;&atilde;o de registros n&atilde;o
                eletr&ocirc;nicos, ou a pagamentos ou a concess&atilde;o de
                cr&eacute;ditos por qualquer outro meio do que meios
                eletr&ocirc;nicos.
              </span>
            </p>
            <ol style={{ listStyleType: "none", textAlign: "left" }}>
              <li>
                <strong>
                  <span>25. Diversos</span>
                </strong>
              </li>
            </ol>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                Estes Termos e condi&ccedil;&otilde;es de Uso e quaisquer
                pol&iacute;ticas ou regras operacionais publicadas por
                n&oacute;s no Site ou em rela&ccedil;&atilde;o ao Site
                constituem o acordo e entendimento completo entre voc&ecirc; e
                n&oacute;s. Nossa falha em exercer ou fazer cumprir qualquer
                direito ou disposi&ccedil;&atilde;o destes Termos e
                Condi&ccedil;&otilde;es de Uso n&atilde;o deve funcionar como
                uma ren&uacute;ncia de tal direito ou disposi&ccedil;&atilde;o.
                Estes Termos e Condi&ccedil;&otilde;es de Uso operam em toda a
                extens&atilde;o permitida por lei. Podemos atribuir qualquer um
                ou todos os nossos direitos e obriga&ccedil;&otilde;es a
                terceiros a qualquer momento. N&atilde;o seremos
                respons&aacute;veis por qualquer perda, dano, atraso ou
                omiss&atilde;o de a&ccedil;&atilde;o causado por qualquer causa
                al&eacute;m do nosso controle razo&aacute;vel. Se qualquer
                disposi&ccedil;&atilde;o ou parte de uma
                disposi&ccedil;&atilde;o destes Termos e Condi&ccedil;&otilde;es
                de Uso for determinada como ilegal, nula ou inexequ&iacute;vel,
                essa disposi&ccedil;&atilde;o ou parte da
                disposi&ccedil;&atilde;o &eacute; considerada separ&aacute;vel
                destes Termos e Condi&ccedil;&otilde;es de Uso e n&atilde;o
                afeta a validade e aplicabilidade de quaisquer
                disposi&ccedil;&otilde;es. N&atilde;o h&aacute; joint venture,
                parceria, emprego ou rela&ccedil;&atilde;o de
                representa&ccedil;&atilde;o criada entre voc&ecirc; e n&oacute;s
                como resultado destes Termos e Condi&ccedil;&otilde;es de Uso do
                Site. Voc&ecirc; concorda que estes Termos e
                Condi&ccedil;&otilde;es de Uso n&atilde;o ser&atilde;o
                interpretados contra n&oacute;s por t&ecirc;-los elaborado.
                Voc&ecirc;, por meio deste, renuncia a toda e qualquer defesa
                que possa ter com base na forma eletr&ocirc;nica destes Termos e
                Condi&ccedil;&otilde;es de Uso e na falta de assinatura das
                partes para executar estes Termos e Condi&ccedil;&otilde;es de
                Uso.
              </span>
            </p>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <strong>
                <span style={{ fontSize: "13px", color: "#415071" }}>
                  CONTATE-NOS
                </span>
              </strong>
            </p>
            <p style={{ textAlign: "justify", lineHeight: "normal" }}>
              <span style={{ fontSize: "13px", color: "#415071" }}>
                Para resolver uma reclama&ccedil;&atilde;o sobre o Site ou para
                receber mais informa&ccedil;&otilde;es sobre o uso do Site,
                entre em contato conosco em:
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span
                style={{
                  fontSize: "13px",
                  lineHeight: "107%",
                  color: "#415071",
                }}
              >
                <a href="mailto:contact@eyeflow.ai">
                  <span style={{ fontSize: "13px" }}>
                    contact@eyeflow.ai
                  </span>
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
      <PageFooter />
    </div>
  );
}
