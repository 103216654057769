/* eslint-disable */
import React from "react";
// import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import apisauce from "apisauce";

// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import signupPageStyle from "assets/jss/material-kit-pro-react/Pages/signupPageStyle.js";
import { IntlContext } from "../../intlProviderWrapper";

const useStyles = makeStyles(signupPageStyle);

export default function GoogleOAuth(props, ...rest) {
  let name = ""
  const classes = useStyles();
  const { locale } = React.useContext(IntlContext);

  const forgotPasswordLink = (
    <span
      style={{
        fontFamily: "'Exo 2'",
        color: "white",
        fontWeight: "600",
      }}
    >
      <FormattedMessage
        id="signup.notification6"
        defaultMessage="Esse email já foi cadastrado, para redefinir sua senha clique"
      />
      <a
        className="external_link"
        target="_blank"
        href="https://app.eyeflow.ai/forgot-password/request"
        style={{ fontWeight: "800", color: "#27378a", paddingLeft: "0.3rem" }}
      >
        <FormattedMessage
          id="signup.notification6_link"
          defaultMessage="aqui"
        />
      </a>
    </span>
  );

  const {
    dados,
    error,
    setOpen,
    setDados,
    setSentEmail,
    setNotification,
    invitationToken,
  } = props;

  const api = apisauce.create({
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: invitationToken ? `Bearer ${invitationToken}` : "",
    },
  });

  const googleLogin = (profile, tokenId) =>
    api.post(`${window.WS_URL}/user/invite/accept`, {
      ...profile,
      tokenId,
    });

  const checkUserInvited = (uid, type) =>
    api.get(`${window.WS_URL}/user/validate/uid/${uid}`, { type });

  const handleGoogleLogin = async (e) => {
    await fetch(
      `https://oauth2.googleapis.com/tokeninfo?id_token=${e.credential}`
    )
      .then((res) => res.json())
      .then((res) =>{
        name = res.name.split(" ")
        Object.assign(dados, {
          email: res.email,
          agreed: true,
          type: "google",
          loginType: "Self Google",
          password: "",
        })
        setDados(dados);
      })
      .catch((error) => console.log(error));

    if (name.length >= 2) {
      Object.assign(dados, {
        first_name: name[0],
        last_name: name[name.length - 1],
      });

      checkUserInvited(`${name[0]}.${name[name.length - 1]}`, "google")
        .then((result) => {
          if (result.ok && !result.data.user) {
            dados["username"] = `${name[0]}.${name[name.length - 1]}`;
            googleLogin(dados, e.tokenId)
              .then((result) => {
                if (result.ok) {
                  setSentEmail(true);
                  window.location.href = window.APP_URL;
                } else {
                  if (
                    result.data.error.message &&
                    result.data.error.message ===
                      "User is registered in Eyeflow Db Admin."
                  ) {
                    setNotification({
                      message: forgotPasswordLink,
                      type: "error",
                      loading: false,
                    });
                    setOpen(true);
                  } else {
                    if (result.data.error.message) {
                      setNotification({
                        message: result.data.error.message,
                        type: "error",
                        loading: false,
                      });
                      setOpen(true);
                    }
                  }
                }
              })
              .then(!error ?? document.getElementById("GoogleButton").click())
              .catch((err) => {
                console.log(err);
              });
          } else if (result.ok && result.data.user) {
            let stopLoop = false;

            async function tryUsername() {
              while (stopLoop === false) {
                // eslint-disable-next-line
                await newUsername()
                  .then((result) => {
                    if (result === "ok") {
                      googleLogin(dados, e.tokenId)
                        .then((result) => {
                          if (result.ok) {
                            setSentEmail(true);
                            window.location.href = window.APP_URL;
                          } else {
                            if (
                              result.data.error.message &&
                              result.data.error.message ===
                                "User is registered in Eyeflow Db Admin."
                            ) {
                              setNotification({
                                message: forgotPasswordLink,
                                type: "error",
                                loading: false,
                              });
                              setOpen(true);
                            } else {
                              if (result.data.error.message) {
                                setNotification({
                                  message: result.data.error.message,
                                  type: "error",
                                  loading: false,
                                });
                                setOpen(true);
                              }
                            }
                          }
                        })
                        .then(!error ?? document.getElementById("GoogleButton").click())
                        .catch((err) => {
                          console.log(err);
                        });
                    }
                  })
                  .catch(() => {
                    stopLoop = false;
                  });
              }
            }
            tryUsername();

            function newUsername() {
              return new Promise((resolve, reject) => {
                let userName = `${name[0]}.${name[name.length - 1]}${Math.floor(
                  Math.random() * 10
                )}${Math.floor(Math.random() * 10)}`;
                checkUserInvited(userName, "google").then((result) => {
                  if (result.ok && !result.data.user) {
                    dados["username"] = userName;
                    stopLoop = true;
                    resolve("ok");
                  } else {
                    stopLoop = false;
                    reject("err");
                  }
                });
              });
            }
          }
        })
        .catch((err) => {
          dados["username"] = "";
          console.log(err);
        });
    } else {
      Object.assign(dados, {
        first_name: name[0],
        last_name: "",
      });
      checkUserInvited(`${name[0]}`, "google")
        .then((result) => {
          if (result.ok && !result.data.user) {
            dados["username"] = `${name[0]}`;
            googleLogin(dados, e.tokenId)
              .then((result) => {
                if (result.ok) {
                  setSentEmail(true);
                  window.location.href = window.APP_URL;
                } else {
                  if (
                    result.data.error.message &&
                    result.data.error.message ===
                      "User is registered in Eyeflow Db Admin."
                  ) {
                    setNotification({
                      message: forgotPasswordLink,
                      type: "error",
                      loading: false,
                    });
                    setOpen(true);
                  } else {
                    if (result.data.error.message) {
                      setNotification({
                        message: result.data.error.message,
                        type: "error",
                        loading: false,
                      });
                      setOpen(true);
                    }
                  }
                }
              })
              .then(!error ?? document.getElementById("GoogleButton").click())
              .catch((err) => {
                console.log(err);
              });
          } else if (result.ok && result.data.user) {
            let stopLoop = false;

            async function tryUsername() {
              while (stopLoop === false) {
                // eslint-disable-next-line
                await newUsername()
                  .then((result) => {
                    if (result === "ok") {
                      googleLogin(dados, e.tokenId)
                        .then((result) => {
                          if (result.ok) {
                            console.log(result);
                          } else {
                            if (
                              result.data.error.message &&
                              result.data.error.message ===
                                "User is registered in Eyeflow Db Admin."
                            ) {
                              setNotification({
                                message: forgotPasswordLink,
                                type: "error",
                                loading: false,
                              });
                              setOpen(true);
                            } else {
                              if (result.data.error.message) {
                                setNotification({
                                  message: result.data.error.message,
                                  type: "error",
                                  loading: false,
                                });
                                setOpen(true);
                              }
                            }
                          }
                        })
                        .then(!error ?? document.getElementById("GoogleButton").click())
                        .catch((err) => {
                          console.log(err);
                        });
                    }
                  })
                  .catch(() => {
                    stopLoop = false;
                  });
              }
            }
            tryUsername();

            function newUsername() {
              return new Promise((resolve, reject) => {
                let userName = `${name[0]}${Math.floor(
                  Math.random() * 10
                )}${Math.floor(Math.random() * 10)}`;
                checkUserInvited(userName, "google").then((result) => {
                  if (result.ok && !result.data.user) {
                    dados["username"] = userName;
                    stopLoop = true;
                    resolve("ok");
                  } else {
                    stopLoop = false;
                    reject("err");
                  }
                });
              });
            }
          }
        })
        .catch((err) => {
          dados["username"] = "";
          console.log(err);
        });
    }
  };

  return (
    <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <GoogleOAuthProvider clientId={window.CLIENT_OAUTH}>
      <GoogleLogin
        onSuccess={handleGoogleLogin}
        onFailure={(e) => console.log(e)}
        cookiePolicy={"single_host_origin"}
        locale={locale}
        text="signup_with"
        useOneTap
      />
      <form
        id="googleSignUpForm"
        noValidate
        onSubmit={(e) => e.preventDefault()}
      >
        <input type="hidden" id="googleEmail" name="email" value={dados["email"]} />
        <button
          type="submit"
          name="submit"
          id="GoogleButton"
          style={{ display: "none" }}
        ></button>
      </form>
    </GoogleOAuthProvider>
    </div>
  );
}

GoogleOAuth.defaultProps = {
  disabledButton: false,
  sentEmail: false,
};

GoogleOAuth.propTypes = {
  invitationToken: PropTypes.string,
  dados: PropTypes.object,
  setDados: PropTypes.func,
  setSentEmail: PropTypes.any,
  setNotification: PropTypes.any,
  notification: PropTypes.any,
  setOpen: PropTypes.any,
  open: PropTypes.any,
};
