import React from "react";
import { FormattedMessage } from "react-intl";
import { put } from "redux-saga/effects";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Grid, TextField, Link } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/PageHeaderLinks.js";
import Footer from "components/Footer/PageFooter.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
// Third-party
import apisauce from "apisauce";

import loginPageStyle from "assets/jss/material-kit-pro-react/Pages/loginPageStyle.js";
import background from "assets/img/backgroundLogin.jpg";
// import { IntlContext } from "../../intlProviderWrapper";
import { loginLanguages } from "../../intl-languages/messages";

const useStyles = makeStyles(loginPageStyle);

export default function LoginPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    if (
      window.location.host === "eyeflow.ai" ||
      window.location.host === "www.eyeflow.ai"
    ) {
      setResetpasswordUrl("https://app.eyeflow.ai/forgot-password/request");
    } else if (window.location.host === "www2.eyeflow.ai") {
      setResetpasswordUrl("https://app2.eyeflow.ai/forgot-password/request");
    }
  }, []);
  // const { locale } = React.useContext(IntlContext);

  const [user, setUser] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [showPassword, setShowPassword] = React.useState(false);
  // const [rememberMe, setRememberMe] = React.useState(false);
  const [resetpasswordUrl, setResetpasswordUrl] = React.useState(
    "https://app.eyeflow.ai/forgot-password/request",
  );
  const [notification, setNotification] = React.useState([]);
  const [languageOption, setLanguageOption] = React.useState("en-US");

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleShowPassword = (e) => {
    setShowPassword(!showPassword);
  };
  // const baseURL = platformOption === 'demo' || window.location.host === 'www2.eyeflow.ai' ? "https://eyeflow-demo.siliconlife.ai" : "https://app.eyeflow.ai"
  const baseURL = "https://app.eyeflow.ai";

  const baseAUTH = baseURL ? `${baseURL}/auth/` : `${window.WS_AUTH}/`;
  const api = apisauce.create({
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  const login = (user, pwd) =>
    api.post(
      `${baseAUTH}login`,
      {},
      { auth: { username: user, password: pwd } },
    );

  const handleLogin = (e) => {
    e.preventDefault();
    login(user, password).then((response) => {
      if (response.ok) {
        let access_token = JSON.stringify(response.data);
        if (window.location.host === "www2.eyeflow.ai") {
          put(
            (window.location.href =
              "https://app2.eyeflow.ai/app/select?access_token=" +
              access_token +
              "&language=" +
              languageOption),
          );
        }
        // else if (platformOption === 'demo') {
        //   put( window.location.href = platformUrl+access_token+"&language="+languageOption )
        // }
        else {
          put(
            (window.location.href =
              "https://app.eyeflow.ai/app/select?access_token=" +
              access_token +
              "&language=" +
              languageOption),
          );
        }
      } else {
        setNotification([true, response.problem]);
      }
    });
  };

  // const handleRememberMe = (e) => {
  //   setRememberMe(rememberMe => !rememberMe)
  // }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification(...notification, [false, ""]);
  };

  // const handleChange = (event) => {
  //   setPlatformUrl(event.target.value);
  //   if (event.target.value.includes('demo')) {
  //     setPlatformOption('demo')
  //     setResetpasswordUrl("https://eyeflow-demo.siliconlife.ai/forgot-password/request")
  //   } else {
  //     setPlatformOption('app')
  //     setResetpasswordUrl("https://app.eyeflow.ai/forgot-password/request")
  //   }
  // };
  const handleLangChange = (e) => {
    setLanguageOption(e.target.value);
  };

  const classes = useStyles();
  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="EyeFlow.AI"
        links={<HeaderLinks dropdownHoverColor="info" />}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + background + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem style={{ width: "25em" }}>
              <Card>
                <form
                  className={classes.form}
                  noValidate
                  id="loginForm"
                  onSubmit={handleLogin}
                >
                  <CardHeader
                    color="blue"
                    signup
                    className={classes.cardHeader}
                  >
                    <h2
                      style={{ fontFamily: "'Exo 2'" }}
                      className={classes.cardTitle}
                    >
                      Login
                    </h2>
                  </CardHeader>
                  <CardBody signup>
                    <TextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      id="user"
                      label={
                        <span style={{ fontFamily: "'Exo 2'" }}>
                          <FormattedMessage
                            id="signin.user"
                            defaultMessage="User"
                          />
                        </span>
                      }
                      name="user"
                      onChange={(event) => setUser(event.currentTarget.value)}
                      onLoad={(event) => setUser(event.currentTarget.value)}
                      autoFocus
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                    <TextField
                      variant="standard"
                      margin="normal"
                      required
                      style={{ fontFamily: "'Exo 2'" }}
                      fullWidth
                      name="password"
                      label={
                        <span style={{ fontFamily: "'Exo 2'" }}>
                          <FormattedMessage
                            id="signin.pass"
                            defaultMessage="Password"
                          />
                        </span>
                      }
                      type={showPassword ? "text" : "password"}
                      id="password"
                      onChange={(event) =>
                        setPassword(event.currentTarget.value)
                      }
                      autoComplete="current-password"
                      onLoad={(event) => setUser(event.currentTarget.value)}
                      InputProps={{
                        className: classes.input,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleShowPassword}
                              onMouseDown={handleShowPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <div style={{ display: "grid", gridAutoFlow: "column" }}>
                      {/* <FormControl style= {{ marginRight: "1em" }} className={classes.formControl}>
              <InputLabel style={{ fontSize:"1.12em", fontFamily: "'Exo 2'" }} id="platformSelectLabel"><FormattedMessage id="signin.select" defaultMessage="Qual plataforma?"/></InputLabel>
              <Select
                labelId="platformSelectLabel"
                id="platformSelect"
                value={platformUrl}
                onChange={handleChange}
                className={classes.input}
              >
                <MenuItem style={{ fontFamily: "'Exo 2'" }} value={"https://eyeflow-demo.siliconlife.ai/app/select?access_token="}>Demo</MenuItem>
                <MenuItem style={{ fontFamily: "'Exo 2'" }} value={"https://app.eyeflow.ai/app/select?access_token="}>App</MenuItem>
              </Select>
            </FormControl> */}
                      <FormControl className={classes.formControl}>
                        <InputLabel
                          style={{ fontSize: "1.12em", fontFamily: "'Exo 2'" }}
                          id="languageSelectLabel"
                        >
                          <FormattedMessage
                            id="signin.select2"
                            defaultMessage="Qual língua?"
                          />
                        </InputLabel>
                        <Select
                          labelId="languageSelectLabel"
                          id="languageSelect"
                          value={languageOption}
                          onChange={handleLangChange}
                          className={classes.input}
                        >
                          {Object.keys(loginLanguages).map((loc) => (
                            <MenuItem
                              key={loginLanguages[loc].locale + "key"}
                              id={loginLanguages[loc].locale + "-key"}
                              style={{ fontFamily: "'Exo 2'" }}
                              value={loginLanguages[loc].locale}
                            >
                              {loginLanguages[loc].label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" checked={rememberMe} onClick={handleRememberMe}/>}
                label={<FormattedMessage id="signin.remember" defaultMessage="Remember me" />}
              /> */}
                  </CardBody>
                  <div
                    style={{ margin: "0.5em 0em 0em 0em" }}
                    className={classes.textCenter}
                  >
                    <Button
                      style={{
                        fontFamily: "'Exo 2'",
                        width: "6.5em",
                        fontSize: "1.12em",
                      }}
                      simple
                      color="facebook"
                      type="submit"
                      fullWidth
                      id="signInButton"
                      variant="contained"
                      className={classes.submit}
                    >
                      <FormattedMessage
                        id="signin.title"
                        defaultMessage="Sign In"
                      />
                    </Button>
                  </div>
                  <Grid
                    style={{ margin: "0em 0em 1em 0em" }}
                    container
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                  >
                    <Grid item>
                      <Link
                        style={{ fontFamily: "'Exo 2'", fontSize: "1.1em" }}
                        id="resetPassword"
                        href={resetpasswordUrl}
                        variant="body2"
                      >
                        <FormattedMessage
                          id="signin.forgot_pass"
                          defaultMessage="Forgot password?"
                        />
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link
                        style={{ fontFamily: "'Exo 2'", fontSize: "1.1em" }}
                        id="signupOption"
                        href="/signup"
                        variant="body2"
                      >
                        <FormattedMessage
                          id="signin.signupOption"
                          defaultMessage="Criar conta"
                        />
                      </Link>
                    </Grid>
                  </Grid>
                </form>
                <Snackbar
                  open={notification[0]}
                  autoHideDuration={6000}
                  onClose={handleClose}
                >
                  <Alert onClose={handleClose} severity="error">
                    {notification[1] === "CLIENT_ERROR" ? (
                      <FormattedMessage
                        id="signin.notification"
                        defaultMessage="Usuário ou senha incorretos!"
                      />
                    ) : (
                      <FormattedMessage
                        id="signin.notification2"
                        defaultMessage="Não há conexão com a internet, tente novamente!"
                      />
                    )}
                  </Alert>
                </Snackbar>
              </Card>
            </GridItem>
          </GridContainer>
          <div style={{ marginBottom: "-8em" }}>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
